import { doc, getDoc, getDocs, query, where } from "firebase/firestore";
import loginSuccess from "mocks/login_success.json";
import { callToCloudFunction } from "services/api/apiRequests";
import {
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  N_CHECKER,
  SYSTEM_ID_SecureBase,
  Y_CHECKER
} from "utils/constants";
import { getAuthenticatedUserBranch, getLanguageId } from "utils/helpers";
import {
  api_results_collection_resultset0,
  branches_collection,
  global_attribute_collection,
  message_by_code_collection,
  message_collection,
  personnel_details_collection,
  request_status_collection,
  service_location_collection
} from "../firebase";

/**
 * @module common application common data requests module
 */
export const common = {
  /**
   * Get all branches
   *
   * @param {object} props
   * @returns
   */
  getAllBranches: async () => {
    let result = [];
    const q = query(branches_collection);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });
    return result;
  },

  /**
   * Get all insert types
   *
   * @param {object} props
   * @param {boolean} props.returnNoneValue return none value or not
   * @returns
   */
  getAllInsertTypes: async ({ returnNoneValue = false }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      return_none_value: returnNoneValue ? Y_CHECKER : N_CHECKER,
      language_locale_id: getLanguageId()
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getactiveinserttypes`,
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get all lock types
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @returns
   */
  getAllLockTypes: async ({ customerId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getactivemultimediainserttypes`,
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get all request statuses
   *
   * @returns
   */
  getAllRequestStatuses: async () => {
    let result = [];
    const q = query(request_status_collection);
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });
    return result;
  },

  /**
   * Get all media types
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.mediaProgramTypeId id of the media program type
   * @param {string} props.permanentIssueFlag permanent issue flag
   * @returns
   */
  getAllMediaTypes: async ({
    customerId,
    mediaProgramTypeId,
    permanentIssueFlag
  }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      media_program_type_id: mediaProgramTypeId,
      permanent_issue_flag: permanentIssueFlag
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getmediatypesbasedonprogramtype`,
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get service locations of a customer
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @returns
   */
  getServiceLocations: async ({ customerId }) => {
    let result = [];
    const q = query(
      service_location_collection,
      where(FIREBASE_FIELD_NAMES.CUSTOMER_ID, "==", customerId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });
    return result;
  },

  /**
   * Get authentication details for the personnel
   *
   * @param {object} props
   * @param {string} props.personnelId id of the selected personnel
   */
  getAuthorizationPersonnelsAuthentications: async ({ personnelId }) => {
    let result = [];
    const q = query(
      personnel_details_collection,
      where(FIREBASE_FIELD_NAMES.PERSONNEL_ID, "==", personnelId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });
    return result[0];
  },

  /**
   * Get Login Success Info
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @returns
   */
  getLoginSuccessInfo: async ({ mainDistrictId }) => {
    return loginSuccess.data;
  },

  /**
   * Get service location filtering to customerId
   *
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @returns
   */
  getServiceLocationByCustomer: async ({ customerId }) => {
    try {
      let result = [];
      const q = query(
        service_location_collection,
        where(FIREBASE_FIELD_NAMES.CSL_CUSTOMER_ID, "==", customerId),
        where(
          FIREBASE_FIELD_NAMES.DISTRICT_ID,
          "==",
          getAuthenticatedUserBranch()
        )
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({ id: doc?.id, ...doc?.data() });
      });

      return result;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get the locale by selected branch
   *
   * @returns
   */
  getLocaleByBranch: async () => {
    const requestBody = JSON.stringify({
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
    });

    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getalllocations`,
    ).then((resp) => resp.data);

    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const q = query(
          api_results_collection_resultset0(cfResponse.docId),
          where(
            FIREBASE_FIELD_NAMES.DISTRICT_ID,
            "==",
            getAuthenticatedUserBranch()
          )
        );
        const collectionSnap = await getDocs(q);

        collectionSnap.forEach((doc) => response.push(doc.data()));
      }

      return response;
    } else {
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get error message by error code
   *
   * @param {object} props
   * @param {string} props.message_id id of the selected customer
   * @returns
   */
  getMessageById: async (props) => {
    try {
      let result = [];
      const q = query(
        message_collection,
        where("message_id", "==", props?.message_id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({ id: doc?.id, ...doc?.data() });
      });

      return result;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get error message by error code from message collection
   *
   * @param {object} props
   * @param {string} props.message_id id of the selected customer
   * @returns
   */
  getMessageByIdFromMessage: async (props) => {
    try {
      let result = [];
      const q = query(
        message_by_code_collection,
        where("message_id", "==", props?.message_id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({ id: doc?.id, ...doc?.data() });
      });

      return result;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get destruction request details
   *
   * @param {object} props
   * @param {string} props.destruct_request_id
   * @returns
   */
  getDestructionRequestDetail: async (props) => {
    try {
      const districtId = getAuthenticatedUserBranch();
      const object = JSON.stringify({
        main_district_id: districtId,
        destruct_request_id: props?.destruct_request_id,
        language_locale_id: "1",
        system_id: 60
      });
      let response = [];
      const cfResponse = await callToCloudFunction(
        object,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/getdestructrequestdetail`,
      ).then((resp) => resp.data);

      const docId = cfResponse.docId;
      const collectionSnap = await getDocs(
        api_results_collection_resultset0(docId)
      );
      collectionSnap.forEach((doc) => {
        response.push({ id: doc?.id, ...doc?.data() });
      });

      return response;
    } catch (err) {
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * Get customer data by id
   *
   * @param {object} props
   * @param {string} props
   * @returns
   */
  getCustomerDataById: async (props) => {
    const districtId = getAuthenticatedUserBranch();
    const object = JSON.stringify({
      main_district_id: districtId,
      function: "sb_fs_customer_l9_sp",
      customer_id: props.customer_id
    });
    const cfResponse = await callToCloudFunction(
      object,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getcustomerdatabyid`,
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  },

  /**
   * Get Personnel Authentation name & id of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.personnelId id of the selected customerId
   * @returns
   */
  getPersonnelAuthCust: async ({ mainDistrictId, personnelId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      personnel_id: personnelId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/authorizedby`,
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response.map(({ name, personnel_id }) => ({
      label: name,
      value: personnel_id
    }));
  },

  /**
   * Get Auth Number of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.personnelId id of the selected personnelId
   * @returns
   */
  getAuthNumber: async ({ mainDistrictId, personnelId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      personnel_id: personnelId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getpersonneldetails`,
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },

  /**
   * Get Authorization Detail of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.requestTypeId id of the selected requestTypeId
   * @param {string} props.requestActionId id of the selected requestActionId
   * @param {string} props.customerId id of the selected customerId
   * @param {string} props.personnelId id of the selected personnelId
   * @returns
   */
  getAuthorizationDetail: async ({
    requestTypeId,
    requestActionId,
    customerId,
    personnelId
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      request_type_id: requestTypeId,
      request_action_id: requestActionId,
      customer_id: customerId,
      personnel_id: personnelId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/determineauthorizationrequirement`,
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },

  /**
   * Validate the challenge response
   *
   * @param {object} props
   * @returns
   */
  getValidateChallengeResponse: async (payload) => {
    try {
      var requestBody = JSON.stringify(payload);
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/validatechallengeresponse`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = {
          error: ERROR_TYPES.FAILED
        };
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = {
        error: ERROR_TYPES.EXCEPTION
      };
      return response;
    }
  },

  /**
   * @name getGlobalAttributeValueById
   * @description Get global attribute value by id
   *
   * @param {number} id global attribute id
   * @returns
   */

  getGlobalAttributeValueById: async (id) => {
    const docRef = doc(global_attribute_collection, id);
    const snapshot = await getDoc(docRef);
    // check whether the document exists
    if (snapshot.exists()) {
      const globalAttributeDoc = snapshot.data();
      return globalAttributeDoc?.global_attribute_value;
    } else {
      return "";
    }
  },

  /**
   * @name getGlobalAttributeValueByTypeId
   * @description Get global attribute value by type id
   *
   * @param {number} id global attribute type id
   * @param {number} system_id system id
   * @returns
   */
  getGlobalAttributeValueByTypeId: async ({
    id,
    system_id = SYSTEM_ID_SecureBase
  }) => {
    const filteredQuery = query(
      global_attribute_collection,
      where(FIREBASE_FIELD_NAMES.GLOBAL_ATTRIBUTE_TYPE_ID, "==", id),
      where(FIREBASE_FIELD_NAMES.SYSTEM_ID, "==", system_id)
    );
    let response = [];
    const querySnapshot = await getDocs(filteredQuery);
    //loop through the snapshot and add it to a variable
    querySnapshot?.forEach((doc) =>
      response.push(doc?.data()?.global_attribute_value)
    );

    // return response if the response array has elements otherwise return empty value
    return response.length > 0 ? response[0] : "";
  }
};
