import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  TableContainer,
  Typography
} from "@mui/material";
import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import { DatePickerField, FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import DownloadPopup from "components/core/modal-components/DownloadPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  CONTAINER_ISSUANCE,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import { callToCloudFunction } from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getCustomersQueryData, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  APP_REGIONS,
  DEFAULT_DATA_TABLE_OPTIONS,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  NO,
  NOT_APPLICABLE,
  N_CHECKER,
  VALUE_ALL,
  YES,
  Y_CHECKER,
  current_exchange_status_id,
  downloadReportOptions,
  errorMsgs,
  jobName,
  jobType,
  media_program_type_id_3,
  media_program_type_id_4,
  printType,
  system_function_id,
  ymdDateFormat
} from "utils/constants";
import { labelPrinterSetup } from "utils/constants/label-printer-setup/LabelPrinterSetupConstant";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getDateFromUTCFormat,
  getResponseData,
  getSortedData,
  getUserName,
  printReport
} from "utils/helpers";
import IssuanceEditModal from "./IssuanceEditModal";
import { IssuranceStyles } from "./IssuanceStyles";
import PrintLabelModal from "./PrintLabelModal";

const { default: MUIDataTable } = require("mui-datatables");

const Issuance = () => {
  const { currentBranch, auth, localeByBranch } = useSelector(selectAuth);
  const { allSelected } = useParams();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showErrorAlertMsg, setShowErrorAlertMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNavigate, setIsLoadingNavigate] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allMediaTypes, setAllMediaTypes] = useState([]);
  const [localDate, setLocalDate] = useState("");
  const [containerIssuranceData, setContainerIssuranceData] = useState([]);
  const [allPrinters, setAllPrinters] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printLabelText, setPrintLabelText] = useState("");
  const [openPrintLabelModal, setOpenPrintLabelModal] = useState(false);
  const [labelFormatData, setLabelFormatData] = useState({});
  const [successInsertMessage, setSuccessInsertMessage] = useState("");
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [showPrintReportModal, setShowPrintReportModal] = useState(false);
  const selectedValue = downloadReportOptions[0];
  const region = process.env.REACT_APP_CF_REGION;
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isSelectedRow = selectedRow.key === tableMeta.rowIndex;
          const iconComponent = isSelectedRow ? Check : EditIcon;
          return (
            <Button
              onClick={() => {
                handleRowClick(value, tableMeta);
              }}
            >
              <SvgIcon component={iconComponent} />
            </Button>
          );
        }
      }
    },
    {
      name: "issue_id",
      label: t("continerIssurance.issue"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = containerIssuranceData[dataIndex];
          const { indefinite_retention_flag } = rowData;
          if (indefinite_retention_flag === N_CHECKER) {
            return YES;
          } else {
            return NO;
          }
        }
      }
    },
    {
      name: "customerLabel_id",
      label: t("continerIssurance.customerLabel")
    },
    {
      name: "logicalVault_id",
      label: t("continerIssurance.logicalVault")
    },
    {
      name: "mediaType_id",
      label: t("continerIssurance.MediaType")
    },
    {
      name: "insertConfiguration_id",
      label: t("continerIssurance.insertConfiguration")
    },
    {
      name: "date_id",
      label: t("continerIssurance.date"),
      options: {
        customBodyRenderLite: (dataIndex) => {
          const rowData = containerIssuranceData[dataIndex];
          const { request_service_date } = rowData;
          if (request_service_date) {
            return getDateFromUTCFormat(request_service_date);
          }
        }
      }
    },
    {
      name: "number_id",
      label: t("continerIssurance.number")
    },
    {
      name: "customerMediaIdentifier_id",
      label: t("continerIssurance.customerMediaIdentifier")
    },
    {
      name: "locktype_id",
      label: t("continerIssurance.locktype")
    }
  ];

  // Conditionally filter columns
  const filteredColumns =
    region === APP_REGIONS.IMNA
      ? columns.filter(
          (column) =>
            column.label !== t("continerIssurance.customerMediaIdentifier")
        )
      : columns;

  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: containerIssuranceData?.length && true,
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={!containerIssuranceData?.length}
            onClick={() => onPrintLabelClick()}
          >
            {t("continerIssurance.printLabels")}
          </Button>
        </Grid>
      </>
    ),
    textLabels: {
      body: {
        noMatch:
          containerIssuranceData?.length === 0 &&
          `${t("common.tableTextWithoutInputs")}`
      }
    },
    setRowProps: (row, dataIndex) => {
      const rowData = containerIssuranceData[dataIndex];
      const completedFlag = rowData ? rowData.completed_flag : null;
      return {
        style: {
          backgroundColor:
            dataIndex === selectedRow?.key
              ? ContainerProcessingStyles?.selectedColor
              : completedFlag === Y_CHECKER
                ? ContainerProcessingStyles?.completeColor
                : ""
        }
      };
    }
  };

  let initialValues = {
    customer: null,
    minDate: dayjs(),
    today: dayjs(),
    maxDate: dayjs(),
    mediaType: null,
    isAllDatesChecked: false,
    printer: null
  };

  const issueranceForm = useFormik({
    initialValues: initialValues
  });

  // handle customer change
  const onCustomerChange = (event, customer) => {
    issueranceForm.handleChange({
      target: { name: "customer", value: customer }
    });
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setContainerIssuranceData([]);
  };

  const getLocalDateAndTime = async () => {
    setIsLoading((prev) => true);
    try {
      let reqBody = {
        main_district_id: currentBranch?.value
      };

      const localDateAndTime = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.getLocalDateTime}`,
        2
      );

      const localDateTimeErrorResponse = localDateAndTime?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (localDateTimeErrorResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          localDateTimeErrorResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const localDateTimeDataResponse = localDateAndTime?.data[0]; // get the success response
      setLocalDate(localDateTimeDataResponse[0]?.local_getdate);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getCustomerDataForSelectedBranch = async () => {
    setIsLoading((prev) => true);
    try {
      const customerList = await getCustomersQueryData(currentBranch?.value);
      let customers = customerList?.map((cus) => {
        return {
          ...cus,
          label: `${cus?.number} - ${cus?.name}`,
          value: cus?.id
        };
      });
      const listOfCustomers = getSortedData(customers, "number", "asc");
      listOfCustomers.unshift({
        label: VALUE_ALL,
        value: ""
      });
      setAllCustomers(listOfCustomers);
      issueranceForm.setFieldValue("customer", {
        label: VALUE_ALL,
        value: ""
      });
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getAllMediaTypes = async () => {
    setIsLoading((prev) => true);
    try {
      let mediaTypes = [{ label: VALUE_ALL, value: "" }];

      const reqBody = {
        main_district_id: currentBranch?.value,
        media_program_type_id: media_program_type_id_3
      };

      const programType3 = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.getMediaTypes}`,
        2
      );

      const programType3Response = programType3?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (programType3Response[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          programType3Response[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const programType3SuccessResponse = programType3?.data[0]; // get the success response
      const programType3Data = getSortedData(
        programType3SuccessResponse,
        "list_box_text",
        "asc"
      );
      const mappedProgramType3Data = programType3Data?.map((item) => {
        return {
          ...item,
          label: item?.list_box_text,
          value: item?.media_type_id
        };
      });
      mediaTypes = [...mediaTypes, ...mappedProgramType3Data];

      const requestBody = {
        main_district_id: currentBranch?.value,
        media_program_type_id: media_program_type_id_4
      };

      const programType4 = await getResponseData(
        requestBody,
        `${CF_URLS.containerIssurance?.getMediaTypes}`,
        2
      );

      const programType4Response = programType4?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (programType4Response[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          programType4Response[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const programType4SuccessResponse = programType4?.data[0]; // get the success response
      const programType4Data = getSortedData(
        programType4SuccessResponse,
        "list_box_text",
        "asc"
      );

      const mappedProgramType4Data = programType4Data?.map((item) => {
        return {
          ...item,
          label: item?.list_box_text,
          value: item?.media_type_id
        };
      });

      mediaTypes = [...mediaTypes, ...mappedProgramType4Data];
      setAllMediaTypes(mediaTypes);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getAllPrinters = async () => {
    setIsLoading((prev) => true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_printer_id: "",
        district_id: currentBranch?.value
      };

      const printerData = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.getAllPrinters}`,
        2
      );

      const printerDataResponse = printerData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (printerDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          printerDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const printerDataSuccessResponse = printerData?.data[0]; // get the success response

      // Filter results where label_type_code begins with 'C'
      let filteredData = printerDataSuccessResponse.filter((item) =>
        item.label_type_code.startsWith("C")
      );
      const listOfPrinters = getSortedData(filteredData, "short_name", "asc");
      const mappedPrinters = listOfPrinters?.map((item) => {
        return {
          ...item,
          label: item?.short_name,
          value: item?.label_printer_id
        };
      });
      setAllPrinters(mappedPrinters);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // media type change
  const onMediaTypeChange = (item) => {
    issueranceForm.setFieldValue("mediaType", item?.target?.value);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setContainerIssuranceData([]);
  };

  // all dates checkbox
  const handleAllDates = (event) => {
    issueranceForm.setFieldValue("isAllDatesChecked", event.target.checked);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setContainerIssuranceData([]);
  };

  // find button click
  const onFindButtonClick = async () => {
    setIsLoading((prev) => true);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setContainerIssuranceData([]);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        service_date: issueranceForm?.values?.isAllDatesChecked
          ? ""
          : issueranceForm?.values?.today.format(ymdDateFormat),
        customer_id: issueranceForm?.values?.customer?.value,
        media_type_id: issueranceForm?.values?.mediaType?.value
      };

      const findData = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.getContainerIssuranceData}`,
        2
      );

      const findDataResponse = findData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (findDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          findDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const findDataSuccessResponse = findData?.data[0]; // get the success response

      findDataSuccessResponse?.sort((a, b) => {
        // Parse dates
        const dateA = new Date(a.request_service_date);
        const dateB = new Date(b.request_service_date);

        // Compare dates
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        // If dates are the same, sort by container_number
        const containerA = a.container_number.trim();
        const containerB = b.container_number.trim();

        if (containerA < containerB) return -1;
        if (containerA > containerB) return 1;

        // If both are the same
        return 0;
      });

      const mappedData = findDataSuccessResponse?.map((item, i) => {
        const custId = item?.customer_id;
        const selectedCust = allCustomers.find(
          (item) => item?.customer_id === custId
        );
        return {
          key: i,
          ...item,
          issue_id: item?.indefinite_retention_flag,
          customerLabel_id: selectedCust?.number,
          logicalVault_id: item?.logical_vault_code,
          mediaType_id: `${item?.media_type_type} - ${item?.media_type_descr}`,
          insertConfiguration_id: item?.insert_configuration
            ? item?.insert_configuration
            : NOT_APPLICABLE,
          date_id: item?.request_service_date,
          number_id: item?.container_number,
          customerMediaIdentifier_id: item?.customer_media_descr,
          locktype_id: item?.lock_type_code
        };
      });
      setContainerIssuranceData(mappedData);
      setIsLoading((prev) => false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  // clear button click
  const onClearButtonClick = () => {
    setIsLoading((prev) => true);
    issueranceForm.setFieldValue("customer", {
      label: VALUE_ALL,
      value: ""
    });
    issueranceForm.setFieldValue("mediaType", allMediaTypes[0]);
    issueranceForm.setFieldValue("isAllDatesChecked", false);
    issueranceForm.setFieldValue("today", dayjs());
    setContainerIssuranceData([]);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
    setIsLoading((prev) => false);
  };

  // printer change
  const onPrinterChange = (item) => {
    issueranceForm.setFieldValue("printer", item?.target?.value);
    setShowErrorAlert(false);
    setShowErrorAlertMsg("");
  };

  // handle Row Click
  const handleRowClick = (value, tableMeta) => {
    setSelectedRow(containerIssuranceData[tableMeta?.rowIndex]);
    setIsModalOpen((prev) => !prev);
  };

  // get the error msg from firestore msg collection
  const getTheFirestoreErrorMessage = async (msgId) => {
    const msg = await getMessageFromCode(msgId);
    return { errorMsg: msg[0]?.descr };
  };

  // close the modal
  const handleModalClose = () => {
    setSelectedRow(0);
    setIsModalOpen((prev) => !prev);
  };

  // change the description of the selected row
  const onDescriptionChange = (event) => {
    setSelectedRow((prev) => ({
      ...prev,
      indefinite_retention_flag: event.target.checked ? N_CHECKER : Y_CHECKER
    }));
  };

  // save the changes in the modal
  const onSaveClickOnModal = async () => {
    setContainerIssuranceData(
      containerIssuranceData?.map((item) => {
        if (item?.key === selectedRow?.key) {
          return selectedRow;
        } else {
          return item;
        }
      })
    );
    setSelectedRow(0);
    setIsModalOpen((prev) => !prev);
    return;
  };
  const replaceSpecialCharacters = (input) => {
    const replacements = {
      "^": "_5e",
      "~": "_7e",
      "\\": "_5c_5c",
      _: "_5f"
    };

    return input.replace(/[\^~\\_]/g, (match) => replacements[match]);
  };
  // print label click
  const onPrintLabelClick = async () => {
    setIsLoading((prev) => true);

    if (!issueranceForm?.values?.printer) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode25365
      );
      setShowErrorAlert(true);
      setShowErrorAlertMsg(errMsg?.errorMsg);
      setIsLoading((prev) => false);
      return;
    }

    try {
      const itemsToPrint = containerIssuranceData?.filter(
        (item) => item?.indefinite_retention_flag === N_CHECKER
      );

      let successPrints = 0;

      const zpl = labelFormatData?.label_template_zpl;

      for (let index = 0; index < itemsToPrint.length; index++) {
        const element = itemsToPrint[index];

        const containerLabelZpl = await getContainerLabelZpl(
          zpl,
          element?.customerLabel_id.trim(),
          element?.media_type_type.trim(),
          element?.container_number.trim(),
          replaceSpecialCharacters(element?.customerMediaIdentifier_id)
        );

        const labelPrinterId =
          issueranceForm?.values?.printer?.label_printer_id;
        const customerNumber = element?.customerLabel_id.trim();
        const mediaType = element?.media_type_type.trim();
        const containerNumber = element?.container_number.trim();

        const auditInfoMsg = await auditInfoCreation(
          labelPrinterId,
          customerNumber,
          mediaType,
          containerNumber
        );

        // audit
        const reqBody = {
          main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
          label_format_id: labelFormatData?.label_format_id?.trim(),
          audit_info: auditInfoMsg,
          customer_number: element?.customerLabel_id?.trim(),
          media_type: element?.media_type_type?.trim(),
          container_number: element?.container_number?.trim(),
          media_number: element?.number_id?.trim()
        };

        await executeAudit(reqBody);

        // print
        const adjustedZpl = applyPrinterAdjustments(containerLabelZpl);

        if (
          issueranceForm?.values?.printer?.ip_address !==
          labelPrinterSetup.ipAddressCheck
        ) {
          const printResponse = await sendDataToPrinter(adjustedZpl);

          if (printResponse) {
            successPrints = successPrints + 1;
          }
        }
      }

      if (successPrints === 1) {
        const msg = await getTheFirestoreErrorMessage(
          errorMsgs?.errorCode63902
        );
        setPrintLabelText(msg?.errorMsg);
        setOpenPrintLabelModal((prev) => true);
      } else if (successPrints > 1) {
        let msg = await getTheFirestoreErrorMessage(errorMsgs?.errorCode63901);
        let firstReplacement = msg?.errorMsg.replace("|", itemsToPrint?.length);
        let secondReplacement = firstReplacement.replace(
          "|",
          itemsToPrint[0]?.number_id?.trim()
        );
        let finalReplacement = secondReplacement.replace(
          "|",
          itemsToPrint[itemsToPrint?.length - 1]?.number_id?.trim()
        );
        setPrintLabelText(finalReplacement);
        setOpenPrintLabelModal((prev) => true);
        setIsLoading(false);
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getContainerLabelZpl = (
    zpl,
    customerLabel_id,
    media_type_type,
    container_number,
    customerMediaIdentifier_id
  ) => {
    const paddedCustomerNumber = customerLabel_id.padStart(6, "0");
    const paddedContainerNumber = container_number.padStart(6, "0");
    const paddedMediaType = media_type_type.padStart(2, " ");

    let zplString = zpl;

    // Perform replacements without altering special characters
    zplString = zplString.replace(/\{0\}/g, customerLabel_id);
    zplString = zplString.replace(/\{1\}/g, container_number);
    zplString = zplString.replace(/\{2\}/g, media_type_type);
    zplString = zplString.replace(/\{3\}/g, customerMediaIdentifier_id);
    zplString = zplString.replace(/\{4\}/g, paddedCustomerNumber);
    zplString = zplString.replace(/\{5\}/g, paddedMediaType);
    zplString = zplString.replace(/\{6\}/g, paddedContainerNumber);

    return zplString;
  };

  const onNoClick = () => {
    setOpenPrintLabelModal((prev) => false);
  };

  const onYesClick = async () => {
    setIsLoading(true);
    // issue container

    const itemsToIssue = containerIssuranceData?.filter(
      (item) => item?.indefinite_retention_flag === N_CHECKER
    );

    if (!itemsToIssue?.length) {
      setIsLoading(false);
      return;
    }

    // check run rule
    const runRules = await checkRunRules();

    //get batch id
    const batchID = await getBatchID();

    let errorContainerIDs = [];
    for (let index = 0; index < itemsToIssue.length; index++) {
      const element = itemsToIssue[index];
      const errorContaiers = await containerUpdate(runRules, batchID, element);
      if (errorContaiers) {
        errorContainerIDs?.push(errorContaiers);
      }
    }

    if (errorContainerIDs?.length > 1) {
      const errMsg = await getTheFirestoreErrorMessage(
        errorMsgs?.errorCode62531
      );
      const commaSeparatedString = errorContainerIDs?.join(", ");
      let msg = errMsg?.errorMsg?.replace("|", commaSeparatedString);
      setShowErrorAlert(true);
      setShowErrorAlertMsg(msg);
    }

    // download the report - need to be added
    setOpenPrintLabelModal((prev) => false);
    setShowPrintReportModal(true);
  };

  const getLabelFormatFromSP = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        label_format_id: "",
        label_type_code: issueranceForm?.values?.printer?.label_type_code
      };

      const labelFormatGetData = await getResponseData(
        reqBody,
        `${CF_URLS.printTapeLabel.getLabelFormat}`,
        1
      );

      setLabelFormatData(labelFormatGetData.data[0][0]);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const auditInfoCreation = async (
    labelPrinterId,
    customerNumber,
    mediaType,
    containerNumber
  ) => {
    let auditInfo = t("continerIssurance.auditMsg");

    auditInfo = auditInfo.replace(/\{0\}/g, labelPrinterId);
    auditInfo = auditInfo.replace(/\{1\}/g, customerNumber);
    auditInfo = auditInfo.replace(/\{2\}/g, mediaType);
    auditInfo = auditInfo.replace(/\{3\}/g, containerNumber);

    return auditInfo;
  };

  const executeAudit = async (body) => {
    setIsLoading(true);
    try {
      const auditData = await getResponseData(
        body,
        `${CF_URLS.containerIssurance?.labelPrintAudit}`,
        1
      );

      const auditDataResponse = auditData?.data[0]; // get error code

      // if there is an error showing the error and exit from the process
      if (auditDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          auditDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const applyPrinterAdjustments = (testLabelZpl) => {
    const leftOffSetDots = Math.floor(
      issueranceForm?.values?.printer?.left_offset_mm * labelPrinterSetup.dots
    );
    const topOffSetDots = Math.floor(
      issueranceForm?.values?.printer?.top_offset_mm * labelPrinterSetup.dots
    );
    const pattern = labelPrinterSetup.regex.applyPrinterAdjustmentsPatten;
    const match = pattern.exec(testLabelZpl);

    if (match) {
      const xHome = Math.max(parseInt(match[1]) + leftOffSetDots, 0);
      const yHome = Math.max(parseInt(match[2]), 0);
      const newZpl = testLabelZpl.replace(pattern, `^LH${xHome},${yHome}`);
      let darknessAdjustment = 0;
      if (issueranceForm?.values?.printer?.darkness_adjustment > 0) {
        darknessAdjustment = Math.min(
          issueranceForm?.values?.printer?.darkness_adjustment,
          labelPrinterSetup.darknessAdjustment
        );
      } else {
        darknessAdjustment = Math.max(
          issueranceForm?.values?.printer?.darkness_adjustment,
          -labelPrinterSetup.darknessAdjustment
        );
      }
      let topAdjustment = 0;
      if (topOffSetDots > 0) {
        topAdjustment = Math.min(
          topOffSetDots,
          labelPrinterSetup.topOffsetAdjustments
        );
      } else {
        topAdjustment = Math.max(
          topOffSetDots,
          -labelPrinterSetup.topOffsetAdjustments
        );
      }
      const sbFormat = `^XA^MD${darknessAdjustment}^LT${topAdjustment}`;
      return newZpl.replace("^XA", sbFormat);
    }

    return testLabelZpl;
  };

  const sendDataToPrinter = async (adjustedLabel) => {
    setIsLoading(true);
    try {
      const districtId = getAuthenticatedUserBranch();
      const printerId = `sb${selectEnv()}-${
        issueranceForm?.values?.printer?.label_printer_id
      }-${districtId}`;
      const stringToEncode = adjustedLabel;
      const encodedString = btoa(stringToEncode);
      const reqBody = {
        printerID: printerId,
        printType: printType,
        printData: encodedString,
        jobType: jobType,
        jobName: jobName
      };
      const response = await callCloudFunction(
        reqBody,
        `${CF_URLS.labelPrinterSetup.cloudPrint}`
      );
      if (response.data.isSuccess) {
        setIsLoading(false);
        return true;
      } else {
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const selectEnv = () => {
    if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev1) {
      return labelPrinterSetup.logicalNameEnv.dev1;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.dev2) {
      return labelPrinterSetup.logicalNameEnv.dev2;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa1) {
      return labelPrinterSetup.logicalNameEnv.qa1;
    } else if (process.env.REACT_APP_CF_ENV === labelPrinterSetup.env.qa2) {
      return labelPrinterSetup.logicalNameEnv.qa2;
    } else if (process.env.REACT_APP_ENV === labelPrinterSetup.env.uat) {
      return labelPrinterSetup.logicalNameEnv.uat;
    } else {
      return "";
    }
  };

  const callCloudFunction = async (reqBody, url) => {
    const response = await callToCloudFunction(reqBody, url);
    return response;
  };

  const checkRunRules = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        system_function_id: system_function_id,
        current_exchange_status_id: current_exchange_status_id,
        called_by_sp: N_CHECKER
      };

      const runRuleData = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.runRule}`,
        2
      );

      const runRuleResponse = runRuleData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (runRuleResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          runRuleResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }
      setIsLoading(false);
      return runRuleData?.data[0];
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getBatchID = async () => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        run_id: "",
        system_function_id: system_function_id,
        employee_id: auth?.user?.id
      };

      const batchData = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.getBatchId}`,
        2
      );

      const batchIDResponse = batchData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (batchIDResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          batchIDResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }
      setIsLoading(false);
      return batchData?.data[0];
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const containerUpdate = async (runRules, batchID, element) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: currentBranch?.value,
        container_id: element?.container_id,
        new_exchange_status_id: runRules[0]?.next_exchange_status_id,
        new_logical_vault_id: element?.logical_vault_id,
        new_media_type_id: element?.media_type_id,
        new_number: element?.number_id?.trim(),
        new_home_district_id: element?.home_district_id,
        new_outcode: element?.outcode,
        new_customer_owned_flag: element?.customer_owned_flag,
        new_issued_request_id: element?.issued_request_id,
        new_issued_date: element?.issued_date,
        new_delete_request_id: element?.delete_request_id,
        new_deleted_date: element?.deleted_date,
        new_request_id: element?.request_id,
        new_return_date: element?.return_date,
        new_indefinite_retention_flag: element?.indefinite_retention_flag,
        new_personnel_id: "",
        employee_id: auth?.user?.id,
        batch_id: batchID[0]?.batch_id,
        new_assigned_to_customer_id: element?.assigned_to_customer_id,
        create_container_txn_flag: runRules[0]?.create_container_trx_flag,
        container_ts: "",
        system_function_id: system_function_id
      };

      const containerUpdateData = await getResponseData(
        reqBody,
        `${CF_URLS.containerIssurance?.containerUpdate}`,
        2
      );

      const containorUpdateResponse = containerUpdateData?.data[1]; // get error code

      // if there is an error showing the error and exit from the process
      if (containorUpdateResponse[0]?.error === errorMsgs?.errorCode62531) {
        setIsLoading((prev) => false);
        return element?.number_id?.trim();
      }

      if (containorUpdateResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          containorUpdateResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const updateReqBody = {
        main_district_id: currentBranch?.value,
        batch_id: batchID[0]?.batch_id
      };

      const updateData = await getResponseData(
        updateReqBody,
        `${CF_URLS.containerIssurance?.requestTwoUpdate}`,
        1
      );

      const updateDataResponse = updateData?.data[0]; // get error code

      // if there is an error showing the error and exit from the process
      if (updateDataResponse[0]?.error !== errorMsgs?.noError) {
        const errMsg = await getTheFirestoreErrorMessage(
          updateDataResponse[0]?.error
        );
        setShowErrorAlert(true);
        setShowErrorAlertMsg(errMsg?.errorMsg);
        setIsLoading((prev) => false);
        return;
      }

      const successMsg = await getTheFirestoreErrorMessage(
        updateDataResponse[0]?.error
      );
      setSuccessInsertMessage(successMsg?.errorMsg);
      setMessageSuccess(true);
      setIsLoading(false);
    } catch (error) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const onCancelClick = () => {
    onFindButtonClick();
    setShowPrintReportModal(false);
  };

  const onDownloadClick = async () => {
    try {
      setIsLoading(true);
      const itemsToPrint = containerIssuranceData?.filter(
        (item) => item?.indefinite_retention_flag === N_CHECKER
      );
      // Extract the number_id values and create a comma-separated string
      const numberIds = itemsToPrint.map((item) => item.number_id.trim());
      const commaSeparatedString = numberIds.join(",");
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value,
        service_date: issueranceForm?.values?.isAllDatesChecked
          ? ""
          : issueranceForm?.values?.today.format(ymdDateFormat),
        customer_id: issueranceForm?.values?.customer?.value,
        media_type_id: issueranceForm?.values?.mediaType?.value,
        display_container_slot: N_CHECKER,
        display_cust_media_desc:
          region === APP_REGIONS.IMNA ? N_CHECKER : Y_CHECKER,
        report_title: t("continerIssurance.issueContainersReport"),
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase(),
        container_print_list: commaSeparatedString
      });
      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.containerProcessing.issuance}`
      );
      if (!reportResponse.success) {
        setShowErrorAlert(true);
        setShowErrorAlertMsg(reportResponse.error);
        setIsLoading(false);
      }
    } catch (e) {
      setShowErrorAlert(true);
      setShowErrorAlertMsg(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      onFindButtonClick();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentBranch?.value) {
      getLocalDateAndTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.value]);

  useEffect(() => {
    //find method trigger when navigating from dashboard container tab
    if (allSelected === Y_CHECKER) {
      setIsLoadingNavigate(true);
      issueranceForm.setFieldValue("isAllDatesChecked", true);
      if (issueranceForm.values.mediaType !== null) {
        onFindButtonClick();
        setIsLoadingNavigate(false);
        //remove params from url
        window.history.replaceState(
          {},
          "",
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${CONTAINER_ISSUANCE}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueranceForm.values.mediaType]);

  useEffect(() => {
    if (currentBranch?.value) {
      getCustomerDataForSelectedBranch();
      getAllMediaTypes();
      getAllPrinters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch?.value]);

  useEffect(() => {
    if (localDate) {
      setIsLoading((prev) => true);
      // Parse the date string into a Date object
      let dateObj = new Date(localDate);

      // Extract the parts we need for currentDate
      let year = dateObj.getFullYear();
      let month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
      let day = String(dateObj.getDate()).padStart(2, "0");

      // currentDate in yyyy-mm-dd format
      let currentDate = `${year}-${month}-${day}`;
      const today = dayjs(currentDate);
      issueranceForm.setFieldValue("today", today);

      // Calculate minDate (one year less than currentDate)
      let oneYearAgo = new Date(
        Date.UTC(year - 1, dateObj.getUTCMonth(), dateObj.getUTCDate())
      );
      let minDate = `${oneYearAgo.getUTCFullYear()}-${String(
        oneYearAgo.getUTCMonth() + 1
      ).padStart(2, "0")}-${String(oneYearAgo.getUTCDate()).padStart(2, "0")}`;
      const minimumDate = dayjs(minDate);
      issueranceForm.setFieldValue("minDate", minimumDate);

      // Calculate maxDate (one year greater than currentDate)
      let oneYearLater = new Date(
        Date.UTC(year + 1, dateObj.getUTCMonth(), dateObj.getUTCDate())
      );
      let maxDate = `${oneYearLater.getUTCFullYear()}-${String(
        oneYearLater.getUTCMonth() + 1
      ).padStart(2, "0")}-${String(oneYearLater.getUTCDate()).padStart(
        2,
        "0"
      )}`;
      const maximumDate = dayjs(maxDate);
      issueranceForm.setFieldValue("maxDate", maximumDate);
      setIsLoading((prev) => false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localDate]);

  useEffect(() => {
    if (allMediaTypes?.length) {
      issueranceForm.setFieldValue("mediaType", allMediaTypes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMediaTypes]);

  useEffect(() => {
    if (allPrinters?.length) {
      // issueranceForm.setFieldValue("printer", allPrinters[0]); // ToDo - Check the default printer setup
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPrinters]);

  useEffect(() => {
    if (issueranceForm?.values?.printer) {
      getLabelFormatFromSP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueranceForm?.values?.printer]);

  return (
    <>
      {/*Success message popup*/}
      {messageSuccess && (
        <SuccessPopup
          message={successInsertMessage}
          close={setMessageSuccess}
        />
      )}
      {(isLoading || isLoadingNavigate) && <FullCircularLoader />}
      <Stack spacing={2}>
        {showErrorAlert && (
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" width="20" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setShowErrorAlert(false);
                  setShowErrorAlertMsg("");
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {Array.isArray(showErrorAlertMsg)
              ? showErrorAlertMsg.map((message, index) => (
                  <>
                    {index === 0 ? <b>{message}</b> : `${index}) ${message}`}
                    <br />
                  </>
                ))
              : showErrorAlertMsg}
          </Alert>
        )}
        <Box>
          <Grid container spacing={2} alignItems="">
            {/* customers field */}
            <Grid item md={6} lg={3} xs={12}>
              <FormControl fullWidth variant="outlined">
                <CustomersField
                  options={allCustomers}
                  value={issueranceForm?.values?.customer}
                  handleOnChange={(event, newValue) => {
                    onCustomerChange(event, newValue, "customerDetailCustomer");
                  }}
                  form={issueranceForm}
                  label={t("personnelValidation.customer")}
                />
              </FormControl>
            </Grid>

            {/* service date */}
            <Grid item md={6} lg={3} xs={12}>
              <FormControl fullWidth>
                <DatePickerField
                  id="serviceDateId"
                  name="serviceDate"
                  label={t("continerIssurance.serviceDate")}
                  disabled={issueranceForm.values.isAllDatesChecked}
                  minDate={issueranceForm.values.minDate}
                  maxDate={issueranceForm.values.maxDate}
                  locale={localeByBranch}
                  value={
                    issueranceForm.values.isAllDatesChecked
                      ? null
                      : issueranceForm.values.today
                  }
                  onChange={(value) => {
                    issueranceForm.setFieldValue("today", value);
                    setShowErrorAlert(false);
                    setShowErrorAlertMsg("");
                    setContainerIssuranceData([]);
                  }}
                />
              </FormControl>
            </Grid>

            {/* all dates */}
            <Grid item md={6} lg={3} xs={12}>
              <Stack sx={ContainerProcessingStyles.formControllabelStart}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={issueranceForm.values.isAllDatesChecked}
                      onChange={handleAllDates}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={t("continerIssurance.allDates")}
                />
              </Stack>
            </Grid>

            {/* media types */}
            <Grid item md={6} lg={3} xs={12}>
              <FormControl fullWidth variant="outlined">
                <>
                  <InputLabel
                    id="mediaTypeLabel"
                    shrink={issueranceForm.values.mediaType}
                  >
                    {t("continerIssurance.mediaType")}
                  </InputLabel>
                  <Select
                    label={t("continerIssurance.mediaType")}
                    name={"MediaTypelabel"}
                    value={issueranceForm.values.mediaType}
                    onChange={onMediaTypeChange}
                    notched={issueranceForm.values.mediaType}
                  >
                    {allMediaTypes?.length > 0 &&
                      allMediaTypes?.map((mediaType) => {
                        return (
                          <MenuItem value={mediaType} key={mediaType?.label}>
                            {mediaType?.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2} alignItems="">
            {/* printer */}
            <Grid item md={6} lg={3}>
              <FormControl fullWidth variant="outlined">
                <>
                  <InputLabel
                    id="printerNameLabel"
                    placeholder="printer"
                    shrink={issueranceForm.values.printer}
                  >
                    {t("continerIssurance.printer")}
                  </InputLabel>
                  <Select
                    label={t("continerIssurance.printer")}
                    name={"printerlabel"}
                    disabled={!containerIssuranceData?.length}
                    onChange={onPrinterChange}
                    value={issueranceForm.values.printer}
                    notched={issueranceForm.values.printer}
                  >
                    {allPrinters?.length > 0 &&
                      allPrinters?.map((printer) => {
                        return (
                          <MenuItem value={printer} key={printer?.label}>
                            {printer?.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </>
              </FormControl>
            </Grid>
            <Grid item md={6} lg={8}>
              <Typography sx={IssuranceStyles?.printerInfo}>
                {t("continerIssurance.prineteIfo")}
              </Typography>
              <Typography>
                {issueranceForm.values.printer?.location_description}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Stack direction="row" gap={spacing.gap}>
            <Button
              id="newBtnfilter"
              variant="outlined"
              onClick={() => onClearButtonClick()}
            >
              {t("common.clear")}
            </Button>
            <Button
              id="saveBtnfilter"
              variant="contained"
              type="submit"
              onClick={() => onFindButtonClick()}
            >
              {t("common.find")}
            </Button>
          </Stack>
          <br />
          <Stack>
            <Paper>
              <TableContainer>
                <MUIDataTable
                  columns={filteredColumns}
                  options={options}
                  data={containerIssuranceData}
                  title={
                    <Typography style={IssuranceStyles.titleStyle}>
                      {containerIssuranceData?.length}{" "}
                      {t("common.recordsFound")}
                    </Typography>
                  }
                />
              </TableContainer>
            </Paper>
          </Stack>
        </Box>
      </Stack>

      {/* Edit modal */}
      <IssuanceEditModal
        isModalOpen={isModalOpen}
        handleModalClose={handleModalClose}
        selectedRow={selectedRow}
        onDescriptionChange={onDescriptionChange}
        onSaveClickOnModal={onSaveClickOnModal}
      />

      <PrintLabelModal
        openPrintLabelModal={openPrintLabelModal}
        printLabelText={printLabelText}
        onNoClick={onNoClick}
        onYesClick={onYesClick}
      />

      {/* Report download */}
      <DownloadPopup
        title={t("continerIssurance.issueContainersReport")}
        showPrintModal={showPrintReportModal}
        downloadOptions={downloadReportOptions}
        onCancleClick={onCancelClick}
        onDownloadClick={onDownloadClick}
      />
    </>
  );
};
export default Issuance;
