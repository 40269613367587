import { getDocs, query, where } from "firebase/firestore";
import destructMedia from "mocks/v2/destruct_media_validate_sp_mockdata.json";
import destructionRequestDetail from "mocks/v2/destruct_request_detail_l_sp_mockdata.json";
import mediaTypes from "mocks/v2/destruct_validate_select_media_sp_mockdata.json";
import { callToCloudFunction } from "services/api/apiRequests";

import {
  api_results_collection_resultset0,
  customers_collection,
  personnel_details_collection
} from "services/firebase";
import { FIREBASE_FIELD_NAMES } from "utils/constants";
import { customerFilter, getAuthenticatedUserBranch } from "utils/helpers";

/**
 * @module customer customer specific data requests module
 */
export const customer = {
  /**
   * Get all customers
   *
   * @returns
   */
  getAllCustomers: async () => {
    const districtId = getAuthenticatedUserBranch();
    let result = [];
    const q = query(
      customers_collection,
      where(FIREBASE_FIELD_NAMES.DISTRICT_ID, "==", districtId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });

    //Filter customers
    result = customerFilter(result);
    return result;
  },

  /**
   * Get personnel details by personnelId
   *
   * @param {object} props
   * @param {string} props.personnelId id of the selected personnel
   * @returns {object}
   */
  getPersonnelAuth: async ({ personnelId }) => {
    let result = [];

    if (personnelId) {
      const q = query(
        personnel_details_collection,
        where(FIREBASE_FIELD_NAMES.PERSONNEL_ID, "==", String(personnelId))
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({ id: doc?.id, ...doc?.data() });
      });
    }

    return result;
  },

  /**
   * Get all destruct media by customer
   *
   * @param {object} props
   * @param {string} props.customerID id of the selected customer
   * @returns {object}
   */
  getAllDestructMedia: async ({ customerId }) => {
    const flattenedData = destructMedia.data?.flat();
    const filteredMedia = flattenedData?.filter(
      (media) => media.customerId === parseInt(customerId, 10)
    );

    return filteredMedia;
  },

  /**
   * Get destruct request detail by Id
   *
   * @param {object} props
   * @param {string} props.destructRequestId
   * @param {string} props.languageLocaleId
   * @returns {object}
   */
  getDestructRequestDetail: async ({ destructRequestId, languageLocaleId }) => {
    // to get the destruction request by destructRequestId, languageLocaleId
    const requestDetails = destructionRequestDetail.data[0]?.filter(
      (req) =>
        String(req.destructRequestId) === String(destructRequestId) &&
        String(req.languageLocaleId) === String(languageLocaleId)
    );
    // to get the volser list by destructRequestId
    const volserList = destructionRequestDetail.data[1]?.filter(
      (volser) => String(volser.destructRequestId) === String(destructRequestId)
    );

    const combinedData = [requestDetails, volserList];

    return combinedData;
  },

  /**
   * Get all destruct media types by customer
   *
   * @param {object} props
   * @param {string} props.customerID id of the selected customer
   * @returns {object}
   */
  getAllDestructMediaTypes: async ({ customerId }) => {
    const flattenedData = mediaTypes.data?.flat();
    const filteredMedia = flattenedData?.filter(
      (media) => media.customerId === parseInt(customerId, 10)
    );

    return filteredMedia;
  },

  /**
   * Get personnels for the customer
   * @param {object} props
   * @param {string} props.customerId id of the selected customer
   * @param {string} props.select_all_personnel_flag select all flag
   */
  getAuthorizationPersonnels: async ({ customerId }) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId
    });
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/authorizedby`
    ).then((resp) => resp.data);
    // read firestore document or throw an error based on the response
    if (cfResponse.isSuccess) {
      let response = [];
      if (cfResponse.hasResults) {
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(cfResponse.docId)
        );
        collectionSnap.forEach((doc) => response.push(doc.data()));
        response = response.sort((a, b) => a?.name?.localeCompare(b?.name));
      }
      // return the results
      return response;
    } else {
      // throw an error containing the error message
      if (!cfResponse.hasResults) {
        throw Error(cfResponse.error);
      }
    }
  }
};
