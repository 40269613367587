import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import {
  ERROR_TYPES,
  GLOBAL_ATTRIBUTE_VALUES,
  SYSTEM_ID_SecureBase,
  downloadReportOptions,
  numberConstants
} from "utils/constants";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getUserName,
  printReport
} from "utils/helpers";

export default function DownloadReportModal({
  isVisible,
  setIsVisible,
  mainDistrictId,
  setIsLoading,
  districtName,
  systemId,
  languageLocaleId,
  destructRequestId,
  customerId,
  throwError,
  setThrowError,
  throwErrorMessage,
  setThrowErrorMessage,
  isPickPrint,
  setIsPickPrint,
  isPickSizeDisabled,
  selectedPickListSize,
  fetchDataExistingMDR,
  isReprint,
  pickListSizes
}) {
  const { auth } = useSelector(selectAuth);
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);

  const splitCount = useMemo(() => {
    if (isReprint) {
      return numberConstants.zero;
    }
    return isPickSizeDisabled
      ? pickListSizes[0].value || numberConstants.fiveHundred
      : selectedPickListSize?.value;
  }, [isReprint, isPickSizeDisabled, pickListSizes, selectedPickListSize]);

  //handle download
  const handleDownload = async () => {
    try {
      setIsLoading(true);
      setThrowError(false);

      const attributeData = await getGlobalAttributeValue(
        mainDistrictId,
        SYSTEM_ID_SecureBase,
        GLOBAL_ATTRIBUTE_VALUES.VALUE_189
      );

      const islandscape = attributeData === "L";

      const reqBody = isPickPrint
        ? JSON.stringify({
            main_district_id: mainDistrictId,
            destruct_request_id: destructRequestId,
            logged_in_employee_id: auth?.user?.employee_id,
            islandscape: islandscape
              ? numberConstants.one
              : numberConstants.zero,
            split_destruct_picklist_value: splitCount,
            user_name: getUserName(auth?.user),
            report_format: selectedValue?.type?.toUpperCase()
          })
        : JSON.stringify({
            main_district_id: mainDistrictId,
            user_name: getUserName(auth?.user),
            destruct_request_id: destructRequestId,
            system_id: systemId,
            customer_id: customerId,
            language_locale_id: languageLocaleId,
            district_name: districtName,
            report_format: selectedValue?.type?.toUpperCase()
          });
      let reportResponse = await printReport(
        reqBody,
        isPickPrint
          ? islandscape
            ? `${CF_URLS.printReports.request.mdrVaultItemizedPickReport}`
            : `${CF_URLS.printReports.request.mdrVaultItemizedPickPotraitReport}`
          : `${CF_URLS.printReports.request.mdrVaultItemizedStdReport}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      } else {
        fetchDataExistingMDR(destructRequestId);
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setThrowError(false);
    setIsVisible(false);
    setIsPickPrint(false);
  };

  return (
    <DownloadPopup
      title={t("common.downloadReport")}
      showPrintModal={isVisible}
      downloadOptions={downloadReportOptions}
      onCancleClick={handleCancel}
      onDownloadClick={handleDownload}
      setSelectedValue={setSelectedValue}
      setThrowError={setThrowError}
      throwError={throwError}
      throwErrorMessage={throwErrorMessage}
    />
  );
}
