export const COMMON_CARD_STYLES = {
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    minWidth: "30%",
    overflow: "visible"
  },
  cardTitle: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "160%",
    letterSpacing: "0.15px",
    color: "var(--color-im-dark-blue)",
    display: "flex",
    padding: "10px 16px",
    alignItems: "center",
    alignSelf: "stretch",
    width: "100% !important"
  },
  cardActions: {
    display: "flex",
    padding: "16px",
    alignItems: "flex-end",
    gap: "10px",
    alignSelf: "stretch"
  }
};
