import { Divider, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { getDateFromUTCFormat } from "utils/helpers";
import { ContainerAuditStyles } from "./ContainerAuditDetailStyles";

const ShowFormattedDate = ({ value }) => {
  let formattedDate = "";
  if (value) {
    formattedDate = getDateFromUTCFormat(value);
  }
  return <Typography>{formattedDate}</Typography>;
};

const MediaCount = ({ viewData }) => {
  return (
    <Grid container spacing={2} sx={ContainerAuditStyles.fontColor}>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>
      {viewData?.audit_type_id && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.auditId")}
          <Typography> {viewData?.container_audit_id}</Typography>
        </Grid>
      )}
      {viewData?.audit_status && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.auditStatus")}
          <Typography>{viewData?.audit_status} </Typography>
        </Grid>
      )}
      {viewData?.created_employee_name && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.loggedBy")}
          <Typography>{viewData?.created_employee_name}</Typography>
        </Grid>
      )}
      {viewData?.created_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.loggedDate")}
          <ShowFormattedDate value={viewData?.created_datetime_ltz} />
        </Grid>
      )}
      {viewData?.customer_number && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.customer")}
          <Typography>{viewData?.customer_number}</Typography>
        </Grid>
      )}
      {viewData?.audit_status !== "new" && viewData?.requestor_code === "c" && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.personnel")}
          <Typography>{viewData?.requested_by_personnel_name}</Typography>
        </Grid>
      )}
      {viewData?.started_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditSearch.audiDate")}
          <ShowFormattedDate value={viewData?.started_datetime_ltz} />
        </Grid>
      )}
      {viewData?.completedcanceled_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("containerAuditDetails.cancelledDate")}
          <ShowFormattedDate value={viewData?.completedcanceled_datetime_ltz} />
        </Grid>
      )}
      {viewData?.audit_type_id && (
        <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
          <Divider />
        </Grid>
      )}
      <Grid item md={6} lg={3} xs={12}>
        {t("containerAuditDetails.scanCount")}
        <Typography>{viewData?.scan_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("containerAuditDetails.unExpected")}
        <Typography>{viewData?.unexpected_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("containerAuditDetails.missing")}
        <Typography>{viewData?.missing_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("containerAuditDetails.duplicate")}
        <Typography>{viewData?.duplicate_car_count}</Typography>
      </Grid>
    </Grid>
  );
};

export default MediaCount;
