import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";

import { FullCircularLoader } from "components/core";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth } from "store/slices";
import { ERROR_TYPES, corporateAccessLevelDistrictId } from "utils/constants";
import { all } from "utils/constants/container-processing/ContainerProcessing";
import {
  insertSizeDefaultValue,
  zero
} from "utils/constants/open-media-processing/WorkManagement";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { WorkManagementStyles } from "../WorkManagementStyles";

const PrintDistributionModal = ({
  logicalVualtCheck,
  openPrintModal,
  onClose,
  selectLogicalVault,
  tableData,
  size,
  setSize,
  vault,
  setVualt,
  row,
  setRow,
  rack,
  setRack,
  shelf,
  setShelf,
  setThrowError,
  setThrowErrorMessage,
  throwError,
  throwErrorMessage
}) => {
  const { currentBranch } = useSelector(selectAuth);
  const [sizeData, setSizeData] = useState([]);
  const [specifyLocation, setSpecifyLocation] = useState(false);

  const [loading, setLoading] = useState(false);
  const [availableCapacity, setAvailableCapacity] = useState("0");
  const [requiredCapacity, setRequiredCapacity] = useState("0");
  const [vaultCount, setVaultCount] = useState([]);

  const [vaultList, setVaultList] = useState([
    {
      physical_vault_number: t("common.all"),
      physical_vault_id: all
    }
  ]);
  const [rowList, setRowList] = useState([
    {
      row_number: t("common.all"),
      row_id: all
    }
  ]);

  const [rackList, setRackList] = useState([
    {
      rack_number: t("common.all"),
      rack_id: all
    }
  ]);
  const [shelfList, setShelfList] = useState([
    {
      shelf_number: t("common.all"),
      shelf_id: all
    }
  ]);

  function removeVaultDuplicates(arr) {
    arr?.sort((a, b) =>
      a?.physical_vault_number?.localeCompare(b?.physical_vault_number)
    );
    const seen = new Set();
    return arr?.filter((item) => {
      if (seen.has(item.physical_vault_number)) {
        return false;
      } else {
        seen.add(item.physical_vault_number);
        return true;
      }
    });
  }

  function removeRowDuplicates(arr) {
    arr?.sort((a, b) => a?.row_id?.localeCompare(b?.row_id));
    const seen = new Set();
    return arr?.filter((item) => {
      if (seen.has(item.row_id)) {
        return false;
      } else {
        seen.add(item.row_id);
        return true;
      }
    });
  }
  function removeRackDuplicates(arr) {
    arr?.sort((a, b) => a?.rack_id?.localeCompare(b?.rack_id));
    const seen = new Set();
    return arr?.filter((item) => {
      if (seen.has(item.rack_id)) {
        return false;
      } else {
        seen.add(item.rack_id);
        return true;
      }
    });
  }
  function removeShelfDuplicates(arr) {
    const sorted = arr?.sort((a, b) =>
      a?.shelf_number.localeCompare(b?.shelf_number)
    );
    const seen = new Set();
    return sorted.filter((item) => {
      if (seen.has(item.shelf_number)) {
        return false;
      } else {
        seen.add(item.shelf_number);
        return true;
      }
    });
  }

  const getSizes = useCallback(async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        district_id: corporateAccessLevelDistrictId
      };
      const response = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.getsizesfordistrict}`,
        1
      );
      const sortedData = response.data[0]?.sort((a, b) =>
        a.insert_size.localeCompare(b.insert_size)
      );
      const findIniSize = sortedData?.find(
        (o) => o?.insert_size === insertSizeDefaultValue
      );
      setSize(findIniSize?.insert_size);
      setSizeData(sortedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  }, [currentBranch?.value, setSize, setThrowError, setThrowErrorMessage]);
  function checkSameLogicalVaultId(data) {
    if (data.length === 0) return false;

    const firstVaultId = data[0].logical_vault_id;

    return data.every((item) => item?.logical_vault_id === firstVaultId);
  }
  const getCapacity = useCallback(async () => {
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: currentBranch?.value,
        logical_vault_id: selectLogicalVault,
        physical_vault_id: vault === all ? "" : vault,
        row_id: row === all ? "" : row,
        rack_id: rack === all ? "" : rack,
        shelf_id: shelf === all ? "" : shelf
      };
      const response = await getResponseData(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.getCapacity}`,
        1
      );
      const capacity = response?.data[0];
      const reqCount = tableData?.reduce((acc, cur) => {
        return acc + parseInt(cur?.count);
      }, 0);
      setRequiredCapacity(reqCount);
      const Vault = removeVaultDuplicates(capacity);
      setVaultList((prev) => removeVaultDuplicates([...prev, ...Vault]));
      setLoading(false);
      setVaultCount(capacity);
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  }, [
    currentBranch?.value,
    vault,
    selectLogicalVault,
    row,
    shelf,
    rack,
    tableData,
    setThrowError,
    setThrowErrorMessage
  ]);
  useEffect(() => {
    const count = vaultCount
      ?.filter((o) => o?.physical_vault_id !== all)
      ?.reduce((acc, cur) => {
        return acc + parseInt(cur?.empty_slot_count);
      }, 0);
    setAvailableCapacity(count);
  }, [vaultList, vaultCount]);

  useEffect(() => {
    getSizes();
  }, [getSizes]);
  const handleSpecifyLocation = () => {
    if (specifyLocation) {
      setRequiredCapacity("0");
      setSpecifyLocation(false);
      setAvailableCapacity("0");
      setVaultCount([]);
      setShelf(all);
      setRack(all);
      setRow(all);
      setVualt(all);
      setShelfList([
        {
          shelf_number: t("common.all"),
          shelf_id: all
        }
      ]);
      setRackList([
        {
          rack_number: t("common.all"),
          rack_id: all
        }
      ]);
      setRowList([
        {
          row_number: t("common.all"),
          row_id: all
        }
      ]);
      setVaultList([
        {
          physical_vault_number: t("common.all"),
          physical_vault_id: all
        }
      ]);
    } else {
      setSpecifyLocation(true);
      getCapacity();
    }
  };
  const getVaultList = async () => {};
  useEffect(() => {
    getVaultList();
  }, []);
  const handleVault = async (e) => {
    setRow(all);
    setShelf(all);
    setRack(all);
    setVualt(e);
    const reqBody = {
      main_district_id: currentBranch?.value,
      logical_vault_id: selectLogicalVault,
      physical_vault_id: e === all ? "" : e,
      row_id: "",
      rack_id: "",
      shelf_id: ""
    };
    const response = await getResponseData(
      reqBody,
      `${CF_URLS.openMediaProcessing?.workManagement.picking.getCapacity}`,
      1
    );
    const capacity = response.data[0];
    setVaultCount(capacity);
    e === all
      ? setRowList([
          {
            row_number: t("common.all"),
            row_id: all
          }
        ])
      : setRowList((prev) =>
          removeRowDuplicates([
            ...[
              {
                row_number: t("common.all"),
                row_id: all
              }
            ],
            ...capacity
          ])
        );

    setShelfList([
      {
        shelf_number: t("common.all"),
        shelf_id: all
      }
    ]);
    setRackList([
      {
        rack_number: t("common.all"),
        rack_id: all
      }
    ]);
  };

  const handleRow = async (e) => {
    setRow(e);
    const reqBody = {
      main_district_id: currentBranch?.value,
      logical_vault_id: selectLogicalVault,
      physical_vault_id: vault === all ? "" : vault,
      row_id: e === all ? "" : e,
      rack_id: "",
      shelf_id: ""
    };
    const response = await getResponseData(
      reqBody,
      `${CF_URLS.openMediaProcessing?.workManagement.picking.getCapacity}`,
      1
    );
    const capacity = response.data[0];
    setVaultCount(capacity);
    e === all
      ? setRackList([
          {
            rack_number: t("common.all"),
            rack_id: all
          }
        ])
      : setRackList((prev) =>
          removeRackDuplicates([
            ...[
              {
                rack_number: t("common.all"),
                rack_id: all
              }
            ],
            ...capacity
          ])
        );

    setShelfList([
      {
        shelf_number: t("common.all"),
        shelf_id: all
      }
    ]);
    setShelf(all);
    setRack(all);
  };
  const handleRack = async (e) => {
    setRack(e);
    const reqBody = {
      main_district_id: currentBranch?.value,
      logical_vault_id: selectLogicalVault,
      physical_vault_id: vault === all ? "" : vault,
      row_id: row === all ? "" : row,
      rack_id: e === all ? "" : e,
      shelf_id: ""
    };
    const response = await getResponseData(
      reqBody,
      `${CF_URLS.openMediaProcessing?.workManagement.picking.getCapacity}`,
      1
    );
    const capacity = response.data[0];

    e === all
      ? setShelfList([
          {
            shelf_number: t("common.all"),
            shelf_id: all
          }
        ])
      : setShelfList((prev) =>
          removeShelfDuplicates([
            ...[
              {
                shelf_number: t("common.all"),
                shelf_id: all
              }
            ],
            ...capacity
          ])
        );
    setShelf(all);
    setVaultCount(capacity);
  };
  const handleShelf = async (e) => {
    setShelf(e);
    const reqBody = {
      main_district_id: currentBranch?.value,
      logical_vault_id: selectLogicalVault,
      physical_vault_id: vault === all ? "" : vault,
      row_id: row === all ? "" : row,
      rack_id: rack === all ? "" : rack,
      shelf_id: e === all ? "" : e
    };
    const response = await getResponseData(
      reqBody,
      `${CF_URLS.openMediaProcessing?.workManagement.picking.getCapacity}`,
      1
    );
    const capacity = response.data[0];
    setVaultCount(capacity);
  };
  return (
    <>
      {loading && <FullCircularLoader />}
      {!openPrintModal && throwError && (
        <Alert
          severity="error"
          icon={<img src={ErrorIcon} alt="error" />}
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}
      <CommonModal
        open={openPrintModal}
        title={t("workManagement.distribution.printDistributionReport")}
        body={
          <>
            {throwError && (
              <Alert
                severity="error"
                icon={<img src={ErrorIcon} alt="error" />}
                action={
                  <IconButton
                    aria-label={t("common.close")}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setThrowError(false);
                      setThrowErrorMessage(null);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {throwErrorMessage}
              </Alert>
            )}
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("workManagement.distribution.insertSize")}
                  </InputLabel>
                  <Select
                    label={t("workManagement.distribution.insertSize")}
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  >
                    {sizeData?.map((o) => {
                      return (
                        <MenuItem value={o?.insert_size} key={o?.insert_size}>
                          {o?.insert_size}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6} lg={3}>
                <FormControlLabel
                  sx={WorkManagementStyles.formControllabelSx}
                  control={
                    <Checkbox
                      checked={specifyLocation}
                      onChange={handleSpecifyLocation}
                      color="primary"
                      disabled={!checkSameLogicalVaultId(tableData)}
                    />
                  }
                  label={t("workManagement.distribution.specifyLocation")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("workManagement.distribution.vault")}
                  </InputLabel>
                  <Select
                    label={t("workManagement.distribution.vault")}
                    disabled={
                      vaultList?.filter((o) => o?.physical_vault_id !== all)
                        ?.length === 0
                    }
                    value={vault}
                    onChange={(e) => handleVault(e?.target?.value)}
                  >
                    {vaultList?.map((o) => {
                      return (
                        <MenuItem
                          value={o?.physical_vault_id}
                          key={o?.physical_vault_number}
                        >
                          {o?.physical_vault_number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("workManagement.distribution.row")}
                  </InputLabel>
                  <Select
                    label={t("workManagement.distribution.row")}
                    disabled={
                      rowList?.filter((o) => o?.row_id !== all)?.length === 0
                    }
                    value={row}
                    onChange={(e) => handleRow(e?.target?.value)}
                  >
                    {rowList?.map((o) => {
                      return (
                        <MenuItem value={o?.row_id} key={o?.row_number}>
                          {o?.row_number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("workManagement.distribution.rack")}
                  </InputLabel>
                  <Select
                    label={t("workManagement.distribution.rack")}
                    disabled={
                      rackList?.filter((o) => o.rack_id !== all)?.length === 0
                    }
                    value={rack}
                    onChange={(e) => handleRack(e?.target?.value)}
                  >
                    {rackList?.map((o) => {
                      return (
                        <MenuItem value={o?.rack_id} key={o?.rack_number}>
                          {o?.rack_number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("workManagement.distribution.shelf")}
                  </InputLabel>
                  <Select
                    label={t("workManagement.distribution.shelf")}
                    disabled={
                      shelfList?.filter((o) => o?.shelf_id !== all).length === 0
                    }
                    value={shelf}
                    onChange={(e) => handleShelf(e?.target?.value)}
                  >
                    {shelfList?.map((o) => {
                      return (
                        <MenuItem value={o?.shelf_id} key={o?.shelf_number}>
                          {o?.shelf_number}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>{" "}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  {t("workManagement.distribution.availableCapacity")}
                </Typography>
                <Typography
                  sx={
                    vaultList?.filter((o) => o?.physical_vault_id !== all)
                      ?.length > 0 &&
                    WorkManagementStyles.capacityColor(
                      availableCapacity < requiredCapacity
                    )
                  }
                >
                  {!isNaN(availableCapacity) ? availableCapacity : zero}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography>
                  {t("workManagement.distribution.requiredCapacity")}
                </Typography>
                <Typography>{requiredCapacity}</Typography>
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button
              variant="outlined"
              onClick={() => {
                onClose("close");
                setVaultCount([]);
                setAvailableCapacity("0");
                setShelf(all);
                setVualt(all);
                setRack(all);
                setRow(all);
                setShelfList([
                  {
                    shelf_number: t("common.all"),
                    shelf_id: all
                  }
                ]);
                setRackList([
                  {
                    rack_number: t("common.all"),
                    rack_id: all
                  }
                ]);
                setVaultList([
                  {
                    physical_vault_number: t("common.all"),
                    physical_vault_id: all
                  }
                ]);
                setShelfList([
                  {
                    shelf_number: t("common.all"),
                    shelf_id: all
                  }
                ]);
              }}
            >
              {t("common.close")}
            </Button>
            <Button
              variant="contained"
              disabled={availableCapacity < requiredCapacity}
              onClick={() => logicalVualtCheck()}
            >
              {t("workManagement.distribution.print")}
            </Button>
          </>
        }
      />
    </>
  );
};
export default PrintDistributionModal;
