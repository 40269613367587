import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getRoleAccess } from "store/slices";

const useHasAccess = (programIds = []) => {
  const programList = useSelector(getRoleAccess);

  const hasAccess = useMemo(() => {
    if (!programList || programList.length === 0) {
      return [];
    }

    return programIds.map((programId) => {
      const isNewVisible = programList.includes(programId.toString());
      return isNewVisible;
    });
  }, [programIds, programList]);

  return hasAccess;
};

export default useHasAccess;
