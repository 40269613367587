import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  SvgIcon,
  TableContainer,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import { ContainerProcessingStyles } from "components/container-processing/ContainerProcessingStyles";
import { DatePickerField, FullCircularLoader } from "components/core";
import { t } from "i18next";
import { useEffect, useImperativeHandle, useState } from "react";
import spacing from "styles/spacing";

import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as EditIconBeforeTheSave } from "assets/images/EditIconBeforeSave.svg";
import {
  DEFAULT_DATA_TABLE_OPTIONS,
  DEFAULT_LOCALE,
  ERROR_TYPES,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y,
  Y_CHECKER,
  dateFormatWithoutTime,
  downloadReportOptions,
  errorMsgs,
  type,
  ymdDateFormat
} from "utils/constants";

import DownloadPopup from "components/core/modal-components/DownloadPopup";
import { labelPrinterSetupStyles } from "components/label-printing/label-printer-setup/LabelPrinterSetupStyles";
import { WorkManagementStyles } from "components/open-media-processing/work-management/WorkManagementStyles";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  GENERAL,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import {
  all,
  checkValue,
  checkedValue,
  missing,
  missingValue,
  systemfunctionid2,
  systemfunctionid3
} from "utils/constants/container-processing/ContainerProcessing";
import {
  findErrorMessage,
  getResponseData,
  getUserName,
  printReport
} from "utils/helpers";
import EditPickingModal from "./EditPickingModal";
import PickingPrintModal from "./PrintModal";
const { default: MUIDataTable } = require("mui-datatables");
const Picking = ({ madeChanges, setMadeChanges, pickRef, check, setCheck }) => {
  const params = useParams();
  let errorList = [];
  let validList = [];
  const { currentBranch, localeByBranch, timeZoneOffset } =
    useSelector(selectAuth);
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [runDates, setRunDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [runOptions, setRunOptions] = useState([]);
  const [selectedRun, setSelectedRun] = useState("");
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [selectedRuns, setSelectedRuns] = useState([]);
  const [batchId, setBatchId] = useState("");
  const [findStatus, setFindStatus] = useState(false);
  const { auth } = useSelector(selectAuth);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);
  const [openClearModal, setOpenClearModal] = useState(false);

  const [verifyMsg, setVerifyMsg] = useState("");
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(downloadReportOptions[0]);
  const [sort1, setSort1] = useState("");
  const [sort2, setSort2] = useState("");
  const [sort3, setSort3] = useState("");
  const [showReportedItems, setShowReportedItems] = useState(VALUE_N);
  const [temRun, setTempRun] = useState("");
  const [openAlertChangesModal, setOpenAlertChangesModal] = useState(false);
  const navigate = useNavigate();
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const [isPrintDiabled, setIsPrintDiabled] = useState(true);
  const [isPrintModalDiabled, setIsPrintModalDiabled] = useState(true);

  useEffect(() => {
    if (tableData?.length === 0) {
      setShowReportedItems(VALUE_Y);
    } else {
      setShowReportedItems(VALUE_N);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  useEffect(() => {
    if (tableData?.length === 0 && showReportedItems === VALUE_N) {
      setIsPrintModalDiabled(true);
    } else {
      setIsPrintModalDiabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showReportedItems, tableData]);

  const updatePrint = async () => {
    try {
      setLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: currentBranch.value,
        batch_id: batchId,
        run_name:
          selectedRun === all
            ? VALUE_EMPTY_STRING
            : runOptions.find((run) => run.run_id === selectedRun).run_name,
        run_date: dayjs(selectedDate).format(ymdDateFormat)
      });
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.containerProcessing.requestPrintUpdateSp}`,
        1
      );
      if (data) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setThrowErrorMessage(
        error.message || findErrorMessage(ERROR_TYPES.ISSUE)
      );
      setThrowError(true);
    }
  };
  useImperativeHandle(pickRef, () => ({
    verify
  }));
  const handleDownload = async () => {
    try {
      setLoading(true);
      setThrowError(false);
      const reqBody = {
        main_district_id: currentBranch?.value,
        district_id: currentBranch?.value,
        batch_id: batchId,
        previously_reported: showReportedItems,
        run_name:
          selectedRun === all
            ? VALUE_EMPTY_STRING
            : runOptions.find((run) => run.run_id === selectedRun).run_name,
        run_date: dayjs(selectedDate)
          .locale(localeByBranch)
          .format(ymdDateFormat),
        update_printed_flag: VALUE_Y,
        sort_one: sort1,
        sort_two: sort2,
        sort_three: sort3,
        district_name: currentBranch.name.replace("_", " "),
        user_name: getUserName(auth?.user),
        report_format: selectedValue?.type?.toUpperCase()
      };

      let reportResponse = await printReport(
        reqBody,
        `${CF_URLS.printReports.containerProcessing.rpMediaProcessingPick}`
      );
      if (!reportResponse.success) {
        setThrowErrorMessage(reportResponse.error);
        setThrowError(true);
      } else {
        updatePrint();
      }
    } catch (e) {
      setThrowErrorMessage(e.message || findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setLoading(false);
    }
  };

  const getRunDates = async () => {
    try {
      setLoading(true);
      const reqBody = JSON.stringify({
        main_district_id: currentBranch?.value
      });

      const response = await getResponseData(
        reqBody,
        `${CF_URLS.containerProcessing.getRunDates}`,
        1
      );
      const dates = response.data[0][0];
      const { oldest_run_date, newest_run_date } = dates;
      const reqDateBody = JSON.stringify({
        main_district_id: currentBranch?.value
      });
      setRunDates(dates);
      const localDateResponse = await getResponseData(
        reqDateBody,
        `${CF_URLS.containerProcessing.getLocalDateTime}`,
        1
      );
      const localDate = new Date(localDateResponse.data[0][0].local_getdate);
      const validDate =
        localDate >= new Date(oldest_run_date) &&
        localDate <= new Date(newest_run_date)
          ? localDate
          : new Date(newest_run_date);
      if (params.id) {
        setSelectedDate(new Date(params.date));
      } else {
        setSelectedDate(validDate);
      }
      const runReqData = {
        main_district_id: currentBranch?.value,
        run_date: params.id
          ? dayjs(new Date(params.date)).format(ymdDateFormat)
          : dayjs(validDate).format(ymdDateFormat),
        exchange_status_id: ""
      };

      const runsResponse = await getResponseData(
        runReqData,
        `${CF_URLS.containerProcessing.getReturnlistofruns}`,
        1
      );
      const runs = runsResponse.data[0];
      if (runs) {
        setLoading(false);
      }
      const sortedRunNames = runs.sort((a, b) =>
        a.run_name.localeCompare(b.run_name)
      );
      setRunOptions([
        {
          run_name: t("common.all"),
          run_id: all
        },
        ...sortedRunNames
      ]);
      setSelectedRun(all);
      setSelectedRuns([all, ...(sortedRunNames?.map((o) => o?.run_id) || [])]);
    } catch (error) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    if (currentBranch) getRunDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBranch]);
  useEffect(() => {
    if (runOptions?.filter((o) => o.run_id !== all)?.length > 0 && params.id) {
      handleFindClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, runOptions]);
  useEffect(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZoneOffset, currentBranch]);
  const handleCheckboxChange = () => {
    if (check === checkValue) {
      setCheck("");
      let selectRow = selectedRow;
      selectRow.discrep_type_code = check === checkValue ? "" : missing;
      selectRow.discrep_system_function =
        check === checkValue ? "" : missingValue;
      selectRow.create_discrepancy_flag =
        check === checkValue ? "" : checkedValue;
      setSelectedRow(selectRow);
      setTableData((prevData) =>
        prevData.map((item, i) =>
          i === selectedIndex
            ? {
                ...item,
                discrep_type_code: check === checkValue ? "" : missing,
                discrep_system_function:
                  check === checkValue ? "" : missingValue,
                create_discrepancy_flag:
                  check === checkValue ? "" : checkedValue
              }
            : item
        )
      );
      setMadeChanges(false);
    } else {
      setMadeChanges(true);
      setCheck(checkValue);
      let selectRow = selectedRow;
      selectRow.discrep_type_code = check === checkValue ? "" : missing;
      selectRow.discrep_system_function =
        check === checkValue ? "" : missingValue;
      selectRow.create_discrepancy_flag =
        check === checkValue ? "" : checkedValue;
      setSelectedRow(selectRow);
      setTableData((prevData) =>
        prevData.map((item, i) =>
          i === selectedIndex
            ? {
                ...item,
                discrep_type_code: check === checkValue ? "" : missing,
                discrep_system_function:
                  check === checkValue ? "" : missingValue,
                create_discrepancy_flag:
                  check === checkValue ? "" : checkedValue
              }
            : item
        )
      );
    }
  };

  const openPrint = async () => {
    if (!getVerifyBtnStatus()) {
      const verifyDescr = await getMessageFromCode(errorMsgs.errorCode20343);
      const msg = verifyDescr[0]?.descr?.replace(
        "|",
        t("containerProcessing.pickingTab")
      );
      setVerifyMsg(msg);
      setOpenVerifyModal(true);
    } else {
      setOpenPrintModal(true);
    }
  };
  const print = async () => {
    const idsToCheck = selectedRuns;

    const matchedRuns = runOptions.filter((run) =>
      idsToCheck.includes(run.run_id)
    );
    try {
      if (!getVerifyBtnStatus() || !tableData.length) {
        setShowPrintModal(true);
        setOpenPrintModal(false);
      } else {
        setLoading(true);
        const employee_id = auth?.user?.id;
        const reqBody = {
          system_function_id: systemfunctionid3,
          employee_id: employee_id,
          main_district_id: currentBranch?.value,
          container: tableData?.map((o) => ({
            container_id: o.container_id,
            create_discrepancy_flag: checkValue,
            container_ts: o?.timestamp
          })),
          run: matchedRuns
            ?.filter((o) => o?.run_id !== all)
            .map((o) => ({
              run_id: o?.run_id
            }))
        };

        const performMediaLifeCycleFunctionsRes = await getResponseData(
          reqBody,
          `${CF_URLS.containerProcessing.getPerformMediaLifeCycleFunctions}`,
          2
        );
        const batchId = performMediaLifeCycleFunctionsRes?.data[0][0]?.batch_id;
        if (batchId) {
          setBatchId(batchId);
          findCall(true, batchId);
          setLoading(false);
          setOpenPrintModal(false);
          setShowPrintModal(true);
        } else {
          setLoading(false);
          setThrowError(true);
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const getVerifyBtnStatus = () => {
    return !madeChanges;
  };
  const tableTitleSection = (
    <Box pt={spacing.padding}>
      <Stack
        direction="row"
        spacing={10}
        mt={spacing.verticalMargin32}
        mb={spacing.verticalMargin16}
      >
        <Typography variant="body1" style={WorkManagementStyles.titleStyle}>
          {tableData.length} {t("common.recordsFound")}
        </Typography>
      </Stack>
    </Box>
  );
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isSelectedRow =
            selectedRow && selectedRow.i === tableMeta.rowIndex;
          const isEdited = tableData[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && isModalOpen
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;
          return (
            <Button
              onClick={() => {
                handleRowClick(value, tableMeta);
              }}
            >
              <SvgIcon component={iconComponent} />
            </Button>
          );
        }
      }
    },
    {
      name: "run_name",
      label: t("containerProcessing.picking.run")
    },
    {
      name: "run_date",
      label: t("containerProcessing.picking.serviceDates"),
      options: {
        customBodyRender: (value) => {
          return dayjs
            .utc(value)
            .locale(localeByBranch)
            .format(dateFormatWithoutTime);
        }
      }
    },
    {
      name: "cust_number",
      label: t("containerProcessing.picking.customer")
    },
    {
      name: "short_descr",
      label: t("containerProcessing.picking.mediaType")
    },
    {
      name: "number",
      label: t("containerProcessing.picking.cont")
    },
    {
      name: "slotting_location",
      label: t("containerProcessing.picking.location")
    },
    {
      name: "type",
      label: t("containerProcessing.picking.status")
    },
    {
      name: "",
      label: t("containerProcessing.picking.notFound"),
      options: {
        customBodyRender: (value, tableMeta) => {
          const pick = tableData[tableMeta.rowIndex];
          return (
            <Checkbox
              checked={pick?.discrep_system_function === missingValue}
              disabled={true}
              color="primary"
            ></Checkbox>
          );
        }
      }
    },
    {
      name: "discrep_type_code",
      label: t("containerProcessing.picking.discrepancy")
    }
  ];
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    pagination: 0 && true,
    textLabels: {
      body: {
        noMatch:
          tableData?.length === 0 && `${t("common.tableTextWithoutInputs")}`
      }
    },
    customToolbar: () => (
      <>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="end"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={isPrintDiabled}
            onClick={() => openPrint()}
          >
            {t("containerProcessing.picking.Print")}
          </Button>
          <Button
            variant="outlined"
            size="medium"
            type="button"
            disabled={getVerifyBtnStatus()}
            onClick={() => verify()}
          >
            {t("containerProcessing.picking.verify")}
          </Button>
        </Grid>
      </>
    ),
    setRowProps: (dataIndex) => {
      const rowData = tableData[dataIndex];
      const completedFlag = rowData ? rowData.completed_flag : null;
      return {
        style: {
          backgroundColor:
            dataIndex === 2
              ? ContainerProcessingStyles?.selectedColor
              : completedFlag === Y_CHECKER
                ? ContainerProcessingStyles?.completeColor
                : ""
        }
      };
    }
  };
  const handleDateChange = async (newDate) => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_CONTAINER_PROCESSING_BASE_PATH}/${GENERAL}`,
      { replace: true }
    );
    setSelectedDate(newDate);
    setFindStatus(false);
    setIsPrintDiabled(true);
    setTableData([]);
    setBatchId("");
    setThrowError(false);
    try {
      setLoading(true);
      const runReqData = {
        main_district_id: currentBranch?.value,
        run_date: dayjs(newDate).format(ymdDateFormat),
        exchange_status_id: ""
      };
      const runsResponse = await getResponseData(
        runReqData,
        `${CF_URLS.containerProcessing.getReturnlistofruns}`,
        1
      );

      const runs = runsResponse.data[0];
      if (runs) {
        setLoading(false);
      }
      const sortedRunNames = runs.sort((a, b) =>
        a.run_name.localeCompare(b.run_name)
      );
      setRunOptions([
        {
          run_name: t("common.all"),
          run_id: all
        },
        ...sortedRunNames
      ]);
      setSelectedRun(all);
      setSelectedRuns([all, ...(sortedRunNames?.map((o) => o?.run_id) || [])]);
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const validateRun = async () => {
    let warningMessage = "";
    if (
      selectedRuns.includes(all) &&
      runOptions.filter((o) => o.run_id !== all)?.length === 0
    ) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20345);
      warningMessage = errDesc[0]?.descr;
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(warningMessage);
      return;
    }

    try {
      const filteredRuns = selectedRuns.filter((o) => o !== all);

      // Process all runs in parallel
      const results = await Promise.all(
        filteredRuns.map(async (runId) => {
          const reqBody = {
            main_district_id: currentBranch?.value,
            run_id: runId
          };
          const determineExchangeStatusResponse = await getResponseData(
            reqBody,
            `${CF_URLS.containerProcessing.getDetermineExchangeStatusId}`,
            1
          );

          if (determineExchangeStatusResponse.data[0][0]) {
            const { current_exchange_status_id } =
              determineExchangeStatusResponse.data[0][0];

            const runReqBody = {
              main_district_id: currentBranch?.value,
              system_function_id: systemfunctionid2,
              current_exchange_status_id,
              called_by_sp: VALUE_N
            };

            const runRuleResponse = await getResponseData(
              runReqBody,
              `${CF_URLS.containerProcessing.getRunRule}`,
              1
            );
            if (runRuleResponse?.data[0][0]?.next_exchange_status_id) {
              return { isValid: false, runId, current_exchange_status_id };
            } else {
              const runs = runOptions?.find((obj) => obj?.run_id === runId);
              return {
                isValid: true,
                runs,
                error: determineExchangeStatusResponse.data[0][0]
              };
            }
          } else {
            throw new Error(findErrorMessage(ERROR_TYPES.ISSUE));
          }
        })
      );

      // Process results
      results.forEach((result) => {
        if (!result.isValid) {
          validList.push({
            runId: result.runId,
            current_exchange_status_id: result.current_exchange_status_id
          });
        } else {
          errorList.push(result.error);
        }
      });
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  const handleFindClick = async () => {
    if (!selectedDate) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20295);
      setThrowErrorMessage(
        errDesc[0].descr.replace("|", t("workManagement.picking.numberOfDays"))
      );
      setThrowError(true);
      return;
    }

    try {
      setLoading(true);
      if (batchId) {
        const reqBody = {
          main_district_id: currentBranch?.value,
          batch_id: batchId
        };

        const conditionFlagsResponse = await getResponseData(
          reqBody,
          `${CF_URLS.containerProcessing.getConditionalFlag}`,
          2
        );
        const isValid = conditionFlagsResponse.data[0][0]?.batch_exists_flag;

        if (isValid === VALUE_Y) {
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20373);
          setThrowError(true);
          setThrowErrorMessage(errDesc[0].descr);
        }
        const DetermineUnassociatedContainerResponse = await getResponseData(
          reqBody,
          `${CF_URLS.containerProcessing.getDetermineUnassociatedContainer}`,
          1
        );

        if (DetermineUnassociatedContainerResponse.data[0]?.length > 0) {
          let msg;
          DetermineUnassociatedContainerResponse.data[0]?.forEach((record) => {
            msg += `\n${t("containerProcessing.picking.customerId")} ${
              record.customer_code
            } - ${t("containerProcessing.picking.container")} # ${
              record.number
            }`;
          });
          setLoading(false);
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20389);
          setThrowError(true);
          setThrowErrorMessage(errDesc[0].descr.replace("|", msg));
          return;
        }
      }

      await validateRun();
      findCall();
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const findCall = async (printCheck, batch, verifycheck) => {
    try {
      setLoading(true);
      const reqBodyRun = {
        batch_id: printCheck ? batch : batchId,
        main_district_id: currentBranch?.value,
        run_id_list: validList
          ?.map((item) => {
            const runIdStr = item.runId.toString();
            const formattedRunId = runIdStr.padStart(10, " ");
            return { ...item, runId: formattedRunId };
          })
          ?.map((obj) => obj?.runId)
          .join(""),
        system_function_id: systemfunctionid2
      };
      const response = await getResponseData(
        reqBodyRun,
        `${CF_URLS.containerProcessing.getReturnRun}`,
        1
      );
      if (response.data) {
        if (response.data[0][0]?.error) {
          setLoading(false);
          const errDesc = await getMessageFromCode(errorMsgs.errorCode20342);
          const err = errDesc[0].descr.replace(
            "|",
            errorList
              ?.filter((o) => o.run_id !== all)
              ?.map((o) => o.run_name + ` - ${o?.current_status}`)
          );
          setMadeChanges(false);
          setThrowError(true);
          setThrowErrorMessage(err);
        } else {
          if (!batchId) {
            if (errorList.length > 0) {
              const errDesc = await getMessageFromCode(
                errorMsgs.errorCode20342
              );
              const err = errDesc[0].descr.replace(
                "|",
                errorList
                  ?.filter((o) => o.run_id !== all)
                  ?.map((o) => ` ${o.run_name} - ${o.highest_status}`)
              );

              setThrowError(true);
              setThrowErrorMessage(err);
            }
          }

          const sortedData = response.data[0]?.sort((a, b) => {
            const runNameA = a.run_name?.trim();
            const runNameB = b.run_name?.trim();
            return runNameA.localeCompare(runNameB);
          });
          setOpenVerifyModal(false);
          setLoading(false);
          if (batchId || batch) {
            setMadeChanges(true);
          }
          if (verifycheck) {
            setMadeChanges(false);
            setCheck("");
          }

          setTableData(sortedData);
          setFindStatus(true);
          if (sortedData.length === 0) {
            setMadeChanges(false);
          }
        }
        if (
          (batchId === "" && response?.data[0]?.length >= 0) ||
          (batchId !== "" && response?.data[0]?.length > 0)
        ) {
          setIsPrintDiabled(false);
        }
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const confirmSelectRuns = () => {
    setSelectedRun(temRun);
    setTableData([]);
    setThrowError(false);

    setBatchId("");
    setFindStatus(false);
    if (temRun === all) {
      setSelectedRuns(runOptions.map((o) => o.run_id));
    } else {
      setSelectedRuns([temRun]);
    }
    setOpenAlertChangesModal(false);
    setMadeChanges(false);
  };

  const handleSelectRuns = async (e) => {
    setIsPrintDiabled(true);
    setTempRun(e);
    if (check) {
      setOpenAlertChangesModal(true);
    } else if (!getVerifyBtnStatus()) {
      const verifyDescr = await getMessageFromCode(errorMsgs.errorCode20343);
      const msg = verifyDescr[0]?.descr?.replace(
        "|",
        t("containerProcessing.pickingTab")
      );
      setVerifyMsg(msg);
      setOpenVerifyModal(true);
    } else {
      setSelectedRun(e);
      setTableData([]);
      setThrowError(false);

      setBatchId("");
      setFindStatus(false);
      if (e === all) {
        setSelectedRuns(runOptions.map((o) => o.run_id));
      } else {
        setSelectedRuns([e]);
      }
    }
  };
  const verify = async (cb) => {
    const idsToCheck = selectedRuns;
    const matchedRuns = runOptions.filter((run) =>
      idsToCheck.includes(run.run_id)
    );
    try {
      setLoading(true);
      const employee_id = auth?.user?.id;
      const reqBody = {
        main_district_id: currentBranch?.value,
        system_function_id: missingValue,
        employee_id: employee_id,
        container: tableData?.map((o) => ({
          container_id: o.container_id,
          create_discrepancy_flag: o.create_discrepancy_flag || checkValue,
          container_ts: o?.timestamp
        })),
        run: matchedRuns
          ?.filter((o) => o?.run_id !== all)
          .map((o) => ({
            run_id: o?.run_id
          }))
      };

      const performMediaLifeCycleFunctionsRes = await getResponseData(
        reqBody,
        `${CF_URLS.containerProcessing.getPerformMediaLifeCycleFunctions}`,
        2
      );
      if (performMediaLifeCycleFunctionsRes?.data[0][0].batch_id) {
        setLoading(false);
        findCall(
          false,
          performMediaLifeCycleFunctionsRes?.data[0][0].batch_id,
          true
        );
        setOpenClearModal(false);
        setOpenAlertChangesModal(false);
        setOpenVerifyModal(false);
        if (typeof cb === type.function) {
          cb();
        }
      } else {
        setLoading(false);
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const modalClear = () => {
    setFindStatus(false);
    setSelectedRun(all);
    setSelectedRuns(runOptions.map((o) => o.run_id));
    setBatchId("");
    setTableData([]);
    setThrowError(false);
    setFindStatus(false);
    setOpenClearModal(false);
    setMadeChanges(false);
  };
  const clear = async () => {
    if (!getVerifyBtnStatus()) {
      const verifyDescr = await getMessageFromCode(errorMsgs.errorCode20343);
      const msg = verifyDescr[0]?.descr?.replace(
        "|",
        t("containerProcessing.pickingTab")
      );
      setVerifyMsg(msg);
      setOpenClearModal(true);
    } else {
      setFindStatus(false);
      setSelectedRun(all);
      setSelectedRuns(runOptions.map((o) => o.run_id));
      setBatchId("");
      setTableData([]);
      setThrowError(false);
      setMadeChanges(false);
      setFindStatus(false);
      setIsPrintDiabled(true);
    }
  };

  const handleRowClick = (value, tableMeta) => {
    setSelectedRow(tableData[tableMeta?.rowIndex]);
    setSelectedIndex(tableMeta?.rowIndex);
    setIsModalOpen((prev) => !prev);
  };
  return (
    <>
      {loading && <FullCircularLoader />}
      {throwError && (
        <Alert
          severity="error"
          icon={
            <img
              style={labelPrinterSetupStyles.alertSize}
              src={ErrorIcon}
              alt="error"
            />
          }
          action={
            <IconButton
              aria-label={t("common.close")}
              color="inherit"
              size="small"
              onClick={() => {
                setThrowError(false);
                setThrowErrorMessage(null);
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        >
          {throwErrorMessage}
        </Alert>
      )}
      <br />
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3} xs={12}>
          <FormControl fullWidth>
            <DatePickerField
              id="serviceDateId"
              name="serviceDate"
              value={dayjs.utc(selectedDate).add(timeZoneOffsetChanged, "hour")}
              onChange={handleDateChange}
              label={t("containerProcessing.picking.serviceDate")}
              minDate={runDates ? dayjs(runDates.oldest_run_date) : null}
              maxDate={runDates ? dayjs(runDates.newest_run_date) : null}
              locale={localeByBranch || DEFAULT_LOCALE}
              key={timeZoneOffsetChanged}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <FormControl fullWidth variant="outlined">
            <>
              <InputLabel id="printerNameLabel">
                {t("containerProcessing.picking.run")}
              </InputLabel>
              <Select
                label={t("containerProcessing.picking.run")}
                name={"RunLabel"}
                value={selectedRun}
                onChange={(e) => handleSelectRuns(e.target.value)}
              >
                {runOptions?.map((runsObj) => {
                  return (
                    <MenuItem value={runsObj?.run_id} key={runsObj?.run_id}>
                      {runsObj?.run_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} xs={12}>
          <FormControl fullWidth variant="outlined">
            <>
              <TextField
                type="text"
                label={t("containerProcessing.picking.batchId")}
                value={batchId}
                onChange={(e) => {
                  setBatchId(e?.target?.value);
                  setFindStatus(false);
                  setIsPrintDiabled(true);
                }}
              />
            </>
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button id="newBtnfilter" variant="outlined" onClick={() => clear()}>
          {t("common.clear")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={findStatus}
          onClick={() => handleFindClick()}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <Paper>
        <TableContainer>
          <MUIDataTable
            title={tableData.length > 0 && tableTitleSection}
            columns={columns}
            options={options}
            data={tableData}
          />
        </TableContainer>
      </Paper>
      {isModalOpen && (
        <EditPickingModal
          isModalOpen={isModalOpen}
          check={check}
          setCheck={setCheck}
          setTableData={setTableData}
          selectedIndex={selectedIndex}
          localeByBranch={localeByBranch}
          handleCheckboxChange={(e) => handleCheckboxChange(e)}
          update={() => {
            setIsModalOpen(false);
          }}
          handleModalClose={() => {
            setMadeChanges(false);
            setIsModalOpen(false);
            setCheck("");
            let selectRow = selectedRow;
            selectRow.discrep_type_code = "";
            selectRow.discrep_system_function = "";
            selectRow.create_discrepancy_flag = "";
            setSelectedRow(selectRow);
            setTableData((prevData) =>
              prevData.map((item, i) =>
                i === selectedIndex
                  ? {
                      ...item,
                      discrep_type_code: "",
                      discrep_system_function: "",
                      create_discrepancy_flag: ""
                    }
                  : item
              )
            );
          }}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          madeChanges={madeChanges}
          setMadeChanges={setMadeChanges}
        ></EditPickingModal>
      )}
      {openPrintModal && (
        <PickingPrintModal
          print={() => print()}
          isModalOpen={openPrintModal}
          handleModalClose={() => setOpenPrintModal(false)}
          setShowPrintModal={setShowPrintModal}
          sort1={sort1}
          setSort1={setSort1}
          sort2={sort2}
          setSort2={setSort2}
          sort3={sort3}
          setSort3={setSort3}
          showReportedItems={showReportedItems}
          setShowReportedItems={setShowReportedItems}
          isPrintModalDiabled={isPrintModalDiabled}
          setIsPrintModalDiabled={setIsPrintModalDiabled}
        />
      )}
      {openVerifyModal && (
        <ConfirmPopup
          modalPopupOpen={openVerifyModal}
          message={verifyMsg}
          handleNo={() => {
            setOpenVerifyModal(false);
            print();
          }}
          handleYes={verify}
          handleCancel={() => setOpenVerifyModal(false)}
          showCancel={true}
          showNo={true}
        />
      )}
      {openClearModal && (
        <ConfirmPopup
          modalPopupOpen={openClearModal}
          message={verifyMsg}
          handleNo={() => modalClear()}
          handleYes={verify}
          handleCancel={() => setOpenClearModal(false)}
          showCancel={true}
          showNo={true}
        />
      )}
      {showPrintModal && (
        <DownloadPopup
          title={t("common.downloadReport")}
          showPrintModal={showPrintModal}
          downloadOptions={downloadReportOptions}
          onDownloadClick={handleDownload}
          setSelectedValue={setSelectedValue}
          onCancleClick={() => setShowPrintModal((prev) => !prev)}
        />
      )}
      {openAlertChangesModal && (
        <ConfirmPopup
          modalPopupOpen={openAlertChangesModal}
          message={t("containerProcessing.picking.saveErr")}
          handleNo={() => confirmSelectRuns()}
          handleYes={verify}
          handleCancel={() => setOpenAlertChangesModal(false)}
          showCancel={true}
          showNo={true}
        />
      )}
    </>
  );
};
export default Picking;
