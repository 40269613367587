import { Grid } from "@mui/material";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { DEFAULT_DATA_TABLE_OPTIONS } from "utils/constants";
import { getDateFromUTCFormat } from "utils/helpers";

const BatchDetail = ({ tableData }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (tableData?.length) {
      setData(
        tableData.map((item) => {
          return {
            ...item,
            completed_datetime_ltz: getDateFromUTCFormat(
              item?.completed_datetime_ltz,
              { withTime: true }
            )
          };
        })
      );
    }
  }, [tableData]);
  const options = {
    ...DEFAULT_DATA_TABLE_OPTIONS,
    textLabels: {
      body: {
        noMatch:
          (!tableData || tableData.length === 0) && `${t("common.noDataFound")}`
      }
    }
  };
  const columns = [
    {
      name: "batch_id",
      label: t("containerAuditDetails.batchId"),
      options: {
        sort: true
      }
    },
    {
      name: "completed_datetime_ltz",
      label: t("containerAuditDetails.batchDate"),
      options: {
        sort: true
      }
    },
    {
      name: "employee_name",
      label: t("containerAuditDetails.employee"),
      options: {
        sort: true
      }
    },
    {
      name: "audit_batch_status",
      label: t("containerAuditDetails.batchStatus"),
      options: {
        sort: true
      }
    },

    {
      name: "Scan_Count",
      label: t("containerAuditDetails.scanCount"),
      options: {
        sort: true
      }
    },

    {
      name: "Missing_Count",
      label: t("containerAuditDetails.missing"),
      options: {
        sort: true
      }
    },

    {
      name: "Unexpected_Count",
      label: t("containerAuditDetails.unexpected"),
      options: {
        sort: true
      }
    },
    {
      name: "Duplicate_Count",
      label: t("containerAuditDetails.duplicate"),
      options: {
        sort: true
      }
    }
  ];
  return (
    <Grid item xs={12} lg={3} marginTop={4}>
      <MUIDataTable
        columns={columns}
        data={data ? [...data] : []}
        options={options}
      />
    </Grid>
  );
};

export default BatchDetail;
