import { Divider, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { getDateFromUTCFormat } from "utils/helpers";
import { OpenMediaAuditStyles } from "./OpenMediaAuditDetailStyles";

const ShowFormattedDate = ({ value }) => {
  let formattedDate = "";
  if (value) {
    formattedDate = getDateFromUTCFormat(value);
  }
  return <Typography>{formattedDate}</Typography>;
};

const MediaCount = ({ viewData }) => {
  return (
    <Grid container spacing={2} sx={OpenMediaAuditStyles.fontColor}>
      <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
        <Divider />
      </Grid>

      {viewData?.audit_type_id && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.auditId")}
          <Typography> {viewData?.om_audit_id}</Typography>
        </Grid>
      )}
      {viewData?.audit_status && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.auditStatus")}
          <Typography>{viewData?.audit_status} </Typography>
        </Grid>
      )}
      {viewData?.created_employee_name && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.loggedBy")}
          <Typography>{viewData?.created_employee_name}</Typography>
        </Grid>
      )}
      {viewData?.created_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.loggedDate")}
          <ShowFormattedDate value={viewData?.created_datetime_ltz} />
        </Grid>
      )}
      {viewData?.started_datetime_ltz && viewData?.completed_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.auditDate")}
          <ShowFormattedDate value={viewData?.started_datetime_ltz} />
        </Grid>
      )}
      {viewData?.customer_number && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.customer")}
          <Typography>{viewData?.customer_number}</Typography>
        </Grid>
      )}
      {viewData?.requested_by_personnel_name && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.personnel")}
          <Typography>{viewData?.requested_by_personnel_name}</Typography>
        </Grid>
      )}

      {viewData?.cancel_employee_name && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.canceledBy")}
          <Typography>{viewData?.cancel_employee_name}</Typography>
        </Grid>
      )}
      {viewData?.cancel_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.canceledDate")}
          <ShowFormattedDate value={viewData?.cancel_datetime_ltz} />
        </Grid>
      )}
      {viewData?.completed_datetime_ltz && (
        <Grid item md={6} lg={3} xs={12}>
          {t("openMediaAuditDetail.completedDate")}
          <ShowFormattedDate value={viewData?.completed_datetime_ltz} />
        </Grid>
      )}
      {viewData?.audit_type_id && (
        <Grid item md={12} lg={12} xs={12} marginTop={3} marginBottom={3}>
          <Divider />
        </Grid>
      )}
      <Grid item md={6} lg={3} xs={12}>
        {t("openMediaAuditDetail.expectedMediaCount")}
        <Typography>{viewData?.expected_scan_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("openMediaAuditDetail.actualScanCount")}
        <Typography>{viewData?.scan_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("openMediaAuditDetail.unexpected")}
        <Typography>{viewData?.unexpected_count}</Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("openMediaAuditDetail.missing")}
        <Typography>{viewData?.missing_count}</Typography>
      </Grid>
    </Grid>
  );
};

export default MediaCount;
