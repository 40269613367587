import { getDocs, query, where } from "firebase/firestore";
import { callToCloudFunction } from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import {
  api_results_collection_resultset0,
  api_results_collection_resultset1,
  message_by_code_collection,
  unit_of_measure_collection
} from "services/firebase";
import {
  CANCELLED_BY_CUSTOMER_ID,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  PROGRAM_ID_VAULT_ITEMIZED,
  SECURE_SYNC_FLAG_Y
} from "utils/constants";
import { formatArrayAsString } from "utils/helpers";

/**
 * @module vaultItemized vaultItemized specific data requests module
 */
export const vaultItemized = {
  /**
   * Get all unit of measure
   *
   * @returns {object}
   */
  getUnitOfMeasure: async () => {
    let result = [];
    const q = query(unit_of_measure_collection);
    const querySnapshot = await getDocs(q);
    querySnapshot?.forEach((doc) => {
      result.push({ id: doc?.id, ...doc?.data() });
    });

    return result;
  },

  getValidateOpenMediaList: async ({
    mainDistrictId,
    customerId,
    volserList,
    startValidation
  }) => {
    const reqParama = {
      main_district_id: String(mainDistrictId),
      customer_id: String(customerId),
      volser_list: formatArrayAsString(volserList)
    };

    var requestBody = JSON.stringify(reqParama);
    if (startValidation) {
      try {
        let response = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_media_validate_sp}`,
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess && cfResponse.hasResults) {
          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response.push(formattedData);
          });

          if (response && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }
          return response;
        } else if (
          !cfResponse.isSuccess ||
          (cfResponse.isSuccess &&
            !cfResponse.hasResults &&
            volserList[0] !== "")
        ) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  },

  getPossibleMatchesList: async ({
    mainDistrictId,
    customerId,
    volserList,
    selectedVolser,
    followUp
  }) => {
    const reqParama = {
      main_district_id: String(mainDistrictId),
      customer_id: String(customerId),
      volser_list: formatArrayAsString(volserList),
      selected_volser: selectedVolser,
      follow_up: followUp
    };

    var requestBody = JSON.stringify(reqParama);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_validate_select_media_sp}`
      ).then((resp) => resp.data);

      if (cfResponse.isSuccess && cfResponse.hasResults) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap?.forEach((doc) => {
          const formattedData = {
            ...doc.data()
          };
          response.push(formattedData);
        });

        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  createMDRVaultItemized: async ({
    mainDistrictId,
    customerId,
    requestedPersonnelId,
    volserList,
    imComment,
    businessEmailAddr,
    loggedInEmployeeId,
    destructRequestId,
    destructRequestTypeId,
    saveButtonClicked,
    isCanUpdate
  }) => {
    const reqParama = {
      main_district_id: String(mainDistrictId),
      customer_id: String(customerId),
      requested_personnel_id: String(requestedPersonnelId),
      volser_list: formatArrayAsString(volserList),
      im_comment: String(imComment),
      business_email_addr: String(businessEmailAddr),
      logged_in_employee_id: String(loggedInEmployeeId),
      // destruct_request_id: destructRequestId,
      destruct_request_type_id: destructRequestTypeId
    };

    if (saveButtonClicked && !isCanUpdate) {
      var requestBody = JSON.stringify(reqParama);
      try {
        let response = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_request_i_sp}`
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess && cfResponse.hasResults) {
          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response.push(formattedData);
          });

          if (response && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }
          return response;
        } else if (!cfResponse.isSuccess) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  },

  updateMDRVaultItemized: async ({
    mainDistrictId,
    imComment,
    loggedInEmployeeId,
    unitOfMeasureId,
    destructReqTimestamp,
    destructRequestId,
    weight,
    systemId,
    saveButtonClicked,
    isCanUpdate
  }) => {
    const reqParama = {
      main_district_id: mainDistrictId,
      destruct_request_id: destructRequestId,
      unit_of_measure_id: unitOfMeasureId === "" ? null : unitOfMeasureId,
      im_comment: imComment,
      // (if comment not changed NULL)
      logged_in_employee_id:
        loggedInEmployeeId === "" ? null : loggedInEmployeeId,
      destruct_req_timestamp: destructReqTimestamp,
      // (sb_fs_destruct_request_detail_l_sp returns the timestamp)
      bulk_weight: weight === "" ? null : weight,
      system_id: systemId
    };

    if (saveButtonClicked && isCanUpdate) {
      var requestBody = JSON.stringify(reqParama);
      try {
        let response = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_request_u2_sp}`
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess && cfResponse.hasResults) {
          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response.push(formattedData);
          });

          if (response && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }
          return response;
        } else if (!cfResponse.isSuccess) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  },

  getPersonnelSecurity: async ({
    mainDistrictId,
    customerId,
    personnelId,
    userLogin,
    getPersonnelSecurity
  }) => {
    const reqParama = {
      main_district_id: mainDistrictId,
      // (login from personnel auth data)
      user_login: userLogin,
      program_id: PROGRAM_ID_VAULT_ITEMIZED,
      customer_id: customerId,
      secure_sync_flag: SECURE_SYNC_FLAG_Y,
      personnel_id: personnelId
    };

    var requestBody = JSON.stringify(reqParama);
    if (getPersonnelSecurity) {
      try {
        let response = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.check_security_access_sp}`
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess && cfResponse.hasResults) {
          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response.push(formattedData);
          });

          if (response && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }
          return response;
        } else if (!cfResponse.isSuccess) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  },

  cancelMDRVaultItemized: async ({
    mainDistrictId,
    cancelPersonnelId,
    loggedInEmployeeId,
    destructRequestId,
    cancelButtonClicked
  }) => {
    const reqParama = {
      main_district_id: mainDistrictId,
      destruct_request_id: destructRequestId,
      cancel_personnel_id: cancelPersonnelId,
      cancel_employee_id: loggedInEmployeeId,
      destruct_cancel_reason_id: CANCELLED_BY_CUSTOMER_ID
    };

    if (cancelButtonClicked) {
      var requestBody = JSON.stringify(reqParama);
      try {
        let response = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_request_cancel_sp}`,
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess) {
          if (cfResponse.hasResults) {
            const docId = cfResponse.docId;

            // fetch the document's resultset which CF returns
            const collectionSnap = await getDocs(
              api_results_collection_resultset0(docId)
            );
            //loop through the snapshot and add it to a variable
            collectionSnap?.forEach((doc) => {
              const formattedData = {
                ...doc.data()
              };
              response.push(formattedData);
            });
          }

          if (cfResponse.hasResults && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }

          if (!cfResponse.hasResults) {
            response = [
              {
                data: true
              }
            ];
          }
          return response;
        } else if (!cfResponse.isSuccess) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  },

  getExistingVaultItemizedMDR: async ({
    mainDistrictId,
    destructRequestId,
    languageLocaleId,
    systemId,
    isReadOnly,
    customerID
  }) => {
    const reqParama = {
      main_district_id: mainDistrictId,
      // If user edit the URL, only can get the MDR ID
      // customer_id: customerID,
      destruct_request_id: destructRequestId,
      language_locale_id: languageLocaleId,
      system_id: systemId
    };

    if (isReadOnly) {
      var requestBody = JSON.stringify(reqParama);
      try {
        let response = [];
        let response2 = [];
        // send the parameters to the CF and wait for the response
        const cfResponse = await callToCloudFunction(
          requestBody,
          `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.REQUEST_VAULT_ITEMIZED.destruct_request_detail_l_sp}`
        ).then((resp) => resp.data);

        if (cfResponse.isSuccess && cfResponse.hasResults) {
          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response.push(formattedData);
          });

          // fetch the document's resultset which CF returns
          const collectionSnap1 = await getDocs(
            api_results_collection_resultset1(docId)
          );
          //loop through the snapshot and add it to a variable
          collectionSnap1?.forEach((doc) => {
            const formattedData = {
              ...doc.data()
            };
            response2.push(formattedData);
          });

          response.push(response2);

          if (response && response[0]?.error) {
            let result = [];
            const filteredQuery = query(
              message_by_code_collection,
              where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
            );

            const querySnapshot = await getDocs(filteredQuery);
            querySnapshot?.forEach((doc) => {
              result.push({ id: doc?.id, ...doc?.data() });
            });

            response = [
              {
                error: result[0]?.descr
              }
            ];
          }
          return response;
        } else if (!cfResponse.isSuccess) {
          response = [
            {
              error: ERROR_TYPES.FAILED
            }
          ];
          return response;
        }
      } catch (err) {
        const response = [
          {
            error: ERROR_TYPES.EXCEPTION
          }
        ];
        return response;
      }
    }
  }
};
