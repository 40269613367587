import { FormControl, ThemeProvider } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocale, selectAuth } from "store/slices";
import {
  DEFAULT_LOCALE,
  localizationeEsPE,
  number100,
  weekFr
} from "utils/constants";
import { ReactComponent as DisabledDatePickerIcon } from "../../../assets/images/DatePickerDisableIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../../assets/images/DatePickerIcon.svg";
import { datePickerFieldTheme } from "./DatePickerField.Styles";

function DatePickerField({ helperText, error, onBlur, todayText, ...props }) {
  const { localeByBranch: locale } = useSelector(getLocale);
  const [isAvailableInDayjs, setIsAvailableInDayjs] = useState(false);
  const { currentBranch } = useSelector(selectAuth);
  useEffect(() => {
    // importing the locale file based on locale prop
    if (locale !== null) {
      import(`dayjs/locale/${locale}.js`)
        .then(() => {
          setIsAvailableInDayjs(true);
        })
        .catch((error) => {
          setIsAvailableInDayjs(false);
          dayjs.locale(DEFAULT_LOCALE);
        });
    }
  }, [locale]);

  let localeValue = DEFAULT_LOCALE;
  const isEsPE =
    localizationeEsPE === locale &&
    [number100].includes(currentBranch?.district_id);

  if (isEsPE || isAvailableInDayjs) {
    localeValue = locale;
  }

  const datePickerProps = {};

  if (isEsPE) {
    datePickerProps.dayOfWeekFormatter = (day) => `${weekFr[day]}`;
  }

  return (
    <ThemeProvider theme={datePickerFieldTheme}>
      <FormControl fullWidth>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={localeValue}
        >
          <DatePicker
            slots={{
              openPickerIcon: props.disabled
                ? DisabledDatePickerIcon
                : DatePickerIcon,
              day: props.highlightScheduleDays
            }}
            onBlur={onBlur}
            slotProps={{
              textField: {
                variant: "outlined",
                error,
                helperText,
                value: props?.value
              },
              actionBar: {
                actions: todayText ? todayText : ["today"]
              }
            }}
            {...datePickerProps}
            {...props}
          />
        </LocalizationProvider>
      </FormControl>
    </ThemeProvider>
  );
}

export default DatePickerField;
