import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";
import WarningIcon from "assets/images/WarningAmberOutlined.svg";
import { CircularLoaderFullPage } from "components/core";
import { useFormik } from "formik";
import { useCallbackPrompt } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  EVENT,
  EVENT_SEARCH,
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH,
  VIEW_DR_MEDIA
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import { selectAuth, setShowConfirmationModal } from "store/slices";

import { useNavigate } from "react-router-dom";
import {
  getCountries,
  getCustomersQueryData,
  getLocaleById,
  getMessageFromCode
} from "services/api/query";
import { getMessageByIdFromMessage } from "services/common";
import {
  cancelConfirmation,
  cancelExitProcedure,
  cancelSaveProcedure,
  resetRouteState,
  selectRoute,
  setHaveChanges
} from "store/slices";
import spacing from "styles/spacing";
import {
  CANCELLED,
  COMPLETE,
  dateFormat,
  dateFormatWithTime,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  drprocedureAttributeId,
  ERROR_TYPES,
  errorMsgs,
  FIREBASE_FIELD_NAMES,
  hyphen,
  numberConstants,
  ROLE_ACCESS_CODES,
  SYSTEM_ID_SecureBase,
  VALUE_EMPTY_STRING,
  VALUE_N,
  VALUE_Y
} from "utils/constants";
import {
  customerFilter,
  deleteMultipleFilesFromFB,
  downloadFileFromFB,
  findArrayElementByValue,
  findErrorMessage,
  formatDateByLocale,
  getAuthenticatedUserBranch,
  getDateFromUTCFormat,
  getDrInstructionMainFolderPath,
  getGlobalAttributeValue,
  getGsFilePath,
  getResponseData,
  getSortedData,
  getTheFirestoreErrorMessage,
  openMinimizedWindow,
  uploadDrInstructionFilesToGcs,
  validateUserHasProgramId
} from "utils/helpers";
import { disasterRecoveryStyles } from "./DisasterRecoveryStyles";
import EventDetailInstructions from "./EventDetailInstructions";
import EventDetailsTable from "./EventDetailsTable";
import RequestInfo from "./RequestInfo";
import { filterSchema } from "./schema";

import { ChevronLeft } from "@mui/icons-material";
import ModalPopup from "components/core/modal-components/ModalPopup";
import SuccessPopup from "components/core/success-popup/SuccessPopup";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { customer } from "services/common/customer";
import { setLocationValue } from "store/slices/disater-module/eventSearch";
import {
  deliveryRequest,
  drInstructionsDelete,
  drInstructionsDownloadWarningMessageCode,
  drInstructionsExist,
  drInstructionsNew,
  drOutBoundMessageCode,
  eventStatus,
  instMsgCodes,
  otherRequest,
  phoneRegex
} from "utils/constants/disaster-module/EventSearchConstants";
import { zero } from "utils/constants/disaster-module/InboundDateConst";
import Distribution from "./Distribution";
import DRRequestGeneratorModal from "./DRRequestGeneratorModal";

const EventDetailLayout = ({ setRouteData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { timeZoneOffset } = useSelector(selectAuth);
  const [isValid257Role, setIsValid257Role] = useState(
    validateUserHasProgramId(ROLE_ACCESS_CODES.code_257)
  );
  const isValid264Role = validateUserHasProgramId(ROLE_ACCESS_CODES.code_264);
  const routeParams = useParams();
  const pageIsViewOnly = useMemo(() => Boolean(routeParams?.id), [routeParams]);
  const drEventIdToEdit = useMemo(
    () => Number(routeParams.id),
    [routeParams?.id]
  );

  const _ = require("lodash");

  const { auth, localeByBranch } = useSelector(selectAuth);

  const eventTypeDefaultSelected = {
    Actual: "A"
  };

  const initFilterState = {
    customer: "",
    drEventDate: dayjs().startOf("h"),
    eventCoordinator: "",
    drEventStatusId: "0",
    status: "",
    eventType: eventTypeDefaultSelected.Actual,
    drEventId: "",
    declaredByPersonnel: "",
    verifiedByPersonnel: "",
    hotSiteEventID: "",
    customerContact: "",
    countryCode: "",
    primaryPhone: "",
    primaryCellular: "",
    secondaryPhone: "",
    secondaryCellular: "",
    expiredMediaOption: VALUE_N,
    expiredMediaReturnDate: "",
    retainReturnDateFlag: VALUE_Y,
    timestamp: "",
    loggedBy: "",
    loggedDate: "",
    initiatedBy: "",
    initiatedDate: "",
    inboundCompleteBy: "",
    inboundCompleteDate: "",
    outboundCompleteBy: "",
    outboundCompleteDate: "",
    cancelBy: "",
    cancelDate: "",
    completeBy: "",
    completeDate: ""
  };

  // react states ----------
  const { haveChanges, showConfirmationModal } = useSelector(selectRoute); // select route slice
  const dispatch = useDispatch(); // redux actions dispatcher
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(haveChanges); // browser back and forward blocker hook
  const eventLocation = useSelector(({ eventSearch }) => eventSearch);
  const [exitAfterSave, setExitAfterSave] = useState(false); // after saving request exit the page or not state

  const [isLoading, setIsLoading] = useState(false);

  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const handleOpenConfirmModel = () => setOpenConfirmModel(true);
  const handleCloseConfirmModel = () => setOpenConfirmModel(false);
  const [confirmModelContent, setConfirmModelContent] = useState();
  const [modelButtons, setModelButtons] = useState();
  const [modelTitle, setModelTitle] = useState("");
  const [showRequestGeneratorModal, setShowRequestGeneratorModal] =
    useState(false); // show/hide state of request generator modal
  const [instDeleteWarning, setInstDeleteWarning] = useState("");
  const [instReplaceWarning, setInstReplaceWarning] = useState("");
  const [declaredByPersonnelError, setDeclaredByPersonnelError] = useState("");
  const [timeZoneOffsetChanged, setTimeZoneOffsetChanged] = useState(false); // timezone offset value changed
  const currentDate = useMemo(() => {
    let date = dayjs.utc(new Date());
    if (timeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(timeZoneOffset));
    }
    setTimeZoneOffsetChanged((prevState) => !prevState);
    return date;
  }, [timeZoneOffset]);

  const EventDetailForm = useFormik({
    initialValues: initFilterState,
    validationSchema: filterSchema
  });

  //if the Page with view mode and change the  values the save button will enabled
  const [formInitVal, setFormInitVal] = useState(initFilterState);

  // formik ----------
  const [eventCoordinator, setEventCoordinator] = useState([]);
  const [allCountries, setAllCountries] = useState([]);
  const [defaultCountryCode, setDefaultCountryCode] = useState({});
  const [customerSelected, setCustomerSelected] = useState();
  const [customerElKey, setCustomerElKey] = useState(false); // customer element key
  const [personnelSelected, setPersonnelSelected] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [fetchSavedRecord, setFetchSavedRecord] = useState(false);
  const [enableReconcile, setEnableReconcile] = useState(false);
  const [eventTypes, setEventTypes] = useState([]);
  const [personnelList, setPersonnelList] = useState([]);
  const [instructionsList, setInstructionsList] = useState([]);
  const [instructionsDBList, setInstructionsDBList] = useState([]);
  const [requestInfo, setRequestInfo] = useState([]);

  const [authModalSuccess, setAuthModalSuccess] = useState(false);
  const [authRequestModalVisible, setAuthRequestModalVisible] = useState(false); // Auth request modal show/hide state
  const [authRequestForDeclared, setAuthRequestForDeclared] = useState(false);
  const [authRequestForVerified, setAuthRequestForVerified] = useState(false);
  const [enableDelete, setEnableDelete] = useState(false);
  const [enableView, setEnableView] = useState(false);
  const [enableAttach, setEnableAttach] = useState(false);
  const [enableInst, setEnableInst] = useState(false);

  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [ShowSuccessMessage, setShowSuccessMessage] = useState("");
  const [throwWarning, setThrowWarning] = useState(false);
  const [throwWarningMessage, setThrowWarningMessage] = useState("");
  const [authSuccessMessage, setAuthSuccessMessage] = useState("");
  const [customers, setCustomers] = useState([]);
  const [instMsgList, setInstMsgList] = useState([]);

  //selected row
  const [selectedRow, setSelectedRow] = useState(null);

  //clicked row data
  const [clickedRowData, setClickedRowData] = useState(null);

  // Show Request generator modal after confirm
  const [awaitRequestGenerartorModal, setAwaitRequestGenerartorModal] =
    useState(false);
  // handler functions ----------
  const handleCustomerChange = (event, newValue) => {
    if (newValue != null) {
      if (EventDetailForm.values?.customer !== initFilterState.customer) {
        const changedFields = Object.keys(EventDetailForm.values).filter(
          (key) =>
            key !== customer &&
            EventDetailForm.values[key] !== initFilterState[key]
        );
        if (changedFields.length > 0) {
          dispatch(setShowConfirmationModal(true));
          setCustomerElKey((prevState) => !prevState);
          return;
        }
      }
      setCustomerSelected(newValue.value);
      EventDetailForm.setFieldValue("customer", newValue);
    } else {
      EventDetailForm.setFieldValue("customer", "");
      setEnableInst(false);
    }
  };

  const handleDrEventDate = (date) => {
    EventDetailForm.setFieldValue("drEventDate", date);
  };

  const handleEventCoordinator = (event, employee) => {
    EventDetailForm.setFieldValue("eventCoordinator", employee);
  };

  const handleEventTypeChange = (event) => {
    EventDetailForm.setFieldValue("eventType", event.target.value);
  };

  const handleHotSiteEventID = (event) => {
    EventDetailForm.setFieldValue("hotSiteEventID", event.target.value);
  };

  const handleCustomerContact = (event, customer) => {
    EventDetailForm.setFieldValue("customerContact", event.target.value);
    EventDetailForm.handleChange({
      target: { name: "customerContact", value: customer }
    });
  };

  const handleCountryCode = (event, code) => {
    EventDetailForm.handleChange({
      target: { name: "countryCode", value: code }
    });
  };

  const handlePrimaryPhoneField = (event) => {
    if (phoneRegex.test(event.target.value)) {
      EventDetailForm.setFieldValue("primaryPhone", event.target.value);
    }
  };

  const handlePrimaryCellular = (event) => {
    if (event.target.checked) {
      EventDetailForm.setFieldValue("primaryCellular", VALUE_Y);
    } else {
      EventDetailForm.setFieldValue("primaryCellular", VALUE_N);
    }
  };

  const handleSecondaryPhone = (event) => {
    if (phoneRegex.test(event.target.value)) {
      EventDetailForm.setFieldValue("secondaryPhone", event.target.value);
    }
  };

  const handleSecondaryCellular = (event) => {
    if (event.target.checked) {
      EventDetailForm.setFieldValue("secondaryCellular", VALUE_Y);
    } else {
      EventDetailForm.setFieldValue("secondaryCellular", VALUE_N);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("dr_event_id");
    return () => {
      pageReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNavigate = () => {
    pageReset();
    setRouteData({
      eventId: "",
      customerNumber: ""
    });
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}`,
      {
        state: location?.state
      }
    );
  };

  const onGoBack = () => {
    let dataSet = location?.state;
    if (!dataSet) {
      dataSet = eventLocation?.location;
    }
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT_SEARCH}`,
      {
        state: dataSet
      }
    );
    dispatch(setLocationValue(null));
  };

  // handle the submit button click listener of request generator modal
  const handleRequestGeneratorOnSubmit = (generatorType, eventId, customer) => {
    if (generatorType === deliveryRequest) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${deliveryRequest}/${eventId}/${customer.value}/${customer.number}`,
        {
          state: location?.state
        }
      );
    } else {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${otherRequest}/${eventId}/${customer.value}`,
        {
          state: location?.state
        }
      );
    }
  };

  const CustomBodyRenderComponent = ({ value, locale }) => {
    const [formattedDate, setFormattedDate] = useState(null);

    useEffect(() => {
      const fetchData = async () => {
        const result = await formatDateByLocale(value, locale, false);
        setFormattedDate(result);
      };
      fetchData();
    }, [value, locale]);

    return formattedDate;
  };

  // init page title
  const initPageTitle = async (drEventId, customerNumber) => {
    setRouteData((prevState) => ({
      eventId: drEventId,
      customerNumber: customerNumber.trim()
    }));
  };

  //handle view DR media button
  const handleViewDrMedia = () => {
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${VIEW_DR_MEDIA}/${
        routeParams.id
      }/${EventDetailForm?.values?.customer.label?.split("-")[0].trim()}/${
        EventDetailForm?.values?.customer?.value
      }`,
      {
        state: location?.state
      }
    );
  };

  const handleDrEventHistory = () => {
    setConfirmModelContent(
      <>
        <Stack direction={"column"} gap={spacing.gap10}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.loggedDate")}
              id="logged-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.loggedDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.loggedBy")}
              id="logged-by"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.loggedBy}
              disabled="true"
            />
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.initiatedDate")}
              id="initiated-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.initiatedDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.initiatedBy")}
              id="initiated-by"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.initiatedBy}
              disabled="true"
            />
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.outboundCompleteDate")}
              id="outbound-complete-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.outboundCompleteDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.outboundCompleteBy")}
              id="outbound-complete-by"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              size="medium"
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.outboundCompleteBy}
              disabled="true"
            />
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.inboundCompleteDate")}
              id="inbound-complete-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.inboundCompleteDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.inboundCompleteBy")}
              id="inbound-complete-by"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.inboundCompleteBy}
              disabled="true"
            />
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.completeDate")}
              id="complete-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.completeDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.completeBy")}
              id="complete-by"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.completeBy}
              disabled="true"
            />
          </Stack>
        </Stack>
        <Stack direction={"column"} gap={spacing.gap}>
          <Stack direction="row" gap={spacing.gap}>
            <TextField
              label={t("disasterRecovery.eventDetail.cancelDate")}
              id="cancel-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.cancelDate}
              disabled="true"
            />
            <TextField
              label={t("disasterRecovery.eventDetail.cancelBy")}
              id="cancel-date"
              size="medium"
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
              sx={disasterRecoveryStyles.commonStyles.textLabel}
              type="text"
              value={EventDetailForm?.values?.cancelBy}
              disabled="true"
            />
          </Stack>
        </Stack>
      </>
    );
    setModelTitle(t("disasterRecovery.eventDetail.drEventHistoryTitle"));
    setModelButtons(
      <Button variant="outlined" onClick={handleCloseConfirmModel}>
        {t("common.close")}
      </Button>
    );
    handleOpenConfirmModel();
  };

  const handleDrRequestGenerator = (ignoreChanges = false) => {
    // if DR event status is inbound
    if (EventDetailForm.values?.drEventStatusId === eventStatus.inBound) {
      navigate(
        `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_DISASTER_RECOVERY_BASE_PATH}/${EVENT}/${otherRequest}/${EventDetailForm.values?.drEventId}/${EventDetailForm.values?.customer?.value}`,
        {
          state: location?.state
        }
      );
    }
    if (ignoreChanges) {
      dispatch(setShowConfirmationModal(false));
    }

    if (!ignoreChanges && haveChanges) {
      dispatch(setShowConfirmationModal(true));
      setAwaitRequestGenerartorModal(true);
    } else if (
      EventDetailForm.values?.drEventStatusId === eventStatus.outBound
    ) {
      setShowRequestGeneratorModal((prevState) => true);
    }
  };

  const handelOutBound = () => {
    setIsLoading(true);
    setModelTitle("");
    getMessageById(
      drOutBoundMessageCode,
      `${t("disasterRecovery.eventDetail.retainExistingOMR")}s`,
      t("disasterRecovery.eventDetail.returnNextDay")
    );
    setModelButtons(
      <>
        <Button
          variant="outlined"
          onClick={() => handleOnSave(eventStatus.inBound, false, true)}
        >
          {t("common.yes")}
        </Button>
        <Button variant="outlined" onClick={handleCloseConfirmModel}>
          {t("common.no")}
        </Button>
      </>
    );
    handleOpenConfirmModel();
    setIsLoading(false);
  };

  const showDeclaredByPersonnalError = () => {
    setIsLoading(true);
    setModelTitle("");
    setConfirmModelContent(declaredByPersonnelError);
    setModelButtons(
      <>
        <Button variant="outlined" onClick={handleCloseConfirmModel}>
          {t("common.ok")}
        </Button>
      </>
    );
    handleOpenConfirmModel();
    setIsLoading(false);
  };

  const showAuthSuccess = (fieldName) => {
    setAuthSuccessMessage(
      t("disasterRecovery.eventDetail.authSuccessMeg").replace("|", fieldName)
    );
  };

  // init
  useEffect(() => {
    window.scrollTo({ top: 0 });
    setIsLoading(true);
    Promise.allSettled([
      getCustomers(),
      fetchEventCoordinator(),
      fetchEventTypes(),
      getCountryData(),
      fetchInstWarningMsg()
    ]).then(() => {
      if (!isNaN(drEventIdToEdit) && drEventIdToEdit > 0) {
        setFetchSavedRecord(true);
      } else {
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNaN(drEventIdToEdit) && drEventIdToEdit > 0 && fetchSavedRecord) {
      fetchSingleEventDetails(drEventIdToEdit);
    }
    // eslint-disable-next-line
  }, [fetchSavedRecord]);

  useEffect(() => {
    if (customerSelected) {
      setEnableInst(true);
      fetchPersonnelList();
      setThrowError(false);
    } else {
      setPersonnelList([]);
      setEnableInst(false);
    }
    // eslint-disable-next-line
  }, [customerSelected]);

  useEffect(() => {
    if (
      EventDetailForm?.values?.drEventStatusId !== eventStatus.complete &&
      clickedRowData != null
    ) {
      if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent ||
        EventDetailForm?.values?.drEventStatusId === eventStatus.logged
      ) {
        setEnableDelete(true);
      }
      if (
        EventDetailForm?.values?.drEventStatusId !== eventStatus.newEvent &&
        clickedRowData.status !== "new"
      ) {
        setEnableView(true);
      } else {
        setEnableView(false);
      }
    } else {
      setEnableDelete(false);
      setEnableView(false);
    }
    // eslint-disable-next-line
  }, [clickedRowData]);

  useEffect(() => {
    if (
      EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent ||
      EventDetailForm?.values?.drEventStatusId === eventStatus.logged
    ) {
      setEnableAttach(true);
    } else {
      setEnableAttach(false);
    }
    async function fetchInstMessage() {
      if (EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent) {
        setEnableReconcile(false);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26180
        );
        let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26181
        );
        let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26182
        );
        let { errorMsg: helperText4 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26183
        );
        let { errorMsg: helperText5 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26184
        );
        setInstMsgList([
          helperText1,
          helperText2,
          helperText3,
          helperText4,
          helperText5
        ]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.logged
      ) {
        setEnableReconcile(false);
        setCustomerSelected(EventDetailForm?.values?.customer.value);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26185
        );
        let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26181
        );
        let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26182
        );
        let { errorMsg: helperText4 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26183
        );
        let { errorMsg: helperText5 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26184
        );
        let { errorMsg: helperText6 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26186
        );
        let { errorMsg: helperText7 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26187
        );
        let { errorMsg: helperText8 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26188
        );
        setInstMsgList([
          helperText1,
          helperText2,
          helperText3,
          helperText4,
          helperText5,
          helperText6,
          helperText7,
          helperText8
        ]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.outBound
      ) {
        setEnableReconcile(true);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26189
        );
        let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26190
        );
        let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26186
        );
        let { errorMsg: helperText4 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26187
        );
        let { errorMsg: helperText5 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26191
        );
        let { errorMsg: helperText6 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26192
        );
        setInstMsgList([
          helperText1,
          helperText2,
          helperText3,
          helperText4,
          helperText5,
          helperText6
        ]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.inBound
      ) {
        setEnableReconcile(true);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26193
        );
        let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26194
        );
        let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26195
        );
        setInstMsgList([helperText1, helperText2, helperText3]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.reconciled
      ) {
        setEnableReconcile(true);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26196
        );
        let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26197
        );
        let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26198
        );
        setInstMsgList([helperText1, helperText2, helperText3]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.complete
      ) {
        setEnableReconcile(true);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26199
        );
        setInstMsgList([
          helperText1.replace("|", t("disasterRecovery.eventDetail.complete"))
        ]);
      } else if (
        EventDetailForm?.values?.drEventStatusId === eventStatus.cancel
      ) {
        setEnableReconcile(false);
        let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
          instMsgCodes.msgCode26199
        );
        setInstMsgList([
          helperText1.replace("|", t("disasterRecovery.eventDetail.canceled"))
        ]);
      }
    }
    fetchInstMessage();
    // eslint-disable-next-line
  }, [EventDetailForm?.values?.drEventStatusId]);

  useEffect(() => {
    let isEqualForm = _.isEqual(EventDetailForm?.values, formInitVal);
    let isEqualInstructions = _.isEqual(instructionsList, instructionsDBList);
    if (!isEqualForm || !isEqualInstructions) {
      if (
        EventDetailForm.values?.hotSiteEventID !==
          formInitVal?.values?.hotSiteEventID ||
        EventDetailForm.values?.primaryPhone !==
          formInitVal?.values?.primaryPhone ||
        EventDetailForm.values?.secondaryPhone !==
          formInitVal?.values?.secondaryPhone
      ) {
        if (
          EventDetailForm.values?.hotSiteEventID === VALUE_EMPTY_STRING ||
          EventDetailForm.values?.primaryPhone === VALUE_EMPTY_STRING ||
          EventDetailForm.values?.secondaryPhone === VALUE_EMPTY_STRING
        ) {
          setIsSaveEnabled(false);
        } else {
          setIsSaveEnabled(true);
        }
      }
      setIsSaveEnabled(true);
      dispatch(setHaveChanges(true));
    } else {
      setIsSaveEnabled(false);
      dispatch(setHaveChanges(false));
    }
    //eslint-disable-next-line
  }, [EventDetailForm.values, instructionsList]);

  useEffect(() => {
    let isValid = validateUserHasProgramId(ROLE_ACCESS_CODES.code_257);
    if (isValid) {
      isValid =
        isValid && auth?.user?.security_group_id !== ROLE_ACCESS_CODES.code_111;
    }
    setIsValid257Role(isValid);
    // eslint-disable-next-line
  }, [auth?.user?.security_group_id]);
  // handle confirmation popup "onYes" click event handler
  const handleConfirmationOnYes = async () => {
    setExitAfterSave((prevState) => false);
    if (showConfirmationModal) {
      dispatch(setShowConfirmationModal(false));
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      setExitAfterSave(true);
      cancelNavigation();
    }

    dispatch(cancelExitProcedure());
    let callBackForRequestGenerator = () => {
      if (awaitRequestGenerartorModal) {
        handleDrRequestGenerator(true);
        setAwaitRequestGenerartorModal(false);
      }
    };
    handleOnSave(
      EventDetailForm?.values?.drEventStatusId,
      EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent,
      false,
      callBackForRequestGenerator,
      true
    );

    window.scrollTo({ top: 0 });
  };

  // handle confirmation popup "onNo" event handler
  const handleConfirmationOnNo = () => {
    dispatch(resetRouteState());
    if (showConfirmationModal) {
      dispatch(cancelSaveProcedure());
    }
    if (awaitRequestGenerartorModal) {
      handleDrRequestGenerator(true);
      setAwaitRequestGenerartorModal(false);
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      confirmNavigation();
    }
  };

  // handle confirmation popup "onCancel" click event handler
  const handleConfirmationOnCancel = () => {
    if (showConfirmationModal) {
      dispatch(cancelConfirmation());
      dispatch(cancelExitProcedure());
    }

    // if navigation triggered by browser button
    if (showPrompt) {
      cancelNavigation();
    }
  };

  const handleErrorMessage = async (messageid, replaceValue = "") => {
    const message = await getMessageByIdFromMessage({
      message_id: messageid
    });

    if (message && message[0]?.descr) {
      setThrowError(true);
      setThrowErrorMessage(message[0]?.descr.replace("|", replaceValue));
      window.scrollTo({ top: 0 });
      setIsLoading(false);
    }
  };

  const handleWarningMessage = async (messageid, replaceValue = "") => {
    const message = await getMessageByIdFromMessage({
      message_id: messageid
    });

    if (message && message[0]?.descr) {
      setThrowWarning(true);
      setThrowWarningMessage(message[0]?.descr.replace("|", replaceValue));
      window.scrollTo({ top: 0 });
    }
  };

  const getMessageById = async (messageid, replaceValue, replaceValue2) => {
    const message = await getMessageByIdFromMessage({
      message_id: messageid
    });
    if (message && message[0]?.descr) {
      let msg = message[0]?.descr.replace("|", replaceValue);
      setConfirmModelContent(msg.replace("|", replaceValue2));
    }
  };

  // CF calls ----------

  const getCustomers = async () => {
    try {
      const districtId = getAuthenticatedUserBranch();
      let result = await getCustomersQueryData(districtId);

      // Filter customers
      result = customerFilter(result);

      if (result) {
        const data = result?.map(({ number, name, customer_id }) => ({
          label: `${number} - ${name.trim()}`,
          value: customer_id,
          number: number
        }));

        setCustomers((prev) => data);
      } else {
        setCustomers([]);
        setThrowErrorMessage(ERROR_TYPES.FAILED);
        setThrowError(true);
      }
    } catch (e) {
      setCustomers([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const fetchEventCoordinator = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        filter_by_op_center: VALUE_N,
        active_only: VALUE_Y
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getEmployeeData}`,
        1
      );

      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({ employee_id, employee_name }) => ({
              label: employee_name,
              value: employee_id
            })
          );

          setEventCoordinator((prev) => getSortedData(mapping, "label", "asc"));
        }
      } else if (data?.error) {
        setEventCoordinator((prev) => []);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setEventCoordinator((prev) => []);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const fetchEventTypes = async () => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch()
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getEventTypes}`,
        1
      );

      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({ dr_event_type, dr_event_type_code }) => ({
              label: dr_event_type,
              value: dr_event_type_code
            })
          );

          setEventTypes((prev) => getSortedData(mapping, "label", "asc"));
        }
      } else if (data?.error) {
        setEventTypes((prev) => []);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setEventTypes((prev) => []);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  const getCountryData = async () => {
    const countryResult = await getCountries();
    const countryData = getSortedData(countryResult, "name", "asc");
    const countryResponse = countryData.map((res) => ({
      ...res,
      value: `${res.country_id}`,
      label: `${res.name} - ${res.phone_country_code}`,
      id: res.country_id
    }));

    setAllCountries(countryResponse);
    const locationReqBody = JSON.stringify({
      main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
    });
    const locationsData = await getResponseData(
      locationReqBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`,
      1
    );
    if (locationsData?.data[0]) {
      const selectedLocationDetails = locationsData?.data[0].filter(
        (location) => location.district_id === getAuthenticatedUserBranch()
      );
      let localeData = await getLocaleById(
        selectedLocationDetails[0].iso_locale_code,
        FIREBASE_FIELD_NAMES.ISO_CODE
      );
      let country = findArrayElementByValue(
        countryResponse,
        localeData[0]?.country_id
      );
      setDefaultCountryCode(country);
      initFilterState.countryCode = country;
      setFormInitVal(initFilterState);
      EventDetailForm.setFieldValue("countryCode", country);
    }
  };

  const fetchInstWarningMsg = async () => {
    let { errorMsg: helperText1 } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26202
    );
    setInstDeleteWarning(helperText1);
    let { errorMsg: helperText2 } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26164
    );
    setInstReplaceWarning(helperText2);
    let { errorMsg: helperText3 } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode26155
    );
    setDeclaredByPersonnelError(helperText3);
  };

  const fetchPersonnelList = async () => {
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        customer_id: customerSelected,
        select_all_personnel_flag: VALUE_N
      });
      /* To do - Use this api endpoint once backend fixed the SQL DB issue.
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getPersonnelList}`,
        1
      );*/
      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getAuthorizedby}`,
        1
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(({ personnel_id, name }) => ({
            label: name,
            value: personnel_id
          }));

          setPersonnelList((prev) => getSortedData(mapping, "label", "asc"));
        }
      } else if (data?.error) {
        setPersonnelList((prev) => []);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setPersonnelList((prev) => []);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSingleEventDetails = async (eventId) => {
    setIsLoading(true);
    try {
      const reqBody = {
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_id: eventId
      };
      const data = await getResponseData(
        JSON.stringify(reqBody),
        CF_URLS.disasterRecovery.getSingleDRDetails,
        3
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[0]?.map(
            ({
              country_id,
              customer,
              customer_contact,
              customer_contact_personnel_id,
              customer_contact_primary_phone,
              customer_contact_secondary_phone,
              customer_id,
              declared_by_personnel,
              declared_by_personnel_id,
              dr_event_date,
              event_coordinator,
              dr_event_status_id,
              status,
              dr_event_id,
              event_coordinator_employee_id,
              event_type,
              hot_site_event_identifier,
              primary_phone_cellular_flag,
              secondary_phone_cellular_flag,
              verified_by_personnel,
              verified_by_personnel_id,
              expired_media_option,
              expired_media_return_date,
              retain_return_date_flag,
              timestamp,
              logged_by,
              logged_datetime_ltz,
              initiated_by,
              initiated_datetime_ltz,
              inbound_complete_by,
              inbound_complete_datetime_ltz,
              outbound_complete_by,
              outbound_complete_datetime_ltz,
              cancel_by,
              cancel_datetime_ltz,
              complete_by,
              complete_datetime_ltz
            }) => ({
              customer:
                findArrayElementByValue(customers, customer_id) === undefined
                  ? { value: customer_id, label: customer, number: customer }
                  : findArrayElementByValue(customers, customer_id),
              drEventDate: getDateFromUTCFormat(dr_event_date, {
                asObject: true
              }),
              eventCoordinator: {
                label: event_coordinator,
                value: event_coordinator_employee_id
              },
              drEventStatusId: dr_event_status_id,
              status: status,
              eventType: event_type,
              drEventId: dr_event_id,
              declaredByPersonnel: {
                label: declared_by_personnel,
                value: declared_by_personnel_id
              },
              verifiedByPersonnel: {
                label: verified_by_personnel,
                value: verified_by_personnel_id
              },
              hotSiteEventID: hot_site_event_identifier,
              customerContact: {
                label: customer_contact,
                value: customer_contact_personnel_id
              },
              countryCode: findArrayElementByValue(allCountries, country_id),
              primaryPhone: customer_contact_primary_phone,
              primaryCellular: primary_phone_cellular_flag,
              secondaryPhone: customer_contact_secondary_phone,
              secondaryCellular: secondary_phone_cellular_flag,
              expiredMediaOption: expired_media_option,
              expiredMediaReturnDate: expired_media_return_date
                ? dayjs(expired_media_return_date)
                : "",
              retainReturnDateFlag: retain_return_date_flag,
              timestamp: timestamp,
              loggedBy: logged_by,
              loggedDate: logged_datetime_ltz
                ? dayjs
                    .utc(logged_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen,
              initiatedBy: initiated_by ? initiated_by : hyphen,
              initiatedDate: initiated_datetime_ltz
                ? dayjs
                    .utc(initiated_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen,
              inboundCompleteBy: inbound_complete_by
                ? inbound_complete_by
                : hyphen,
              inboundCompleteDate: inbound_complete_datetime_ltz
                ? dayjs
                    .utc(inbound_complete_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen,
              outboundCompleteBy: outbound_complete_by
                ? outbound_complete_by
                : hyphen,
              outboundCompleteDate: outbound_complete_datetime_ltz
                ? dayjs
                    .utc(outbound_complete_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen,
              cancelBy: cancel_by ? cancel_by : hyphen,
              cancelDate: cancel_datetime_ltz
                ? dayjs
                    .utc(cancel_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen,
              completeBy: complete_by ? complete_by : hyphen,
              completeDate: complete_datetime_ltz
                ? dayjs
                    .utc(complete_datetime_ltz)
                    .locale(localeByBranch)
                    .format(dateFormatWithTime)
                : hyphen
            })
          );
          EventDetailForm.setValues(...mapping, false);
          setFormInitVal(...mapping);
          setCustomerSelected(mapping[0].customer.value);
          initPageTitle(
            mapping[0].drEventId,
            mapping[0].customer.number === undefined
              ? mapping[0].customer
              : mapping[0].customer.number
          );
        }

        if (data?.data[1][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[1][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          const mapping = data?.data[1]?.map(
            ({ date_time, dr_instruction_id, dr_instruction_name }) => ({
              dr_instruction_id: dr_instruction_id,
              dr_instruction_name: dr_instruction_name,
              date_time: dayjs
                .utc(date_time)
                .locale(localeByBranch)
                .format(dateFormat.dateTime),
              dr_instruction: "",
              status: drInstructionsExist
            })
          );

          setInstructionsDBList(mapping);
          setInstructionsList(mapping);
        }
        if (data?.data[2][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[2][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          setRequestInfo((prev) =>
            getSortedData(data?.data[2], "request_id", "asc")
          );
        }
      } else if (data?.error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchInstructionFileFromDB = async () => {
    handleWarningMessage(drInstructionsDownloadWarningMessageCode);
    setIsLoading(true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        dr_instruction_id: clickedRowData.dr_instruction_id
      });

      const data = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery.getDrInstructionDetails}`,
        1
      );
      if (data?.data) {
        if (data?.data[0][0]?.error) {
          const errorMsg = await getMessageFromCode(data?.data[0][0]?.error);
          setThrowErrorMessage(
            errorMsg[0]?.descr || findErrorMessage(ERROR_TYPES.ISSUE)
          );
          setThrowError(true);
        } else {
          if (!data?.data[0][0]?.dr_instruction) {
            const fileName = data?.data[0][0]?.dr_instruction_name;
            const folderPath = getDrInstructionMainFolderPath(
              EventDetailForm?.values?.customer?.value,
              EventDetailForm?.values?.drEventId
            );
            const fileUrl = getGsFilePath(`${folderPath}/${fileName}`);
            await downloadFileFromFB(fileUrl, fileName);
          } else {
            const mapping = data?.data[0]?.map(
              ({ dr_instruction, dr_instruction_name }) => ({
                dr_instruction_name: dr_instruction_name,
                dr_instruction: dr_instruction,
                dr_instruction_id: clickedRowData.dr_instruction_id,
                status: drInstructionsExist
              })
            );
            if (mapping.length > 0) {
              const link = document.createElement("a");
              const file = new Blob([mapping[0].dr_instruction], {
                type: "text/plain"
              });
              link.href = URL.createObjectURL(file);
              link.download = mapping[0].dr_instruction_name;
              link.dispatchEvent(new MouseEvent("click"));
            }
          }
        }
      } else if (data?.error) {
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setThrowError(true);
      }
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getLabelForMessage = (msg) => msg.replace("*", "");

  const checkForPhoneFormat = async (phone, isPrimary, countryId) => {
    try {
      let reqBody = {
        main_district_id: getAuthenticatedUserBranch(),
        primary_phone: VALUE_EMPTY_STRING,
        secondary_phone: VALUE_EMPTY_STRING,
        primary_phone_cellular_flag: EventDetailForm?.values?.primaryCellular,
        secondary_phone_cellular_flag:
          EventDetailForm?.values?.secondaryCellular,
        personnel_country_id: countryId
      };

      if (isPrimary) {
        reqBody.primary_phone = phone;
      } else {
        reqBody.secondary_phone = phone;
      }
      const response = await getResponseData(
        reqBody,
        CF_URLS.disasterRecovery?.drEventPhoneFormat,
        1
      );
      let data = response?.data?.[0]?.[0];
      if (data?.bad_format_field === zero) {
        return false;
      } else {
        return data?.bad_format_message;
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
      return false;
    }
  };

  const validatePhone = async (phoneNumber, isPrimary) => {
    setIsLoading(true);
    const invalidMsg = await checkForPhoneFormat(
      phoneNumber,
      isPrimary,
      EventDetailForm?.values?.countryCode?.value
    );

    if (invalidMsg) {
      const errorMsg = await getMessageFromCode(errorMsgs.errorCode63688);
      const replacedText = errorMsg[0]?.descr
        .replace(
          "|",
          isPrimary
            ? t("disasterRecovery.eventDetail.primaryPhone")
            : t("disasterRecovery.eventDetail.secondaryPhoneLabel")
        )
        .replace("|", invalidMsg);

      setThrowErrorMessage(replacedText);
      setThrowError(true);
      window.scrollTo({ top: 0 });
      setIsLoading(false);
      return true;
    }
    return false;
  };

  // media request detail save
  const handleOnSave = async (
    eventStatusId,
    isSave = false,
    requestComplete = false,
    callBackForRequestGenerator,
    navigateAfterSave = false
  ) => {
    if (Object.keys(EventDetailForm.errors).length > 0) {
      window.scrollTo({ top: 0 });
      return;
    }
    handleCloseConfirmModel();
    setShowSuccess(false);
    setThrowError(false);
    if (eventStatusId === eventStatus.cancel && requestInfo.length) {
      const data = requestInfo
        ?.filter(
          (item) =>
            item.request_status !== CANCELLED &&
            item.request_status !== COMPLETE
        )
        .map((item) => item.request_id);
      if (data.length) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode26158);
        let replacedText = errorMsg[0]?.descr.replace("|", data.join(","));
        setThrowErrorMessage(replacedText);
        setThrowError(true);
        window.scrollTo({ top: 0 });
        return;
      }
    }

    if (
      eventStatusId === eventStatus.inBound &&
      requestInfo.length &&
      requestComplete
    ) {
      const data = requestInfo
        ?.filter(
          (item) =>
            item.request_status !== CANCELLED &&
            item.request_status !== COMPLETE
        )
        .map((item) => item.request_id);

      if (data.length) {
        const errorMsg = await getMessageFromCode(errorMsgs.errorCode26206);
        let replacedText = errorMsg[0]?.descr.replace("|", data.join(","));
        setThrowErrorMessage(replacedText);
        setThrowError(true);
        window.scrollTo({ top: 0 });
        return;
      }
    }

    if (
      (EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent ||
        EventDetailForm?.values?.drEventStatusId === eventStatus.logged) &&
      isSave
    ) {
      if (EventDetailForm?.values?.customer === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(t("disasterRecovery.eventDetail.customerLabel"))
        );
      } else if (EventDetailForm?.values?.drEventDate === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          t("disasterRecovery.eventDetail.drEventDateLabel")
        );
      } else if (EventDetailForm?.values?.eventType === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          t("disasterRecovery.eventDetail.eventTypeLabel")
        );
      } else {
        if (EventDetailForm?.values?.primaryPhone) {
          const isPrimaryInvalid = await validatePhone(
            EventDetailForm?.values?.primaryPhone,
            true
          );
          if (isPrimaryInvalid) return;
        }
        if (EventDetailForm?.values?.secondaryPhone) {
          const isSecondaryInvalid = await validatePhone(
            EventDetailForm?.values?.secondaryPhone,
            false
          );
          if (isSecondaryInvalid) return;
        }
        if (EventDetailForm?.values?.drEventStatusId === eventStatus.newEvent) {
          saveRecords(eventStatusId, navigateAfterSave);
        } else {
          updateRecords(eventStatusId, callBackForRequestGenerator);
        }
      }
    } else if (
      EventDetailForm?.values?.drEventStatusId === eventStatus.logged ||
      EventDetailForm?.values?.drEventStatusId === eventStatus.outBound ||
      EventDetailForm?.values?.drEventStatusId === eventStatus.inBound ||
      EventDetailForm?.values?.drEventStatusId === eventStatus.reconciled
    ) {
      if (EventDetailForm?.values?.customer === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(t("disasterRecovery.eventDetail.customerLabel"))
        );
      } else if (EventDetailForm?.values?.drEventDate === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(t("disasterRecovery.eventDetail.drEventDateLabel"))
        );
      } else if (EventDetailForm?.values?.eventType === "") {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          t("disasterRecovery.eventDetail.eventTypeLabel")
        );
      } else if (
        !EventDetailForm?.values?.eventCoordinator?.value ||
        EventDetailForm?.values?.eventCoordinator?.value === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(
            t("disasterRecovery.eventDetail.eventCoordinatorLabel")
          )
        );
      } else if (
        !EventDetailForm?.values?.declaredByPersonnel?.value ||
        EventDetailForm?.values?.declaredByPersonnel?.value === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(
            t("disasterRecovery.eventDetail.declaredByPersonnelLabel")
          )
        );
      } else if (
        !EventDetailForm?.values?.verifiedByPersonnel?.value ||
        EventDetailForm?.values?.verifiedByPersonnel?.value === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(
            t("disasterRecovery.eventDetail.verifiedByPersonnelLabel")
          )
        );
      } else if (
        EventDetailForm?.values?.customerContact === "" ||
        EventDetailForm?.values?.customerContact === null ||
        EventDetailForm?.values?.customerContact.value === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          getLabelForMessage(
            t("disasterRecovery.eventDetail.customerContactLabel")
          )
        );
      } else if (
        !EventDetailForm?.values?.countryCode.value ||
        EventDetailForm?.values?.countryCode.value === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          t("disasterRecovery.eventDetail.countryCodeLabel")
        );
      } else if (
        !EventDetailForm?.values?.primaryPhone ||
        EventDetailForm?.values?.primaryPhone === ""
      ) {
        handleErrorMessage(
          errorMsgs.errorCode25052,
          t("disasterRecovery.eventDetail.primaryPhoneLabel")
        );
      } else {
        if (EventDetailForm?.values?.primaryPhone) {
          const isPrimaryInvalid = await validatePhone(
            EventDetailForm?.values?.primaryPhone,
            true
          );
          if (isPrimaryInvalid) return;
        }
        if (EventDetailForm?.values?.secondaryPhone) {
          const isSecondaryInvalid = await validatePhone(
            EventDetailForm?.values?.secondaryPhone,
            false
          );
          if (isSecondaryInvalid) return;
        }
        const instList = instructionsList?.filter(
          (instruction) => instruction.status !== "delete"
        );
        if (instList.length === 0) {
          handleErrorMessage(
            errorMsgs.errorCode25052,
            t("disasterRecovery.eventDetail.instructionFilesLabel")
          );
        } else {
          updateRecords(
            eventStatusId,
            callBackForRequestGenerator,
            navigateAfterSave
          );
        }
      }
    } else {
      updateRecords(
        eventStatusId,
        callBackForRequestGenerator,
        navigateAfterSave
      );
    }
  };
  const saveRecords = async (eventStatusId, navigateAfterSave) => {
    try {
      setIsLoading((prev) => true);
      window.scrollTo({ top: 0 });
      const customerId = EventDetailForm?.values?.customer?.value;
      const reqBody = {
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_status_id: eventStatusId
          ? eventStatusId
          : EventDetailForm?.values?.drEventStatusId,
        customer_id: Number(customerId),
        dr_event_date: EventDetailForm?.values?.drEventDate.format(
          dateFormat.universalFormat
        ),
        dr_event_type: EventDetailForm?.values?.eventType,
        dr_event_coordinator_id:
          EventDetailForm?.values?.eventCoordinator?.value,
        declared_by_personnel_id:
          EventDetailForm?.values?.declaredByPersonnel.value,
        verified_by_personnel_id:
          EventDetailForm?.values?.verifiedByPersonnel.value,
        hot_site_event_identifier: EventDetailForm?.values?.hotSiteEventID,
        customer_contact_personnel_id:
          EventDetailForm?.values?.customerContact?.value,
        country_id: EventDetailForm?.values?.countryCode?.value,
        customer_contact_primary_phone: EventDetailForm?.values?.primaryPhone,
        customer_contact_secondary_phone:
          EventDetailForm?.values?.secondaryPhone,
        retain_return_date_flag: EventDetailForm?.values?.retainReturnDateFlag,
        expired_media_option: EventDetailForm?.values?.expiredMediaOption,
        expired_media_return_date:
          EventDetailForm?.values?.expiredMediaOption === "D"
            ? EventDetailForm?.values?.expiredMediaReturnDate
            : "",
        primary_phone_cellular_flag: EventDetailForm?.values?.primaryCellular,
        secondary_phone_cellular_flag:
          EventDetailForm?.values?.secondaryCellular,
        dr_instructions_xml: getInstructionXml(),
        logged_in_employee_id: auth?.user?.id
      };
      const saveResponse = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery?.insertDrEvent}`,
        3
      );

      const saveError = saveResponse?.error; // get error code
      // if there is an error showing the error and exit from the process
      if (saveError !== null) {
        handleErrorMessage(saveError);
        setIsLoading((prev) => false);
        return;
      }

      let newEventID = saveResponse?.data[0][0].dr_event_id;

      if (exitAfterSave || navigateAfterSave) {
        // exit page after saving
        confirmNavigation();
        setIsLoading((prev) => false);
        sessionStorage.setItem("dr_event_id", newEventID);
        return;
      }

      setShowSuccess(true);
      setShowSuccessMessage(
        t("disasterRecovery.eventDetail.successMessage") +
          " " +
          dayjs(Date.now()).format(dateFormat.dateTime)
      );
      dispatch(setHaveChanges(false));
      setIsLoading(false);
      initFilterState.drEventId = newEventID;
      EventDetailForm.setFieldValue("drEventId", newEventID);
      fetchSingleEventDetails(saveResponse?.data[0][0].dr_event_id);
      await uploadDrInstructionFilesToGcs(
        instructionsList,
        customerId,
        newEventID
      );
      await deleteMultipleFilesFromFB(instructionsList, customerId, newEventID);
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const updateRecords = async (
    eventStatusId,
    callBackForRequestGenerator,
    navigateAfterSave
  ) => {
    try {
      setIsLoading((prev) => true);
      const customerId = EventDetailForm?.values?.customer?.value;
      const reqBody = {
        main_district_id: getAuthenticatedUserBranch(),
        dr_event_status_id:
          Number(eventStatusId) >=
          Number(EventDetailForm?.values?.drEventStatusId)
            ? eventStatusId
            : EventDetailForm?.values?.drEventStatusId,
        dr_event_id: Number(EventDetailForm?.values?.drEventId),
        customer_id: Number(customerId),
        dr_event_date: EventDetailForm?.values?.drEventDate.format(
          dateFormat.universalFormat
        ),
        dr_event_type: EventDetailForm?.values?.eventType,
        dr_event_coordinator_id:
          EventDetailForm?.values?.eventCoordinator === null
            ? numberConstants.zero
            : EventDetailForm?.values?.eventCoordinator?.value,
        declared_by_personnel_id:
          EventDetailForm?.values?.declaredByPersonnel?.value ===
          VALUE_EMPTY_STRING
            ? numberConstants.zero
            : EventDetailForm?.values?.declaredByPersonnel?.value,
        verified_by_personnel_id:
          EventDetailForm?.values?.verifiedByPersonnel?.value ===
          VALUE_EMPTY_STRING
            ? numberConstants.zero
            : EventDetailForm?.values?.verifiedByPersonnel?.value,
        hot_site_event_identifier: EventDetailForm?.values?.hotSiteEventID,
        customer_contact_personnel_id:
          EventDetailForm?.values?.customerContact === null
            ? numberConstants.zero
            : EventDetailForm?.values?.customerContact?.value,
        country_id: EventDetailForm?.values?.countryCode?.value,
        customer_contact_primary_phone: EventDetailForm?.values?.primaryPhone,
        customer_contact_secondary_phone:
          EventDetailForm?.values?.secondaryPhone,
        retain_return_date_flag: EventDetailForm?.values?.retainReturnDateFlag,
        expired_media_option: EventDetailForm?.values?.expiredMediaOption,
        expired_media_return_date:
          EventDetailForm?.values?.expiredMediaOption === "D"
            ? EventDetailForm?.values?.expiredMediaReturnDate
            : "",
        primary_phone_cellular_flag: EventDetailForm?.values?.primaryCellular,
        secondary_phone_cellular_flag:
          EventDetailForm?.values?.secondaryCellular,
        dr_instructions_xml: getInstructionXml(),
        dr_event_timestamp: EventDetailForm?.values?.timestamp,
        logged_in_employee_id: auth?.user?.id
      };
      const saveResponse = await getResponseData(
        reqBody,
        `${CF_URLS.disasterRecovery?.updateDrEvent}`,
        3
      );
      const saveError = saveResponse?.error; // get error code
      // if there is an error showing the error and exit from the process
      if (saveError !== null) {
        handleErrorMessage(saveError[0]?.error);
        setIsLoading((prev) => false);
        return;
      } else if (saveResponse.data[2][0]?.error) {
        if (saveResponse.data[2][0]?.error === errorMsgs.errorCode26158) {
          let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
            errorMsgs.errorCode26158
          );
          setConfirmModelContent(
            helperText.replace("|", saveResponse.data[1][0]?.error_detail)
          );
        } else {
          let { errorMsg: helperText } = await getTheFirestoreErrorMessage(
            saveResponse.data[2][0]?.error
          );
          setConfirmModelContent(
            helperText.replace("|", saveResponse.data[1][0]?.error_detail)
          );
        }
        setModelTitle("");
        setModelButtons(
          <>
            <Button variant="outlined" onClick={handleCloseConfirmModel}>
              {t("common.ok")}
            </Button>
          </>
        );
        handleOpenConfirmModel();
        setIsLoading(false);
        return;
      }

      if (exitAfterSave || navigateAfterSave) {
        // exit page after saving
        confirmNavigation();
        setIsLoading((prev) => false);
        return;
      }

      await uploadDrInstructionFilesToGcs(
        instructionsList,
        customerId,
        EventDetailForm?.values?.drEventId
      );
      await deleteMultipleFilesFromFB(
        instructionsList,
        customerId,
        EventDetailForm?.values?.drEventId
      );

      await fetchSingleEventDetails(EventDetailForm?.values?.drEventId);
      setShowSuccess(true);
      setShowSuccessMessage(
        t("disasterRecovery.eventDetail.successMessage") +
          " " +
          dayjs(Date.now()).format(dateFormat.dateTime)
      );
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (typeof callBackForRequestGenerator === "function")
        callBackForRequestGenerator();
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setIsLoading(false);
    }
  };

  const getInstructionXml = () => {
    let existingInstructionList = [];
    let instructions = "";
    if (instructionsList.length > 0) {
      existingInstructionList = instructionsList.filter(
        (instruction) => instruction.status !== drInstructionsExist
      );
    }
    if (existingInstructionList.length > 0) {
      //eslint-disable-next-line
      existingInstructionList.map((instruction) => {
        const dr_inst_id = instruction.dr_instruction_id;
        const dr_action =
          instruction.status === drInstructionsNew
            ? "I"
            : instruction.status === drInstructionsDelete
              ? "D"
              : "U";
        instructions =
          instructions +
          '<dr_instruction dr_instruction_id="' +
          dr_inst_id +
          '" dr_table_action="' +
          dr_action +
          '" dr_instruction_name="' +
          instruction.dr_instruction_name +
          '" dr_instruction="' +
          '"></dr_instruction>';
      });
      return "<ROOT>" + instructions + "</ROOT>";
    }
    return "";
  };

  const pageReset = () => {
    setCustomerSelected();
    initFilterState.countryCode = defaultCountryCode;
    setFormInitVal(initFilterState);
    EventDetailForm.resetForm();
    EventDetailForm.setFieldValue("countryCode", defaultCountryCode);
    setInstructionsList([]);
    setRequestInfo([]);
    setIsSaveEnabled(false);
    setShowSuccess(false);
    setShowSuccessMessage(VALUE_EMPTY_STRING);
    setThrowErrorMessage(VALUE_EMPTY_STRING);
    setThrowError(false);
    setThrowWarning(false);
    window.scrollTo({ top: 0 });
  };

  // on click DR Procedure button
  const onDRProcedureClick = async () => {
    setIsLoading(true);
    const url = await getGlobalAttributeValue(
      getAuthenticatedUserBranch(),
      SYSTEM_ID_SecureBase,
      drprocedureAttributeId
    );
    setIsLoading(false);
    openMinimizedWindow(url);
  };

  // props ----------
  const sectionEventDetailProps = {
    customers,
    customerSelected,
    customerElKey,
    eventCoordinator,
    handleCustomerChange,
    handleEventCoordinator,
    handleDrEventDate,
    handleEventTypeChange,
    handleHotSiteEventID,
    handleCustomerContact,
    handleCountryCode,
    handlePrimaryPhoneField,
    handlePrimaryCellular,
    handleSecondaryPhone,
    handleSecondaryCellular,
    handleErrorMessage,
    eventTypes,
    personnelList,
    allCountries,
    EventDetailForm,
    localeByBranch,
    DEFAULT_LOCALE,
    setAuthModalSuccess,
    authModalSuccess,
    setAuthRequestModalVisible,
    authRequestModalVisible,
    setAuthRequestForDeclared,
    authRequestForDeclared,
    setAuthRequestForVerified,
    authRequestForVerified,
    setPersonnelSelected,
    personnelSelected,
    isValid257Role,
    currentDate,
    timeZoneOffsetChanged,
    showDeclaredByPersonnalError,
    showAuthSuccess
  };

  const instructionListProps = {
    instMsgList,
    instructionsList,
    setInstructionsList,
    EventDetailForm,
    setThrowError,
    setThrowErrorMessage,
    setIsLoading,
    setSelectedRow,
    selectedRow,
    setClickedRowData,
    clickedRowData,
    fetchInstructionFileFromDB,
    enableAttach,
    enableDelete,
    enableView,
    enableInst,
    pageIsViewOnly,
    CustomBodyRenderComponent,
    localeByBranch,
    customerSelected,
    instDeleteWarning,
    instReplaceWarning
  };

  const requestInfoProps = {
    requestInfo,
    EventDetailForm,
    handleOnSave,
    handleDrRequestGenerator,
    handleViewDrMedia,
    pageIsViewOnly
  };

  const distributionProps = {
    EventDetailForm,
    localeByBranch,
    DEFAULT_LOCALE,
    handleDrEventDate,
    isValid257Role,
    currentDate,
    timeZoneOffsetChanged
  };

  return (
    <>
      {isLoading && <CircularLoaderFullPage loading={isLoading} />}
      {authSuccessMessage && (
        <SuccessPopup
          message={authSuccessMessage}
          close={setAuthSuccessMessage}
        />
      )}
      <Stack
        direction="column"
        gap={spacing.gap}
        marginTop={spacing.margingTop20}
      >
        {showSuccess && (
          <SuccessPopup message={ShowSuccessMessage} close={setShowSuccess} />
        )}

        {throwError && (
          <Collapse in={throwError}>
            <Alert
              severity="error"
              icon={<img src={ErrorIcon} alt="error" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowError(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwErrorMessage}
            </Alert>
          </Collapse>
        )}

        {throwWarning && (
          <Collapse in={throwWarning}>
            <Alert
              severity="warning"
              icon={<img src={WarningIcon} alt="warning" />}
              action={
                <IconButton
                  aria-label={t("common.close")}
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setThrowWarning(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {throwWarningMessage}
            </Alert>
          </Collapse>
        )}

        {/* ---------- Event Detail Section ---------- */}
        <EventDetailsTable {...sectionEventDetailProps} />

        {/* ---------- Event Detail Instructions ---------- */}
        <EventDetailInstructions {...instructionListProps} />

        {/* --------- Request Information ---------- */}
        <RequestInfo {...requestInfoProps} />

        {/* --------- Distribution Information ---------- */}
        <Distribution {...distributionProps} />

        <Divider />

        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          flexWrap="wrap"
          gap={spacing.verticalMargin20}
          mt={spacing.gap10}
        >
          <Button
            variant="outlined"
            id="go-back"
            style={
              pageIsViewOnly
                ? disasterRecoveryStyles.tableStyles.backButton
                : disasterRecoveryStyles.tableStyles.hiddenButton
            }
            onClick={onGoBack}
          >
            <ChevronLeft /> {t("common.back")}
          </Button>
          <Button
            variant="outlined"
            disabled={
              !(
                EventDetailForm?.values?.drEventStatusId ===
                  eventStatus.reconciled &&
                isValid257Role &&
                validateUserHasProgramId(ROLE_ACCESS_CODES.code_263)
              )
            }
            id="complete-event"
            onClick={() => handleOnSave(eventStatus.complete)}
            style={
              isValid257Role &&
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_263)
                ? disasterRecoveryStyles.tableStyles.buttonsSpace
                : disasterRecoveryStyles.tableStyles.hiddenButton
            }
          >
            {t("disasterRecovery.eventDetail.completeEvent")}
          </Button>

          <Button
            variant="outlined"
            disabled={
              !(
                isValid257Role &&
                validateUserHasProgramId(ROLE_ACCESS_CODES.code_267) &&
                enableReconcile
              )
            }
            id="reconcile-dr-media"
            onClick={() => handleViewDrMedia()}
            style={
              isValid257Role &&
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_267)
                ? disasterRecoveryStyles.tableStyles.buttonsSpace
                : disasterRecoveryStyles.tableStyles.hiddenButton
            }
          >
            {t("disasterRecovery.eventDetail.reconcileDrMedia")}
          </Button>

          <Button
            variant="outlined"
            disabled={
              !(
                isValid257Role &&
                validateUserHasProgramId(ROLE_ACCESS_CODES.code_262) &&
                EventDetailForm?.values?.drEventStatusId ===
                  eventStatus.outBound
              )
            }
            id="out-bound-event"
            onClick={handelOutBound}
            style={
              isValid257Role &&
              validateUserHasProgramId(ROLE_ACCESS_CODES.code_262)
                ? disasterRecoveryStyles.tableStyles.buttonsSpace
                : disasterRecoveryStyles.tableStyles.hiddenButton
            }
          >
            {t("disasterRecovery.eventDetail.outboundComplete")}
          </Button>

          <Button
            variant="outlined"
            id="dr-procedures"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            onClick={() => onDRProcedureClick()}
          >
            {t("disasterRecovery.eventSearch.drProcedure")}
          </Button>

          <Button
            variant="outlined"
            style={
              isValid257Role || isValid264Role
                ? disasterRecoveryStyles.tableStyles.buttonsSpace
                : disasterRecoveryStyles.tableStyles.hiddenButton
            }
            disabled={
              !(
                isValid264Role &&
                (EventDetailForm?.values?.drEventStatusId ===
                  eventStatus.logged ||
                  EventDetailForm?.values?.drEventStatusId ===
                    eventStatus.outBound)
              )
            }
            id="cancel-event"
            onClick={() => handleOnSave(eventStatus.cancel)}
          >
            {t("disasterRecovery.eventDetail.cancelEvent")}
          </Button>

          <Button
            variant="outlined"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            id="dr-event-history"
            disabled={
              !(
                validateUserHasProgramId(ROLE_ACCESS_CODES.code_257) &&
                EventDetailForm?.values?.drEventStatusId !==
                  eventStatus.newEvent
              )
            }
            onClick={handleDrEventHistory}
          >
            {t("disasterRecovery.eventDetail.drEventHistory")}
          </Button>

          <Button
            variant="outlined"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            id="new-event"
            disabled={!isValid257Role}
            onClick={onNavigate}
          >
            {t("disasterRecovery.eventDetail.newEvent")}
          </Button>

          <Button
            variant="contained"
            style={disasterRecoveryStyles.tableStyles.buttonsSpace}
            id="save-list"
            disabled={!(isSaveEnabled && isValid257Role)}
            onClick={() =>
              handleOnSave(EventDetailForm?.values?.drEventStatusId, true)
            }
          >
            {t("disasterRecovery.eventDetail.save")}
          </Button>
        </Grid>
      </Stack>
      {/* In Page => Save Changes Confirmation Modal */}
      {(showConfirmationModal || showPrompt) && (
        <ConfirmPopup
          modalPopupOpen={showConfirmationModal || showPrompt}
          handleYes={() => handleConfirmationOnYes()}
          handleNo={() => handleConfirmationOnNo()}
          showNo={true}
          handleCancel={() => handleConfirmationOnCancel()}
          showCancel={true}
        />
      )}

      <ModalPopup
        title={modelTitle}
        modalPopupOpen={openConfirmModel}
        handleClick={handleCloseConfirmModel}
        alertMessage={confirmModelContent}
        modalButton={<>{modelButtons}</>}
      />

      {showRequestGeneratorModal && (
        <DRRequestGeneratorModal
          open={showRequestGeneratorModal}
          onSubmit={(type) =>
            handleRequestGeneratorOnSubmit(
              type,
              EventDetailForm?.values?.drEventId,
              EventDetailForm?.values?.customer
            )
          }
          onClose={() => setShowRequestGeneratorModal((prevState) => false)}
        />
      )}
    </>
  );
};

export default EventDetailLayout;
