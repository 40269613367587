import dayjs from "dayjs";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { getTimeZoneOffset } from "store/slices";
const useBranchCurrentDate = () => {
  const { branchTimeZoneOffset } = useSelector(getTimeZoneOffset);
  const currentDate = useMemo(() => {
    let date = dayjs.utc();

    if (branchTimeZoneOffset !== null) {
      date = date.utcOffset(parseFloat(branchTimeZoneOffset));
    }
    return date;
  }, [branchTimeZoneOffset]); // minimum date

  return currentDate;
};

export default useBranchCurrentDate;
