import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField
} from "@mui/material";
import CommonModal from "components/shared/common-modal/CommonModal";
import { t } from "i18next";
import { N_CHECKER } from "utils/constants";
import { IssuranceStyles } from "./IssuanceStyles";

const IssuanceEditModal = ({
  isModalOpen,
  handleModalClose,
  selectedRow,
  onDescriptionChange,
  onSaveClickOnModal
}) => {
  return (
    <>
      <CommonModal
        open={isModalOpen}
        title={t("continerIssurance.editContainerIssuance")}
        body={
          <>
            <Stack
              direction={IssuranceStyles?.direction?.column}
              gap={IssuranceStyles?.gap?.gap}
            >
              <Stack
                direction={IssuranceStyles?.direction?.row}
                gap={IssuranceStyles?.gap?.gap}
              >
                <Grid sx={IssuranceStyles?.fieldSize}>
                  <TextField
                    id="customer"
                    name="customer"
                    label={t("continerIssurance.customer")}
                    size="medium"
                    variant="standard"
                    sx={IssuranceStyles?.textBoarderHide}
                    value={selectedRow?.customerLabel_id || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid sx={IssuranceStyles?.fieldSize}>
                  <TextField
                    id="logicalVault"
                    name="logicalVault"
                    label={t("continerIssurance.logicalVaultS")}
                    size="medium"
                    variant="standard"
                    sx={IssuranceStyles?.textBoarderHide}
                    value={selectedRow?.logical_vault_code || ""}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>
              </Stack>

              <Stack
                direction={IssuranceStyles?.direction?.row}
                gap={IssuranceStyles?.gap?.gap}
              >
                <Grid sx={IssuranceStyles?.fieldSize}>
                  <TextField
                    id="mediaType"
                    name="mediaType"
                    label={t("continerIssurance.mediaType")}
                    size="medium"
                    variant="standard"
                    sx={IssuranceStyles?.textBoarderHide}
                    value={
                      `${selectedRow?.media_type_type} - ${selectedRow?.media_type_descr}` ||
                      ""
                    }
                    InputProps={{
                      readOnly: true,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true,
                      focused: false
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid sx={IssuranceStyles?.fieldSize}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          selectedRow?.indefinite_retention_flag === N_CHECKER
                        }
                        onChange={onDescriptionChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label={t("continerIssurance.issueS")}
                  />
                </Grid>
              </Stack>
            </Stack>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button onClick={onSaveClickOnModal} variant="contained">
              {t("common.update")}
            </Button>
          </>
        }
      />
    </>
  );
};

export default IssuanceEditModal;
