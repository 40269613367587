import styled from "@emotion/styled";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SvgIcon, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { ReactComponent as CustomerIcon } from "assets/images/CustomerIcon.svg";
import { ReactComponent as NotepadIcon } from "assets/images/NotepadIcon.svg";
import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { DEFAULT_LOCALE, VALUE_Y } from "utils/constants";
import {
  Distribution,
  customerPrefix,
  distributeOptionAll,
  distributeOptionReadyToScan,
  distribution,
  pendingCapcity,
  typeCustomer,
  typeLogicalVault
} from "utils/constants/open-media-processing/WorkManagement";
import { dateTimeFormatByLocale } from "utils/helpers";
import { WorkManagementTreeStyles } from "../WorkManagementStyles";

const StyledCheckbox = styled(Checkbox)({
  "&.Mui-checked": WorkManagementTreeStyles.checkboxColor
});

const StyledTreeItem = styled(TreeItem)({
  "& .MuiTreeItem-iconContainer": WorkManagementTreeStyles.iconStyles
});

const DistributionTree = ({
  setSelectLoagicalVualt,
  customerList,
  logicalVaultList,
  distributionList,
  loadTablePicking,
  checked,
  setChecked,
  expanded,
  setExpanded,
  localeByBranch,
  distributeFilter,
  transportData
}) => {
  // Function to recursively get all child node IDs
  const getAllChildNodeIds = (node) => {
    let childNodeIds = [];
    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        childNodeIds.push(child.nodeId);
        childNodeIds = [...childNodeIds, ...getAllChildNodeIds(child)];
      });
    }
    return childNodeIds;
  };

  const handleToggle = (nodeId, node) => {
    if (node?.children) {
      if (node?.children?.length > 1) {
        setSelectLoagicalVualt("");
      } else {
        setSelectLoagicalVualt(node?.children[0]?.logical_vault_id);
      }
    } else {
      setSelectLoagicalVualt(node?.logicalVault?.logical_vault_id);
    }

    const currentIndex = checked.indexOf(nodeId);
    let newChecked = [...checked];

    let underNodes = getAllChildNodeIds(node);
    if (currentIndex === -1) {
      newChecked.push(nodeId);
      newChecked = [...newChecked, ...underNodes];

      if (node.type === typeLogicalVault) {
        let childrenNodes = getAllChildNodeIds(
          treeData[0]?.children.find(
            (cu) => cu.id === node.customer.customer_id
          )
        ).filter((id) => id.startsWith(node.customer.customer_id));
        let checkedChildren = childrenNodes.filter((c) =>
          newChecked.includes(c)
        );
        if (childrenNodes.length === checkedChildren.length) {
          let parentNodeId = getNode(node.customer);
          newChecked.push(parentNodeId);
        }
      }
      if (node.type === distribution) {
        let parentNode = treeData[0]?.children
          .find((cu) => cu.id === node.customerId)
          .children.find((lv) => lv.id === node.logicalVault.logical_vault_id);

        let childrenNodes = getAllChildNodeIds(parentNode);
        let checkedChildren = childrenNodes.filter((c) =>
          newChecked.includes(c)
        );
        if (childrenNodes.length === checkedChildren.length) {
          let parentNodeId = getSecondLevelNodeId(node.logicalVault);
          newChecked.push(parentNodeId);
        }
      }
    } else {
      if (node.type === typeLogicalVault) {
        underNodes.push(getNode(node.customer));
      }

      if (node.type === distribution) {
        let parentNodeId = getSecondLevelNodeId(node.logicalVault);
        underNodes.push(parentNodeId);
      }
      newChecked = newChecked.filter(
        (item) => item !== nodeId && !underNodes.includes(item)
      );
    }
    setChecked(newChecked);
    loadTablePicking(
      newChecked.filter((id) => !isNaN(parseInt(id))),
      distributionList
    );
  };

  // Tree expand event Handler
  const handleExpand = useCallback(
    (event, nodeId, isExpanded) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, nodeId] : prev.filter((id) => id !== nodeId)
      );
    },
    [setExpanded]
  );
  useEffect(() => {
    setExpanded([Distribution]);
    //eslint-disable-next-line
  }, [customerList]);
  const handleClick = (e, id) => {
    setExpanded((prev) => [...prev, id]);
  };

  // Get intermediate checkbox state
  const getCheckboxIntermediate = (node) => {
    let type = node.type;
    if (!type || type === distribution) return false;
    let childNodes = getAllChildNodeIds(node);
    let checkedChildrenCount = childNodes.filter((c) =>
      checked.includes(c)
    ).length;

    if (!checkedChildrenCount) return false;
    return checkedChildrenCount !== childNodes.length;
  };

  // get lable typography
  const getLabelTypography = (label, isRed) => (
    <Typography sx={isRed ? WorkManagementTreeStyles.textColorRed : {}}>
      {label}
    </Typography>
  );

  // Get node ID for first level
  let getNode = (customer) => {
    return `${customerPrefix}${customer.customer_number}${customer.customer_id}`;
  };

  // Get label for first level
  let getLable = (customer) => {
    const isRed = distributionList
      ?.filter((obj) => obj.customer_number === customer.customer_number)
      ?.some((obj) => obj?.task_status === pendingCapcity);
    const allPending =
      transportData?.filter(
        (obj) => obj.customer_number === customer.customer_number
      )?.length > 0;

    let labelText =
      distributeFilter === distributeOptionAll ||
      distributeFilter === distributeOptionReadyToScan
        ? `${customer.customer_number} ${
            allPending
              ? `(${customer.count} ${t(
                  "workManagement.distribution.transport"
                )})`
              : ""
          }`
        : `${customer.customer_number}`;
    return getLabelTypography(labelText, isRed);
  };

  const getThirdLevelChildren = (lv) => {
    return distributionList
      .filter(
        (dl) =>
          dl.logical_vault_id === lv.logical_vault_id &&
          dl.customer_id === lv.customer_id
      )
      .map((dl) => ({
        nodeId:
          dl.created_datetime + dl.task_status + dl.customer_file_section_id,
        label:
          dateTimeFormatByLocale(
            dl.created_datetime,
            localeByBranch || DEFAULT_LOCALE
          ) +
          " " +
          dl.scanned_by_initials +
          " " +
          `(${dl.task_status})` +
          " " +
          (dl?.open_media_batch_id
            ? `[${t("workManagement.distribution.batch")} ${
                dl?.open_media_batch_id
              }]`
            : ""),
        customer_file_section_id: dl.customer_file_section_id,
        type: distribution,
        icon: NotepadIcon,
        customerId: dl.customer_id,
        logicalVault: lv
      }));
  };
  // Get icon based on type ID

  const getSecondLevelNodeId = (lv) =>
    lv.customer_id + lv.logical_vault_id + lv.logical_vault_code;
  const getSecondLevelChildren = (cid, customer) => {
    return logicalVaultList
      .filter((lv) => lv.customer_id === cid)
      .map((lv) => ({
        logical_vault_id: lv.logical_vault_id,
        nodeId: getSecondLevelNodeId(lv),
        label:
          lv.logical_vault_code +
          (lv?.interactive_distribution_scan_flag === VALUE_Y
            ? t("workManagement.distribution.reqScan")
            : ""),
        customerId: lv.customer_id,
        type: typeLogicalVault,
        id: lv.logical_vault_id,
        customer,
        children: getThirdLevelChildren(lv)
      }));
  };

  const treeData = [
    {
      nodeId: Distribution,
      label: Distribution,
      children: customerList?.map((customer) => ({
        nodeId: getNode(customer),
        customerId: customer.customer_id,
        label: getLable(customer),
        id: customer.customer_id,
        type: typeCustomer,
        icon: CustomerIcon,
        customer,
        children: getSecondLevelChildren(customer.customer_id, customer)
      }))
    }
  ];
  const renderTreeItems = (nodes) => {
    return nodes.map((node) => (
      <>
        <StyledTreeItem
          key={node.nodeId}
          itemId={node.nodeId}
          onClick={(e) => handleClick(e, node.id)}
          slots={{
            collapseIcon: () => (
              <ExpandMoreIcon id={`collapse-icon-${node.nodeId}`} />
            ),
            expandIcon: () => (
              <ChevronRightIcon id={`expand-icon-${node.nodeId}`} />
            )
          }}
          label={
            <Box sx={WorkManagementTreeStyles.nodeLableContainer}>
              <StyledCheckbox
                checked={checked.includes(node.nodeId)}
                onClick={(event) => event.stopPropagation()}
                indeterminate={getCheckboxIntermediate(node)}
                onChange={() => handleToggle(node.nodeId, node)}
              />
              {node.icon && (
                <SvgIcon
                  component={node.icon}
                  fontSize={WorkManagementTreeStyles.iconSize}
                  sx={WorkManagementTreeStyles.iconStyle}
                />
              )}
              {node.label}
            </Box>
          }
        >
          {node.children && renderTreeItems(node.children)}
        </StyledTreeItem>
      </>
    ));
  };

  return (
    <Box>
      <SimpleTreeView
        expandedItems={expanded}
        onItemExpansionToggle={handleExpand}
      >
        {renderTreeItems(treeData)}
      </SimpleTreeView>
    </Box>
  );
};

export default DistributionTree;
