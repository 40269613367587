import { t } from "i18next";
import { useCallback, useEffect } from "react";
import { VALUE_N, VALUE_Y } from "utils/constants";
import {
  customer,
  formFactor,
  location,
  printFormats
} from "utils/constants/container-processing/ContainerProcessing";

const {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography
} = require("@mui/material");
const {
  default: CommonModal
} = require("components/shared/common-modal/CommonModal");

const PickingPrintModal = ({
  isModalOpen,
  handleModalClose,
  print,
  sort1,
  setSort1,
  sort2,
  setSort2,
  sort3,
  setSort3,
  showReportedItems,
  setShowReportedItems,
  isPrintModalDiabled
}) => {
  useEffect(() => {
    if (sort1 && sort2 && sort1 === sort2) {
      setSort2("");
    }
    if (sort1 && sort3 && sort1 === sort3) {
      setSort3("");
    }
    if (sort2 && sort3 && sort2 === sort3) {
      setSort3("");
    }
  }, [sort1, sort2, sort3, setSort2, setSort3]);

  useEffect(() => {
    setSort1(location);
    setSort2(formFactor);
    setSort3(customer);
  }, [setSort1, setSort2, setSort3]);
  const handleSort1Change = (event) => {
    setSort1(event.target.value);
  };

  const handleSort2Change = (event) => {
    setSort2(event.target.value);
  };

  const handleSort3Change = (event) => {
    setSort3(event.target.value);
  };

  const applyRules = useCallback(() => {
    // Implement the rules based on your description
    if (sort1 === customer) {
      if (sort3 === location) {
        setSort2(formFactor);
      } else if (sort3 === formFactor) {
        setSort2(location);
      } else if (sort3 === customer) {
        setSort2(location);
        setSort3(formFactor);
      }
    } else if (sort1 === formFactor) {
      if (sort3 === location) {
        setSort2(customer);
      } else if (sort3 === customer) {
        setSort2(location);
      } else if (sort3 === formFactor) {
        setSort2(location);
        setSort3(customer);
      }
    } else if (sort1 === location) {
      if (sort3 === formFactor) {
        setSort2(customer);
      } else if (sort3 === customer) {
        setSort2(formFactor);
      } else if (sort3 === location) {
        setSort2(formFactor);
        setSort3(customer);
      }
    }
  }, [sort1, sort3, setSort2, setSort3]);
  useEffect(() => {
    applyRules();
  }, [sort1, sort2, sort3, applyRules]);

  const checkPrev = (e) => {
    if (e) {
      setShowReportedItems(VALUE_Y);
    } else {
      setShowReportedItems(VALUE_N);
    }
  };
  return (
    <>
      <CommonModal
        title={t("containerProcessing.picking.pickPrintModal")}
        open={isModalOpen}
        body={
          <>
            <Typography>
              {t("containerProcessing.picking.sortCriteria")}
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("containerProcessing.picking.sortByReport")}
                  </InputLabel>
                  <Select
                    label={t("containerProcessing.picking.sortByReport")}
                    value={sort1}
                    onChange={handleSort1Change}
                  >
                    {printFormats.map((obj) => {
                      return (
                        <MenuItem value={obj?.name} key={obj?.name}>
                          {obj?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {t("containerProcessing.picking.thenBy")}
                  </InputLabel>
                  <Select
                    label={t("containerProcessing.picking.thenBy")}
                    value={sort2}
                    onChange={handleSort2Change}
                  >
                    {printFormats.map((obj) => {
                      return (
                        <MenuItem value={obj?.name} key={obj?.name}>
                          {obj?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    {" "}
                    {t("containerProcessing.picking.thenBy")}
                  </InputLabel>
                  <Select
                    label={t("containerProcessing.picking.thenBy")}
                    value={sort3}
                    onChange={handleSort3Change}
                  >
                    {printFormats.map((obj) => {
                      return (
                        <MenuItem value={obj?.name} key={obj?.name}>
                          {obj?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showReportedItems === VALUE_Y}
                      onChange={(e) => checkPrev(e.target.checked)}
                    />
                  }
                  label={t("containerProcessing.picking.showPreviously")}
                />
              </Grid>
            </Grid>
          </>
        }
        buttons={
          <>
            <Button onClick={handleModalClose} variant="outlined">
              {t("common.cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={print}
              disabled={isPrintModalDiabled}
            >
              {t("containerProcessing.picking.Print")}
            </Button>
          </>
        }
      ></CommonModal>
    </>
  );
};
export default PickingPrintModal;
