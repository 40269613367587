import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography
} from "@mui/material";
import ErrorIcon from "assets/images/warning-2.svg";

import { ReactComponent as Check } from "assets/images/CheckFilled.svg";
import { ReactComponent as EditIcon } from "assets/images/EditIcon.svg";
import { ReactComponent as EditIconBeforeTheSave } from "assets/images/EditIconBeforeSave.svg";
import { DatePickerField, FullCircularLoader } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import CommonModal from "components/shared/common-modal/CommonModal";
import ConfirmPopup from "components/shared/confirm-popup/ConfirmPopup";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  OPERATIONS_MODULE_BASE_PATH,
  OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH,
  WORK_MANAGEMENT
} from "routing/Paths";
import { useGetAllCustomersQuery } from "services/api";
import {
  callToCloudFunction,
  getDataFromFirestore
} from "services/api/apiRequests";
import { CF_URLS } from "services/api/endpoints";
import { getMessageFromCode, getModuleSettings } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  DAYJS_LOCALES,
  DEFAULT_LOCALE,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  NO,
  N_CHECKER,
  ROLE_ACCESS_CODES,
  VALUE_N,
  VALUE_Y,
  YES,
  Y_CHECKER,
  dateFormatWithoutTime,
  errorMsgs,
  numberConstants,
  unauthorized,
  ymdDateFormat
} from "utils/constants";
import {
  buttonTasks,
  dayCount,
  iconType1,
  maxWithinDays,
  modalCase,
  notePad,
  pickingFilterOptions,
  pickingNodeId,
  pickingOptionReadyToPickList,
  pickingOptionReadyToScan,
  pickingOptioncompletedBatches,
  pickingTabId,
  requestType6,
  serviceDays10,
  serviceDays2,
  sortEmptySLot,
  sortOrderOptions,
  strZeros_PadIdwithZero,
  system_function_id,
  taskStatuses,
  zero
} from "utils/constants/open-media-processing/WorkManagement";
import {
  dateTimeFormatByLocale,
  findErrorMessage,
  getAuthenticatedUserBranch,
  getResponseData
} from "utils/helpers";
import WorkManagementDataTable from "../WorkManagementDataTable";
import {
  WorkManagementStyles,
  WorkManagementTableStyles
} from "../WorkManagementStyles";
import PickigActionModals from "./PickigActionModals";
import DownloadReportModal from "./PickingPrintModal";
import PickingTree from "./PickingTree";
import PickingUpdateModal from "./PickingUpdateModal";

// get the error msg from firestore msg collection
const getTheFirestoreErrorMessage = async (msgId) => {
  const msg = await getMessageFromCode(msgId);
  return { errorMsg: msg[0]?.descr };
};

const initialButtons = {
  printPickRpt: false,
  printTransportLabels: false,
  printShipList: false,
  assignMediaToTransport: false,
  cancelBatch: false,
  splitList: false
};

const Picking = ({ localeByBranch, setLocaleByBranch }) => {
  const location = useLocation();
  const { currentBranch, roleAccess, auth } = useSelector(selectAuth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [pickingFilter, setPickingFilter] = useState(
    pickingOptionReadyToPickList
  );
  const [lastGenRunDate, setLastGenRunDate] = useState(null);
  const [minDate, setMinDate] = useState(dayjs());
  const [maxDate, setMaxDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const [pickingFilterData, setPickingFilterData] = useState({
    customerLabel: t("common.all"),
    serviceDate: dayjs().add(dayCount, "day"),
    serviceDays: iconType1
  });
  const [throwError, setThrowError] = useState(false);
  const [throwErrorMessage, setThrowErrorMessage] = useState(null);
  const [selectByRun, setSelectByRun] = useState(false);
  const [runData, setRunData] = useState([]);
  const [serviceDaysModal, setServiceDaysModal] = useState(false);
  const [serviceDaysModalMsg, setServiceDaysModalMsg] = useState(false);
  const [allLocations, setAllLocations] = useState([]);

  const [customerList, setCustomerList] = useState([]);
  const { data: customers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const [checkedPickingData, setCheckedPickingData] = useState([]);
  const [pickingList, setPickingList] = useState({});
  const [pickingListLoaders, setpickingListLoaders] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checked, setChecked] = useState([]);

  // Button Actions related states
  const [openPrintModal, setOpenPrintModal] = useState("");
  const [insertList, setInsertList] = useState([]);
  const [invalidForCancelReportMsg, setInvalidForCancelReportMsg] = useState(
    []
  );
  const [validTasks, setValidTasks] = useState([]);
  const [showCombinedList, setShowCombinedList] = useState(false);
  const [bIncludeNotInInventory, setBIncludeNotInInventory] = useState(false);
  // States for Split List operation and Modal
  const [fastPickList, setFastPickList] = useState([]);
  const [selectedSort, setselectedSort] = useState(sortOrderOptions[0]);
  const [selectedInsertSize, setSelectedInsertSize] = useState();
  const [insertPerList, setInsertPerList] = useState("");
  const [isByVault, setIsByVault] = useState(false);
  const [selectedFastPick, setSelectedFastPick] = useState(null);
  const [splitLoading, setSplitLoading] = useState(false);
  const [combinedList, setCombinedList] = useState(false);

  // Cancel Batch Error Message State
  const [cancelBatchMessage, setCancelBatchMessage] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);

  const [showTree, setShowTree] = useState();
  const selectedBranchWhenLogin = getAuthenticatedUserBranch();
  const isUserHasPermission = roleAccess.includes(ROLE_ACCESS_CODES.CODE_172);
  const [expanded, setExpanded] = useState([]);
  const [findBtnStatus, setFindBtnStatus] = useState(false);

  // Action buttons enable states state
  const [buttons, setButtons] = useState({ ...initialButtons });

  // Report State
  const [isDownloadPopupVisible, setIsDownloadPopupVisible] = useState(false);
  const [printRequestBody, setPrintRequestBody] = useState(null);
  const [reportName, setReportName] = useState(null);
  const [printWarningMsg, setPrintWarningMsg] = useState(null);

  const clear = () => {
    fetchLastGenRunDate();
    setRunData([]);
    setSelectByRun(false);
    setThrowError(false);
    setThrowErrorMessage(null);
    setPickingFilterData({
      customerLabel: t("common.all"),
      serviceDays: iconType1,
      serviceDate: dayjs(Date.now())
    });
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
    setFindBtnStatus(false);
  };
  const getAllLocations = async () => {
    setLoading((prev) => true);
    try {
      const reqBody = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      });
      const response = await callToCloudFunction(
        reqBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}${CF_URLS.COMMON.getalllocations}`
      );

      const data = await getDataFromFirestore(response, 1, response.data.docId);

      if (data?.data[0]) {
        setAllLocations((prev) => data?.data[0]);
      } else if (data?.error) {
        setAllLocations([]);
        setThrowErrorMessage(data.error);
        setThrowError(true);
      }
    } catch (e) {
      setLoading((prev) => false);
      setAllLocations([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };
  useEffect(() => {
    if (allLocations) {
      if (allLocations.length > 0) {
        const selectedLocationDetails = allLocations.filter(
          (location) => location.district_id === selectedBranchWhenLogin
        );
        setLocaleByBranch(
          DAYJS_LOCALES[selectedLocationDetails[0]?.iso_locale_code] ||
            DEFAULT_LOCALE
        );
      }
    }
    // eslint-disable-next-line
  }, [allLocations, selectedBranchWhenLogin]);

  const handleRowClick = (value, tableMeta) => {
    setSelectedRow(tableData[tableMeta?.rowIndex]);
    setIsModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const initializePage = async () => {
      await Promise.allSettled([fetchLastGenRunDate(), getAllLocations()]);
      // if the route state has tab value
      if (location.state?.tab === pickingTabId) {
        // if the route state has filter value
        if (Number(location.state?.filter) > 0) {
          handleFilter(null, {
            props: { value: String(location.state?.filter) }
          });
        }
        await findApiCall();
        window.history.replaceState(
          null,
          "",
          `${OPERATIONS_MODULE_BASE_PATH}/${OPERATIONS_MODULE_OPEN_MEDIA_PROCESSING_BASE_PATH}/${WORK_MANAGEMENT}`
        );
      }
    };

    initializePage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastGenRunDate) {
      if (pickingFilter === pickingOptioncompletedBatches) {
        const tempMinDate = new Date();
        tempMinDate.setDate(tempMinDate.getDate() - 30);
        setMinDate(dayjs(tempMinDate));
        setMaxDate(dayjs(new Date(lastGenRunDate)));
        setPickingFilterData((prev) => ({
          ...prev,
          serviceDate: dayjs(Date.now())
        }));
      } else {
        const today = new Date();
        if (lastGenRunDate <= today) {
          setMinDate(dayjs(new Date(lastGenRunDate)));
          setMaxDate(dayjs(today));
        } else {
          setMinDate(dayjs(today));
          setMaxDate(dayjs(new Date(lastGenRunDate)));
          setPickingFilterData((prev) => ({
            ...prev,
            serviceDate:
              dayjs(Date.now()).add(dayCount, "day") > dayjs(lastGenRunDate)
                ? dayjs(lastGenRunDate)
                : dayjs(Date.now()).add(dayCount, "day")
          }));
        }
      }
    }
  }, [lastGenRunDate, pickingFilter]);

  const fetchRunDropdown = async (changeStatus, date) => {
    try {
      setLoading(true);
      const districtId = getAuthenticatedUserBranch();
      const reqBody = {
        main_district_id: districtId,
        run_date: changeStatus
          ? dayjs(date).locale(localeByBranch).format(ymdDateFormat)
          : dayjs(pickingFilterData?.serviceDate)
              .locale(localeByBranch)
              .format(ymdDateFormat)
      };
      let response;
      if (pickingFilter === pickingOptioncompletedBatches) {
        response = await callToCloudFunction(
          reqBody,
          `${CF_URLS.openMediaProcessing?.workManagement.picking.getrunlistforCompletedBatches}`
        );
      } else {
        response = await callToCloudFunction(
          reqBody,
          `${CF_URLS.openMediaProcessing?.workManagement.picking.getrunlist}`
        );
      }
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      const sortData = data.data[0]?.sort((a, b) =>
        a.run_name.localeCompare(b.run_name)
      );
      if (response.data.isSuccess) {
        setLoading(false);
        setRunData(sortData);
        setThrowError(false);
        setThrowErrorMessage(null);
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const findApiCall = async () => {
    const districtId = await getAuthenticatedUserBranch();
    setLoading(true);
    const reqBody = {
      main_district_id: districtId,
      display_code: pickingFilter,
      service_date:
        pickingFilter === pickingOptioncompletedBatches && !selectByRun
          ? ""
          : dayjs(pickingFilterData.serviceDate).format(ymdDateFormat),
      customer_id: pickingFilterData?.customerId,
      service_days: pickingFilterData.serviceDays,
      run_id: pickingFilterData?.runId || "",
      get_customers: VALUE_Y
    };
    const response = await callToCloudFunction(
      reqBody,
      `${CF_URLS.openMediaProcessing?.workManagement.picking.getDailyPickList}`
    );
    const data = await getDataFromFirestore(response, 1, response.data.docId);
    const list = data.data[0];
    setChecked([]);
    const sortedData = list.sort((a, b) =>
      a.customer_number.localeCompare(b.customer_number)
    );
    setCustomerList(sortedData);
    const custPickingList = {};
    list.forEach((customer) => {
      custPickingList[customer.customer_id] = [];
    });
    setPickingList(custPickingList);
    setShowTree(true);
    if (response.data.isSuccess) {
      setTableData([]);
      setLoading(false);
      setThrowError(false);
      setThrowErrorMessage(null);
      setFindBtnStatus(true);
    }
  };

  const fetchFindFilter = async () => {
    try {
      if (
        dayjs(
          dayjs(pickingFilterData.serviceDate).format(ymdDateFormat)
        ).isSameOrBefore(dayjs(lastGenRunDate).format(ymdDateFormat))
      ) {
        if (selectByRun && !pickingFilterData.runId) {
          setThrowError(true);
          setThrowErrorMessage(t("workManagement.picking.runErrMsg"));
        } else if (
          pickingFilter === pickingOptioncompletedBatches &&
          !selectByRun
        ) {
          const days = parseInt(pickingFilterData.serviceDays, 10);
          if (!pickingFilterData.serviceDays) {
            setThrowError(true);
            setThrowErrorMessage(t("workManagement.picking.serviceDaysErrMsg"));
          } else if (days < 0 || days > maxWithinDays) {
            const serviceErrorDescr = await getMessageFromCode(
              errorMsgs.errorCode21060
            );
            const description = serviceErrorDescr[0].descr
              .replace("|", t("workManagement.picking.numberOfDays"))
              .replace("|", t("workManagement.picking.oneDays"))
              .replace("|", t("workManagement.picking.thirtyDays"));
            setServiceDaysModalMsg(description);
            setServiceDaysModal(true);
          } else {
            findApiCall();
          }
        } else {
          findApiCall();
        }
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };
  const loadTablePicking = (checked) => {
    setCheckedPickingData(checked.map((item) => item.split("-")[0]));
  };
  const fetchSecondLevel = async (cid, update) => {
    if (!cid) return;
    if (pickingList[cid]?.length !== 0 && !update) return;
    setpickingListLoaders((prev) => [...prev, cid]);
    const districtId = await getAuthenticatedUserBranch();
    try {
      const reqBody = {
        main_district_id: districtId,
        display_code: pickingFilter,
        service_date:
          pickingFilter === pickingOptioncompletedBatches && !selectByRun
            ? ""
            : dayjs(pickingFilterData.serviceDate).format(ymdDateFormat),
        customer_id: cid,
        service_days: pickingFilterData.serviceDays.toString(),
        run_id: pickingFilterData?.runId || "",
        get_customers: ""
      };
      const response = await callToCloudFunction(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.getDailyPickList}`
      );
      let data = await getDataFromFirestore(response, 1, response.data.docId);
      data = data.data[0];
      setpickingListLoaders((prev) =>
        prev.filter((pickLoader) => pickLoader !== cid)
      );
      const requestTypeCreatedDate = data.map((obj) => {
        obj.request_type_created_dateTime = `${
          obj.request_type
        }${dateTimeFormatByLocale(
          obj.created_datetime,
          localeByBranch || DEFAULT_LOCALE
        )}`;
        return obj;
      });
      setTableData((prev) => [...prev, ...requestTypeCreatedDate]);
      const sortedPickData = data?.sort((a, b) => {
        const customerNumberA = a.customer_number?.trim();
        const customerNumberB = b.customer_number?.trim();

        if (customerNumberA === customerNumberB) {
          const logicalVaultCodeA = a.created_datetime || sortEmptySLot;
          const logicalVaultCodeB = b.created_datetime || sortEmptySLot;
          return logicalVaultCodeA.localeCompare(logicalVaultCodeB);
        }

        return customerNumberA.localeCompare(customerNumberB);
      });
      setPickingList((prev) => {
        return {
          ...prev,
          [cid]: sortedPickData
        };
      });
    } catch (error) {
      if (error?.response?.data === unauthorized) {
        setThrowErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
        setThrowError(true);
      } else {
        setThrowErrorMessage(t("printTapeLabel.systemError"));
        setThrowError(true);
      }
    }
  };

  const fetchLastGenRunDate = async () => {
    const districtId = await getAuthenticatedUserBranch();
    try {
      setLoading(true);
      const reqBody = {
        main_district_id: districtId
      };
      const response = await callToCloudFunction(
        reqBody,
        `${CF_URLS.openMediaProcessing?.workManagement.picking.getLastGeneretedDate}`
      );
      const data = await getDataFromFirestore(response, 1, response.data.docId);
      if (response.data.isSuccess) {
        setLoading(false);
        setLastGenRunDate(new Date(data.data[0][0]?.last_gen_run_date));
      }
    } catch (error) {
      setLoading(false);
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    }
  };

  const checkedTableData = tableData
    ?.sort((a, b) => {
      const customerNumberA = a.customer_number?.trim();
      const customerNumberB = b.customer_number?.trim();

      if (customerNumberA === customerNumberB) {
        const logicalVaultCodeA = a.created_datetime || sortEmptySLot;
        const logicalVaultCodeB = b.created_datetime || sortEmptySLot;
        return logicalVaultCodeA.localeCompare(logicalVaultCodeB);
      }

      return customerNumberA.localeCompare(customerNumberB);
    })
    ?.filter((pick) =>
      checkedPickingData.includes(
        pick?.batch_id ||
          pick?.picking_request_id ||
          pick?.customer_file_section_id
      )
    );
  const columns = [
    {
      name: "",
      label: "",
      options: {
        customBodyRender: (value, tableMeta) => {
          const isSelectedRow =
            selectedRow && selectedRow.i === tableMeta.rowIndex;
          const isEdited = tableData[tableMeta.rowIndex]?.isEdited;
          const iconComponent =
            isSelectedRow && isModalOpen
              ? Check
              : isEdited
                ? EditIconBeforeTheSave
                : EditIcon;
          return (
            <Button
              onClick={() => {
                handleRowClick(value, tableMeta);
              }}
              disabled={!isUserHasPermission}
            >
              <SvgIcon component={iconComponent} />
            </Button>
          );
        }
      }
    },
    {
      name: "customer_number",
      label: t("branchOutagePickList.customer")
    },
    {
      name: "logical_vault_code",
      label: t("branchOutagePickList.logicalVault")
    },
    {
      name: "request_type_created_dateTime",
      label: t("branchOutagePickList.list")
    },
    {
      name: "batch_id",
      label: t("branchOutagePickList.batch")
    },
    {
      name: "data_row_count",
      label: t("branchOutagePickList.count")
    },
    {
      name: "request_service_date",
      label: t("branchOutagePickList.serviceDate"),
      options: {
        customBodyRender: (value) => {
          return value
            ? dayjs
                .utc(value)
                .locale(localeByBranch)
                .format(dateFormatWithoutTime)
            : "";
        }
      }
    },
    {
      name: "task_status",
      label: t("branchOutagePickList.taskStatus")
    }
  ];
  const handleFilter = (e, { props }) => {
    setPickingFilter(props?.value);
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
    setPickingFilterData({
      customerLabel: t("common.all"),
      serviceDays: iconType1,
      serviceDate: dayjs(Date.now())
    });
    setRunData([]);
  };

  const handleFilterRun = (e, { props }) => {
    setPickingFilterData((prev) => ({
      ...prev,
      runId: props.value
    }));
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
  };

  const handleFilterServiceDate = (e) => {
    setPickingFilterData((prev) => ({
      ...prev,
      serviceDate: dayjs(e)
    }));
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
    if (selectByRun) {
      fetchRunDropdown(true, e);
    }
  };
  const handleServiceDays = (e) => {
    let value = e.target.value;
    if (value.length > serviceDays2) {
      value = value.slice(0, serviceDays2);
    }
    const numValue = parseInt(value, serviceDays10);
    setPickingFilterData((prev) => ({
      ...prev,
      serviceDays: numValue
    }));
    setFindBtnStatus(false);
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
    setButtons({});
  };
  const handleSelectByRun = (event) => {
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
    setPickingFilterData({
      customerLabel: t("common.all"),
      serviceDays: iconType1,
      serviceDate:
        pickingFilter === pickingOptioncompletedBatches
          ? pickingFilterData.serviceDate
          : pickingFilterData.serviceDate
    });
    setRunData([]);
    if (selectByRun) {
      setSelectByRun(false);
    } else {
      setSelectByRun(true);
      fetchRunDropdown();
    }
  };
  const handleFilterCustomer = (e, newValue) => {
    setPickingFilterData((prev) => ({
      ...prev,
      customerId: newValue?.value,
      customerLabel: newValue?.label
    }));
    setTableData([]);
    setpickingListLoaders([]);
    setPickingList({});
    setCustomerList([]);
    setFindBtnStatus(false);
    setChecked([]);
    setShowTree(false);
    setExpanded([]);
  };

  const handleModalClose = () => {
    setSelectedRow(0);
    setIsModalOpen((prev) => !prev);
  };

  //#region BUTTON VADIATIONS
  const resetButtons = () => {
    setButtons({
      printPickRpt: false,
      printTransportLabels: false,
      printShipList: false,
      assignMediaToTransport: false,
      cancelBatch: false,
      splitList: false
    });
  };

  // Select the last row from the checked list from the tree, clear the buttons if all un-checked
  useEffect(() => {
    if (checkedTableData.length) {
      handleRowSelection(null, null, [checkedTableData.length - 1]);
    } else {
      resetButtons();
    }
    //eslint-disable-next-line
  }, [checkedTableData.length]);

  const getSelectedRow = (index) => {
    return checkedTableData[index];
  };

  const handleRowSelection = (
    currentRowsSelected,
    allRowsSelected,
    rowsSelected
  ) => {
    setSelectedRows((prev) => rowsSelected);
    validateButtons(checkedTableData, rowsSelected[0]);
  };

  // Function to validate buttons based on conditions
  function validateButtons(pickingTasks, index) {
    let selectedRow = getSelectedRow(index); // function to get selected row
    if (!selectedRow) return;

    if (isOnlyMDRInGrid([selectedRow])) {
      resetButtons();
      return;
    }

    let nonMDRExists = pickingTasks.some((task) => task.mdr_flag === VALUE_N);

    let newButtons = { ...initialButtons };

    // Loop through each picking task (Loop 1)
    pickingTasks.forEach((task1) => {
      Object.values(buttonTasks).forEach((action) => {
        let resultStatus = checkStatusForAction(task1, action); // function to check status for action

        if (resultStatus) {
          if (nonMDRExists) {
            if (isOnlyMDRInGrid(checkedTableData)) return; // function to check if only MDR exists

            switch (action) {
              case buttonTasks.printPickRpt:
                if (roleAccess.includes(ROLE_ACCESS_CODES.CODE_170))
                  newButtons.printPickRpt = true;
                if (roleAccess.includes(ROLE_ACCESS_CODES.CODE_254)) {
                  if (
                    pickingFilter === pickingOptionReadyToPickList &&
                    checkedTableData.length === 1
                  ) {
                    newButtons.splitList = true;
                  }
                }
                break;
              case buttonTasks.printShiplistRpt:
                if (roleAccess.includes(ROLE_ACCESS_CODES.CODE_170))
                  newButtons.printShipList = true;
                break;
              case buttonTasks.printLabel:
                if (roleAccess.includes(ROLE_ACCESS_CODES.CODE_170))
                  newButtons.printTransportLabels = true;
                break;
              case buttonTasks.cancelBatch:
                newButtons.cancelBatch = true;
                break;
              case buttonTasks.assignTransport:
                newButtons.assignMediaToTransport = true;
                break;
              default:
                break;
            }
          }
        }
      });
    });

    // Loop through each picking task (Loop 2)
    pickingTasks.forEach((task1) => {
      pickingTasks.forEach((task2) => {
        if (task1.checked && task2.batchId !== task1.batchId) {
          newButtons.assignMediaToTransport = false;
        }
      });

      if (task1.checked && roleAccess.includes(ROLE_ACCESS_CODES.CODE_171)) {
        newButtons.assignMediaToTransport = true;
      }
    });

    setButtons(newButtons);
  }

  const isOnlyMDRInGrid = (arr) => {
    return arr.every((task) => task.mdr_flag === VALUE_Y);
  };

  function checkSelectedTasksForAction(action) {
    let showErrorMessage = false;
    let errMsg = [];
    let tasks = [...checkedTableData];
    let validTasks = [];
    tasks.forEach((task) => {
      if (task.mdr_flag === VALUE_Y) {
        task.invalid = true;
      } else {
        let output = checkStatusForAction(task, action);
        if (!output) {
          task.invalid = true;
          showErrorMessage = true;
        } else {
          validTasks.push(task);
        }
      }
    });

    if (showErrorMessage) {
      tasks.forEach((task) => {
        if (task.invalid) {
          errMsg.push(
            `${task.customer_number}-${task.logical_vault_code}-${task.request_type_created_dateTime}-${task.task_status}`
          );
        }
      });
    }
    setValidTasks(validTasks);
    return { errMsg, validTasks };
  }

  // Function to check the status of a batch or file for a specific action
  function checkStatusForAction(task, actionType) {
    if (task.batch_id > 0) {
      switch (actionType) {
        case buttonTasks.printPickRpt:
          return [
            taskStatuses.notStarted,
            taskStatuses.started,
            taskStatuses.pickReportPrinted
          ].includes(task.task_status);
        case buttonTasks.printShiplistRpt:
        case buttonTasks.printLabel:
          return (
            task.task_status === taskStatuses.complete &&
            !task.intervault_file_section_id
          );
        case buttonTasks.cancelBatch:
          return [taskStatuses.notStarted, taskStatuses.started].includes(
            task.task_status
          );
        case buttonTasks.assignTransport:
          return task.task_status === taskStatuses.needsTransport;
        default:
          return false;
      }
    } else {
      switch (actionType) {
        case buttonTasks.printPickRpt:
          return [
            taskStatuses.notStarted,
            taskStatuses.pickReportPrinted
          ].includes(task.task_status);
        case buttonTasks.changeServiceDate:
          return (
            task.task_status === taskStatuses.notStarted &&
            task.iconType === notePad
          );
        default:
          return false;
      }
    }
  }

  //#endregion

  //#region ACTION BUTTON FUNCTIONALITY
  const getInsertSizeList = async () => {
    setLoading(true);
    const districtId = await getAuthenticatedUserBranch();
    try {
      const reqBody = {
        main_district_id: districtId,
        district_id: DISTRICT_ID_GET_ALL_LOCATIONS
      };
      const response = await callToCloudFunction(
        reqBody,
        CF_URLS.openMediaProcessing?.workManagement.picking.getInsertSizeList
      );
      let data = await getDataFromFirestore(response, 1, response.data.docId);
      data = data.data[0];
      if (data.length) {
        setInsertList(
          data
            ?.map((size) => ({
              value: size.insert_size,
              label: size.insert_size
            }))
            .sort((a, b) => a.value - b.value)
        );
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setInsertList([]);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setThrowError(true);
    }
  };

  function getPaddedBatchIds(batchIds) {
    let result = "";
    batchIds.forEach(
      (id) => (result += padIdWithZeros(id, strZeros_PadIdwithZero))
    );
    return result;
  }

  function padIdWithZeros(id, zeros) {
    const idStr = id.toString();
    const paddedId =
      zeros.substring(0, Math.min(10 - idStr.length, zeros.length)) + idStr;
    return paddedId;
  }

  function generateErrorString(tasks) {
    let errorArray = "";
    for (let i = 0; i < Math.min(tasks.length, 10); i++) {
      let task = tasks[i];
      let errorString = `${task.customer_number.trim()}-${task.logical_vault_code.trim()}-${task.task_status.trim()} `;
      errorArray += errorString;
    }
    tasks.length > 10 && (errorArray += "...");
    return errorArray;
  }

  const uncheckInvalidTasks = (validTasks) => {
    const matchedTasks = [];
    checked.forEach((item) => {
      if (!isNaN(item)) {
        validTasks.forEach((task) => {
          if (
            task.batch_id === item ||
            (task.batch_id === "" && task.picking_request_id === item)
          ) {
            matchedTasks.push(task.batch_id || task.picking_request_id);
          }
        });
      } else {
        matchedTasks.push(item);
      }
    });
    setCheckedPickingData(matchedTasks);
    setChecked(matchedTasks);
  };

  const onHandleNoFromCancelReportModal = () => {
    uncheckInvalidTasks(validTasks);
    setOpenPrintModal("");
  };

  const onHandleYesFromCancelReportModal = () => {
    let batchIds = validTasks.map((task) => task.batch_id);
    const districtId = getAuthenticatedUserBranch();
    setCancelLoading(true);

    try {
      batchIds.forEach(async (id) => {
        const reqBody = {
          main_district_id: districtId,
          open_media_batch_id: id,
          system_function_id,
          last_mod_user: auth?.user?.login || ""
        };

        const response = await callToCloudFunction(
          reqBody,
          `${CF_URLS.openMediaProcessing?.workManagement.picking.cancelBatch}`
        );
        let data = await getDataFromFirestore(response, 1, response.data.docId);
        data = data.data[0];
        if (data[0].error === zero) {
          onClickRefresh();
        } else {
          let { errorMsg } = await getTheFirestoreErrorMessage(data[0].error);
          setThrowError(true);
          setThrowErrorMessage(errorMsg?.replace("|", " "));
          setOpenPrintModal("");
        }
      });
    } catch (error) {
      if (error?.response?.data === unauthorized) {
        setThrowErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
        setThrowError(true);
      } else {
        setThrowErrorMessage(t("printTapeLabel.systemError"));
        setThrowError(true);
      }
    } finally {
      setCancelLoading(false);
    }
  };

  const getFastPickList = async () => {
    setLoading(true);
    try {
      const results = await getModuleSettings();

      if (results) {
        const xmlString = results[0]?.settings_xml;

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "text/xml");

        // Extract the value of splitDestructPickListValues
        const fastpickList = xmlDoc.getElementsByTagName(
          "splitPickListFastPickValues"
        )[0].textContent;

        // Convert the value to an array
        const splitFastpickList = fastpickList.split(",").map((e) => {
          return { label: e, value: e };
        });
        setFastPickList(splitFastpickList);
      }
    } catch (e) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  const splitListRequest = async (reqBody, URL, cb) => {
    try {
      const response = await callToCloudFunction(reqBody, URL);
      let data = await getDataFromFirestore(response, 1, response.data.docId);
      data = data.data[0];
      if (data[0].error === zero) {
        // Split List success
        cb();
        onClickRefresh();
      } else {
        let { errorMsg } = await getTheFirestoreErrorMessage(data[0].error);
        setThrowError(true);
        setThrowErrorMessage(errorMsg);
        cb();
        setOpenPrintModal();
      }
    } catch (error) {
      if (error?.response?.data === unauthorized) {
        setThrowErrorMessage(t("printTapeLabel.sessionLoggedOutMessage"));
        setThrowError(true);
      } else {
        setThrowErrorMessage(t("printTapeLabel.systemError"));
        setThrowError(true);
      }
    } finally {
      setSplitLoading(false);
    }
  };
  const onClickYesFromSplitList = async (cb) => {
    let selectedPick = checkedTableData[selectedRow];
    if (!selectedPick) return;
    setSplitLoading(true);
    const districtId = await getAuthenticatedUserBranch();
    let cfs_id = selectedPick.customer_file_section_id;
    let reqBody = {
      cfs_id,
      main_district_id: districtId
    };
    let {
      pickingcustomerfilesection,
      pickingcustomerfilesectionrecieves,
      pickingcustomerfilesectionandsplits
    } = CF_URLS.openMediaProcessing?.workManagement?.picking || {};
    let URL = "";
    if (isByVault) {
      reqBody.split_by_vault = VALUE_Y;
      URL = pickingcustomerfilesection;
    } else if (selectedFastPick) {
      reqBody.first_batch_size = selectedFastPick.value;
      URL = pickingcustomerfilesectionrecieves;
    } else {
      reqBody = {
        ...reqBody,
        insert_size: selectedInsertSize.value,
        inserts_per_list: insertPerList,
        sort_order_id: selectedSort.value
      };
      URL = pickingcustomerfilesectionandsplits;
    }
    await splitListRequest(reqBody, URL, cb);
  };
  const loadPrintPickModalForm = async () => {
    await getInsertSizeList();
    setOpenPrintModal(modalCase.printPickRpt);
  };

  const onCLickYesFromInvalidCancelReport = async () => {
    let { errorMsg } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode25168
    );
    setCancelBatchMessage(errorMsg.replace("|", ""));
    setOpenPrintModal(modalCase.cancelRpt);
  };

  // REFRESH
  const onClickRefresh = () => {
    fetchFindFilter();
    setChecked([]);
    setExpanded([pickingNodeId]);
    setCheckedPickingData([]);
    let customer = JSON.parse(JSON.stringify(pickingList));
    for (let key in customer) {
      customer[key] = [];
    }
    setPickingList(customer);
    setOpenPrintModal("");
  };

  // PRINT PICK LIST
  const onPrintPickListClick = () => {
    let showCombinedList = pickingFilter !== pickingOptionReadyToScan;
    let bIncludeNotInInventory = checkedTableData.some((data) => {
      return data.request_type_id === requestType6;
    });
    setShowCombinedList(showCombinedList);
    setBIncludeNotInInventory(bIncludeNotInInventory);
    loadPrintPickModalForm();
    setCombinedList(false);
    setselectedSort(sortOrderOptions[0]);
  };

  // set warning message to show in print confirm modal
  const getPrintWarning = async (inValidTasks) => {
    let errorArray = generateErrorString(inValidTasks);
    const { errorMsg } = await getTheFirestoreErrorMessage(
      errorMsgs.errorCode25152
    );
    const message = `${errorMsg} ${errorArray}`;

    setPrintWarningMsg(message.replace(/[|,]/g, " "));
  };

  // PRINT SHIP LIST
  const onPrintShipListClick = async () => {
    setLoading(true);
    setReportName(modalCase.printShipList);
    let { validTasks, errMsg } = checkSelectedTasksForAction(
      buttonTasks.printShiplistRpt
    );
    if (errMsg.length) {
      uncheckInvalidTasks(validTasks);
    }
    let batchesIdList = validTasks.map((batch) => batch.batch_id);
    if (batchesIdList.length) {
      const districtId = await getAuthenticatedUserBranch();
      const paddedIds = getPaddedBatchIds(batchesIdList);
      const reqBody = {
        main_district_id: districtId,
        open_media_batch_id_list: paddedIds
      };
      setPrintRequestBody(reqBody);
      try {
        const response = await callToCloudFunction(
          reqBody,
          `${CF_URLS?.openMediaProcessing.shipListReportsForBatchIds}`
        );
        let data = await getDataFromFirestore(response, 5, response.data.docId);
        data = data.data;
        if (data[4][0]?.error === zero) {
          setIsDownloadPopupVisible(true);
        } else {
          getPrintWarning(validTasks);
        }
      } catch (error) {
        setThrowError(true);
        setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      } finally {
        setLoading(false);
      }
    }
  };

  // PRINT TRASPORT LABEL
  const onPrintTransportLabelsClick = async () => {
    setReportName(modalCase.printLabel);
    let { errMsg, validTasks, inValidTasks } = checkSelectedTasksForAction(
      buttonTasks.printLabel
    );
    let requestIdList = validTasks.map((task) => task.picking_request_id);

    const districtId = await getAuthenticatedUserBranch();
    const paddedIds = getPaddedBatchIds(requestIdList);
    const reqBody = {
      main_district_id: districtId,
      request_id_list: paddedIds
    };
    setPrintRequestBody(reqBody);
    if (!errMsg.length) {
      setIsDownloadPopupVisible(true);
    } else {
      getPrintWarning(inValidTasks);
    }
  };

  const onClickYesPrintWarning = () => {
    setPrintWarningMsg(null);
    if (reportName === modalCase.printLabel) {
      uncheckInvalidTasks(validTasks);
    } else if (reportName === modalCase.printPickRpt) {
      fetchBatchId();
    }
    setIsDownloadPopupVisible(true);
  };

  const fetchBatchId = async () => {
    setLoading(true);
    const districtId = await getAuthenticatedUserBranch();
    let fileSectionIdList = checkedTableData.map(
      (data) => data.customer_file_section_id
    );
    const paddedFileIds = getPaddedBatchIds(fileSectionIdList);
    const batchReqBody = {
      main_district_id: districtId,
      customer_file_section_id_list: paddedFileIds,
      combine_sections: combinedList ? Y_CHECKER : N_CHECKER,
      insert_size: selectedInsertSize?.value,
      sort_order_id: selectedSort.value,
      include_items_not_in_inv_flag: bIncludeNotInInventory
        ? Y_CHECKER
        : N_CHECKER
    };
    let batchData = await getResponseData(
      batchReqBody,
      `${CF_URLS?.openMediaProcessing.workManagement.picking.processAndBatchPickList}`,
      2
    );
    let batchesIdList = batchData?.data[0]
      ?.filter((item) => item?.open_media_batch_id)
      .map((batch) => batch?.open_media_batch_id);

    if (batchesIdList.length) {
      const paddedIds = getPaddedBatchIds(batchesIdList);
      const reqBody = {
        main_district_id: districtId,
        district_id: districtId,
        open_media_batch_id_list: paddedIds,
        insert_size: selectedInsertSize?.value,
        sort_code:
          selectedSort?.value === 1
            ? numberConstants.zero
            : numberConstants.one,
        include_not_in_inventory_flag: bIncludeNotInInventory
          ? Y_CHECKER
          : N_CHECKER,
        combined_list: combinedList ? YES : NO
      };
      setPrintRequestBody(reqBody);
      setOpenPrintModal("");
      setIsDownloadPopupVisible(true);
      setLoading(false);
    }
  };

  // PRINT PICK REPORT
  const onPrintPickReportButtonClick = async () => {
    setLoading(true);
    setReportName(modalCase.printPickRpt);
    try {
      const districtId = await getAuthenticatedUserBranch();
      let customerIdList = checkedTableData.map((data) => data.customer_id);
      customerIdList = [...new Set(customerIdList)];
      const auditReqBody = {
        main_district_id: districtId,
        customer_id_list: customerIdList.toString()
      };
      let auditData = await getResponseData(
        auditReqBody,
        `${CF_URLS?.openMediaProcessing.workManagement.picking.checkAuditInProgress}`,
        2
      );

      if (auditData?.data[1][0]?.error === zero) {
        await fetchBatchId();
      } else {
        setOpenPrintModal("");
        const { errorMsg } = await getTheFirestoreErrorMessage(
          errorMsgs.errorCode25166
        );
        setPrintWarningMsg(errorMsg.replace(/[|,]/g, " "));
      }
    } catch (error) {
      setThrowError(true);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
    } finally {
      setLoading(false);
    }
  };

  // CANCEL BATCH
  const onClickCancelReport = async () => {
    let { errMsg } = checkSelectedTasksForAction(buttonTasks.cancelBatch);
    if (errMsg?.length) {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode25152
      );
      setCancelBatchMessage(errorMsg.replace("|", ""));
      setOpenPrintModal(modalCase.invalidCancelRpt);
      setInvalidForCancelReportMsg(errMsg);
    } else {
      let { errorMsg } = await getTheFirestoreErrorMessage(
        errorMsgs.errorCode25168
      );
      setCancelBatchMessage(errorMsg.replace("|", ""));
      setOpenPrintModal(modalCase.cancelRpt);
    }
  };

  // SPLIT LIST
  const onSplitListButtonClick = async () => {
    if (!insertList.length) {
      await getInsertSizeList();
    }
    if (!fastPickList.length) await getFastPickList();
    setOpenPrintModal(modalCase.splitList);
  };

  //#endregion

  //#region TABLE RELATED
  const tableOptions = {
    sort: true,
    selectableRowsHeader: false,
    selectToolbarPlacement: "none",
    onRowSelectionChange: handleRowSelection,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    selectableRowsOnClick: true,
    rowsSelected: selectedRows,
    setRowProps: (row, dataIndex) => {
      const isSelected = selectedRows?.includes(dataIndex);
      return {
        style: {
          backgroundColor: isSelected && "var(--color-im-light-blue-100)"
        }
      };
    }
  };

  const getTotalCount = () =>
    checkedTableData.reduce((a, n) => a + parseInt(n.data_row_count, 10), 0);

  const tableTitleSection = (
    <Box pt={spacing.padding}>
      <Typography variant="h5" style={WorkManagementStyles.titleStyle}>
        {t("workManagement.picking.selectedDetails")}
      </Typography>

      <Stack
        direction="row"
        spacing={10}
        mt={spacing.verticalMargin32}
        mb={spacing.verticalMargin16}
      >
        <Typography variant="body1" style={WorkManagementStyles.titleStyle}>
          {t("workManagement.picking.totalCount") + getTotalCount()}
        </Typography>
      </Stack>
    </Box>
  );

  const customToolbar = () => {
    return (
      <Stack
        direction="row"
        spacing={2}
        mt={spacing.verticalMargin32}
        justifyContent="flex-end"
        sx={WorkManagementTableStyles.toolBar}
      >
        <Button
          variant="outlined"
          onClick={() => onClickRefresh()}
          disabled={customerList?.length === 0}
        >
          {t("workManagement.picking.btnRefresh")}
        </Button>
        <Button
          variant="outlined"
          onClick={() => onPrintPickListClick()}
          disabled={!buttons.printPickRpt}
        >
          {t("workManagement.picking.btnPrintPickRpt")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => onPrintShipListClick()}
          disabled={!buttons.printShipList}
        >
          {t("workManagement.picking.btnPrintShipList")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => onPrintTransportLabelsClick()}
          disabled={!buttons.printTransportLabels}
        >
          {t("workManagement.picking.btnPrintTransportLabels")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => onClickCancelReport()}
          disabled={!buttons.cancelBatch}
        >
          {t("workManagement.picking.btnCancelBatch")}
        </Button>

        <Button
          variant="outlined"
          onClick={() => onSplitListButtonClick()}
          disabled={!buttons.splitList}
        >
          {t("workManagement.picking.btnSplitList")}
        </Button>
      </Stack>
    );
  };
  return (
    <>
      {loading && <FullCircularLoader />}

      <PickigActionModals
        openPrintModal={openPrintModal}
        setOpenPrintModal={setOpenPrintModal}
        insertList={insertList}
        fastPickList={fastPickList}
        validTasks={validTasks}
        selectedSort={selectedSort}
        setselectedSort={setselectedSort}
        invalidForCancelReportMsg={invalidForCancelReportMsg}
        onHandleNoFromCancelReportModal={onHandleNoFromCancelReportModal}
        onHandleYesFromCancelReportModal={onHandleYesFromCancelReportModal}
        setSelectedInsertSize={setSelectedInsertSize}
        selectedInsertSize={selectedInsertSize}
        insertPerList={insertPerList}
        setInsertPerList={setInsertPerList}
        isByVault={isByVault}
        setIsByVault={setIsByVault}
        selectedFastPick={selectedFastPick}
        setSelectedFastPick={setSelectedFastPick}
        selectedPick={checkedTableData[selectedRow]}
        onClickYesFromSplitList={onClickYesFromSplitList}
        showCombinedList={showCombinedList}
        bIncludeNotInInventory={bIncludeNotInInventory}
        splitLoading={splitLoading}
        cancelBatchMessage={cancelBatchMessage}
        onCLickYesFromInvalidCancelReport={onCLickYesFromInvalidCancelReport}
        cancelLoading={cancelLoading}
        setIsDownloadPopupVisible={setIsDownloadPopupVisible}
        combinedList={combinedList}
        setCombinedList={setCombinedList}
        onPrintPickReportButtonClick={onPrintPickReportButtonClick}
      />
      {throwError && (
        <Box my={spacing.gap}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label={t("common.close")}
                color="inherit"
                size="small"
                onClick={() => {
                  setThrowError(false);
                  setThrowErrorMessage(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {throwErrorMessage}
          </Alert>
        </Box>
      )}
      <br />
      <Grid container spacing={2} alignItems="">
        <Grid item md={6} lg={3}>
          <FormControl fullWidth>
            <InputLabel id="printerNameLabel">
              {t("workManagement.picking.filter")}
            </InputLabel>
            <Select
              label={"filter"}
              id={"filterId"}
              name={"filterlabel"}
              value={pickingFilter}
              onChange={handleFilter}
            >
              {pickingFilterOptions?.map((obj) => (
                <MenuItem value={obj?.value} key={obj?.value}>
                  {obj?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3}>
          <FormControl fullWidth>
            {pickingFilter === pickingOptioncompletedBatches && !selectByRun ? (
              <TextField
                type="number"
                value={pickingFilterData.serviceDays}
                label={t("workManagement.picking.withinLastDays")}
                onChange={handleServiceDays}
              />
            ) : (
              <DatePickerField
                id="serviceDateId"
                name="serviceDate"
                minDate={minDate}
                maxDate={maxDate}
                locale={localeByBranch}
                value={pickingFilterData?.serviceDate}
                onChange={(val) => handleFilterServiceDate(val)}
                label={t("branchOutagePickList.date")}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item md={6} lg={3} sx={WorkManagementStyles.selectSx}>
          <FormControlLabel
            sx={WorkManagementStyles.formControllabelSx}
            control={
              <Checkbox
                checked={selectByRun}
                onChange={(e) => handleSelectByRun(e)}
                color="primary"
              />
            }
            label={t("workManagement.picking.selectByRun")}
          />
        </Grid>
        <Grid item md={6} lg={3}>
          <FormControl fullWidth variant="outlined">
            {!selectByRun ? (
              <CustomersField
                options={[
                  {
                    label: t("common.all"),
                    value: ""
                  },
                  ...customers
                ]}
                value={pickingFilterData?.customerLabel}
                handleOnChange={handleFilterCustomer}
              />
            ) : (
              <>
                <InputLabel id="printerNameLabel">
                  {t("workManagement.picking.run")}
                </InputLabel>
                <Select
                  label={"run"}
                  id={"runId"}
                  name={"runlabel"}
                  value={pickingFilterData.runId}
                  onChange={handleFilterRun}
                >
                  {runData?.map((obj) => (
                    <MenuItem value={obj?.run_id} key={obj?.run_id}>
                      {obj?.run_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <Stack direction="row" gap={spacing.gap}>
        <Button id="newBtnfilter" variant="outlined" onClick={() => clear()}>
          {t("common.clear")}
        </Button>
        <Button
          id="saveBtnfilter"
          variant="contained"
          type="submit"
          disabled={findBtnStatus}
          onClick={() => fetchFindFilter()}
        >
          {t("common.find")}
        </Button>
      </Stack>
      <br />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Card sx={WorkManagementStyles.treeCard}>
              <CardContent sx={WorkManagementStyles.treeCardContent}>
                {showTree && (
                  <PickingTree
                    localeByBranch={localeByBranch}
                    customerList={customerList}
                    fetchSecondLevel={fetchSecondLevel}
                    pickingList={pickingList}
                    pickingListLoaders={pickingListLoaders}
                    loadTablePicking={loadTablePicking}
                    setChecked={setChecked}
                    checked={checked}
                    setExpanded={setExpanded}
                    expanded={expanded}
                    pickingFilter={pickingFilter}
                    selectByRun={selectByRun}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={8}>
            <WorkManagementDataTable
              columns={columns}
              title={tableTitleSection}
              tableData={checkedTableData}
              customToolbar={customToolbar}
              customOptions={tableOptions}
            />
          </Grid>
        </Grid>
      </Grid>
      {serviceDaysModal && (
        <CommonModal
          open={serviceDaysModal}
          title={t("workManagement.picking.serviceModalTitle")}
          body={
            <>
              <Typography>{serviceDaysModalMsg}</Typography>
            </>
          }
          buttons={
            <>
              <Button
                variant="contained"
                onClick={() => setServiceDaysModal(false)}
              >
                {t("common.yes")}
              </Button>
            </>
          }
        />
      )}
      {isModalOpen && (
        <PickingUpdateModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
          selectedRow={selectedRow}
          fetchSecondLevel={fetchSecondLevel}
        />
      )}
      <DownloadReportModal
        isVisible={isDownloadPopupVisible}
        setIsVisible={setIsDownloadPopupVisible}
        reportName={reportName}
        printRequestBody={printRequestBody}
        currentBranch={currentBranch}
        onClickRefresh={onClickRefresh}
        setIsLoading={setLoading}
        throwError={throwError}
        setThrowError={setThrowError}
        throwErrorMessage={throwErrorMessage}
        setThrowErrorMessage={setThrowErrorMessage}
      />
      <ConfirmPopup
        message={printWarningMsg}
        modalPopupOpen={printWarningMsg}
        handleYes={onClickYesPrintWarning}
        handleNo={() => setPrintWarningMsg(null)}
        showCancel={false}
        showNo={true}
      />
    </>
  );
};
export default Picking;
