import { getDocs } from "@firebase/firestore";
import { callToCloudFunction } from "services/api/apiRequests";
import {
  api_results_collection_resultset0,
  api_results_collection_resultset1
} from "services/firebase";
import { getDateFromUTCFormat } from "utils/helpers";

/**
 * @module rescheduleService reschedule service detail feature for specific customer
 */
export const rescheduleService = {
  /**
   * Get customer Location Info of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.customerId id of the selected customerId
   * @param {string} props.exchangeLocationTypeCode id of the selected requestTypeId
   * @param {string} props.serviceFrequencyFlag id of the selected serviceFrequencyFlag
   * @returns
   */

  getCustLocationInfo: async ({
    mainDistrictId,
    customerId,
    exchangeLocationTypeCode,
    serviceFrequencyFlag
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      customer_id: customerId,
      exchange_location_type_code: exchangeLocationTypeCode,
      service_frequency_flag: serviceFrequencyFlag
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getserviceauthcomments`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Get all Schedule Date of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.customerId id of the selected customerId
   * @param {string} props.fromDate id of the selected fromDate
   * @param {string} props.toDate id of the selected toDate
   * @param {string} props.rescheduleFlag id of the selected rescheduleFlag
   * @param {string} props.addonCutoffFlag id of the selected addonCutoffFlag
   * @returns
   */

  getScheduleDate: async ({
    mainDistrictId,
    customerId,
    fromDate,
    toDate,
    rescheduleFlag,
    addonCutoffFlag
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      customer_id: customerId,
      from_date: fromDate,
      to_date: toDate,
      reschedule_flag: rescheduleFlag,
      addon_cutoff_flag: addonCutoffFlag
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/returnrequestidandscheduleddateforcustomer`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;
    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));

    // Convert service_date strings to Date objects for proper comparison
    response.forEach((item) => {
      //We are getting day,month,year in localized format
      item.service_date = getDateFromUTCFormat(item.service_date, {
        asObject: true
      }).toDate();
    });

    // Sort the array by service_date in ascending order
    response.sort((a, b) => a.service_date - b.service_date);

    // return the results
    return response;
  },
  /**
   * Get Request Info of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.requestId id of the selected requestId
   * @returns
   */

  getRequestInfo: async ({ mainDistrictId, requestId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      request_id: requestId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getsinglerequestdata`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Get Run Info of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.requestId id of the selected requestId
   * @returns
   */

  getRunInfo: async ({ mainDistrictId, requestId }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      request_id: requestId
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getserviceinfoforrequestonrun`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Get Route Info of the customer
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.customerId id of the selected customerId
   * @param {string} props.rescheduleDate id of the selected rescheduleDate
   * @returns
   */

  getRouteInfo: async ({ mainDistrictId, customerId, rescheduleDate }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      customer_id: customerId,
      reschedule_date: rescheduleDate
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/geteligiblecustomerservicelocations`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Get all routes
   *
   * @param {object} props
   * @param {string} props.mainDistrictId id of the selected district
   * @param {string} props.dayCodes day codes
   * @returns
   */
  getCslInfo: async ({ mainDistrictId, customerId, rescheduleDate }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      customer_id: customerId,
      reschedule_date: rescheduleDate
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/geteligiblecsl`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;
    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   * Assign District Transports to a customer
   * @param {object} props
   * @param {object} props.mainDistrictId main District Id
   * @param {string} props.requestId id of the selected district
   * @param {string} props.employeeId employee Id
   * @param {string} props.sfAssignCode sf Assign Code
   * @param {string} props.sfRemoveCode sf Remove Code
   * @returns
   *
   */
  assignTransportsToCustomer: async (obj) => {
    var requestBody = JSON.stringify({
      main_district_id: obj.mainDistrictId,
      request_id: obj.requestId,
      employee_id: obj.employeeId,
      SF_assign_code: obj.sfAssignCode,
      SF_remove_code: obj.sfRemoveCode,
      request_transport: obj.requestTransport
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/assigndistricttransportstocustomer`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));
    // return the results
    return response;
  },
  /**
   *
   *
   * RescheduleRequests
   * @param {object} props
   * @param {string} props.mainDistrictId Main District Id
   * @param {string} props.cslCustomerId csl Customer Id
   * @param {string} props.oldServiceDate old Service Date
   * @param {string} props.newServiceDate new Service Date
   * @param {string} props.personnelId Personnel Id
   * @param {string} props.employeeId Employee Id
   * @param {string} props.holidayFlag Holiday Flag
   * @param {string} props.cslNewRcsId cslNewRcsId
   * @param {string} props.cslNewRequestId cslNewRequestId
   * @param {string} props.lastModUser Last Modified User
   * @param {string} props.rescheduledToText Rescheduled To Text
   * @param {string} props.rescheduledFromText Rescheduled From Text
   * @returns
   *
   */
  rescheduleRequests: async (obj) => {
    var requestBody = JSON.stringify({
      main_district_id: obj.mainDistrictId,
      csl_customer_id: obj.cslCustomerId,
      old_service_date: obj.oldServiceDate,
      new_service_date: obj.newServiceDate,
      personnel_id: obj.personnelId,
      employee_id: obj.employeeId,
      holiday_flag: obj.holidayFlag,
      csl_new_rcs_id: obj.cslNewRcsId,
      csl_new_request_id: obj.cslNewRequestId,
      last_mod_user: obj.lastModUser,
      rescheduled_to_text: obj.rescheduledToText,
      rescheduled_from_text: obj.rescheduledFromText
    });
    let response = [];
    // send the parameters to the CF and wait for the response
    const cfResponse = await callToCloudFunction(
      requestBody,
      `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/reschedulerequests`
    ).then((resp) => resp.data);
    const docId = cfResponse.docId;

    // fetch the document's resultset which CF returns
    const collectionSnap = await getDocs(
      api_results_collection_resultset0(docId)
    );
    // //loop through the snapshot and add it to a variable
    collectionSnap.forEach((doc) => response.push(doc.data()));

    if (response.error === "25390") {
      const response = [];
      // fetch the document's resultset which CF returns
      const collectionSnap = await getDocs(
        api_results_collection_resultset1(docId)
      );
      collectionSnap.forEach((doc) => response.push(doc.data()));
      return response;
    }

    return response;
  }
};
