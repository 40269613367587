import { routeSlice } from "./Core/routeSlice";
import { authSlice } from "./authSlice";
import { personnelSlice } from "./authorization/personnelSlice";
import { openMediaResearchSlice } from "./open-media-research/openMediaResearchSlice";
import { destructionDetailDeliverySlice } from "./request-module/destructionDetailDeliverySlice";
import { mediaRequestDetailSlice } from "./request-module/mediaRequestDetailSlice";

/*
 * Actions
 */
export const {
  setAuthInfo,
  removeAuthInfo,
  updateBranch,
  setIRMEmployeeInfo,
  setIRMEmployeeGetError,
  logoutSuccess,
  setAssignedBranches,
  setCurrentBranch,
  setRoleAccess,
  setLoggedUserData,
  setTimeZoneOffset,
  setBranchLocale,
  setError,
  setLocations
} = authSlice.actions;

export const {
  detailDeliveryRequestHeaderObj,
  detailDeliveryRequestSaveFetch,
  deliveryInstructionCommentFetch
} = destructionDetailDeliverySlice.actions;

// media request detail actions
export const {
  resetMediaRequestDetail,
  setClearRequest,
  setRefetch,
  displayExitModal,
  cancelExitProcedure,
  cancelSaveProcedure,
  setErrorMessage,
  setInfoMessage,
  removeErrorMessage,
  removeInfoMessage,
  setActiveTab,
  setNextTab,
  setLockedTab,
  setActionId,
  setOpenMedias,
  addOpenMedia,
  addOpenMedias,
  addOpenMediaSelectedFromPossibleMatches,
  removeOpenMedia,
  setContainers,
  addContainer,
  setTransports,
  assignTransport,
  setRequestNewItemType,
  setNextRequestNewItemType,
  setContainerLogicalVaults,
  setTransportLogicalVaults,
  setContainerMediaTypes,
  setTransportMediaTypes,
  setIssueContainersOrTransports,
  addIssueContainerOrTransport,
  updateIssueContainerOrTransport,
  removeIssueContainerOrTransport,
  setBillForInsertsFlag,
  setExtraInserts,
  addExtraInsert,
  updateExtraInsert,
  removeExtraInsert,
  setExtraItems,
  setIssueContainersOrTransportsAndInserts,
  setDeletableContainers,
  addDeletableContainer,
  removeDeletableContainer,
  setOtherComment
} = mediaRequestDetailSlice.actions;

// route actions
export const {
  resetRouteState,
  setHaveChanges,
  setShowConfirmationModal,
  cancelConfirmation
} = routeSlice.actions;

// personnel actions
export const { setAuthenticationState } = personnelSlice.actions;

// open media research
export const { saveBeforeNavigate, resetPersistedData } =
  openMediaResearchSlice.actions;

/*
 * Selectors
 */
export const selectAuth = (state) => state.auth;

export const selectRequestDetailDelivery = (state) =>
  state.destructionDetailDeliverySlice;

export const selectMediaRequest = (state) => state.mediaRequestDetail; // media request detail selector

export const selectRoute = (state) => state.route; // route selector

export const selectPersonnel = (state) => state.personnel; // personnel selector

export const openMediaResearchPersit = (state) => state.openMediResearch; // open media reserch selector

export const selectContainerProcessingResearch = (state) =>
  state.containerProcessingResearch; // container processing research selector

export const getLocale = (state) => {
  return {
    localeByBranch: state.auth.localeByBranch,
    isLoaded: state.auth.localeByBranchLoaded
  };
};

export const getTimeZoneOffset = (state) => {
  return {
    branchTimeZoneOffset: state.auth.timeZoneOffset,
    isOffSetLoaded: state.auth.isTimeZoneOffsetLoaded
  };
};

export const getRoleAccess = (state) => {
  return state.auth?.roleAccess || [];
};
