import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "store/slices";
import { DEFAULT_LOCALE } from "utils/constants";

// This hook loads the dayjs locale globally instead of loading in every page
const useDateLocalize = () => {
  const { localeByBranch } = useSelector(selectAuth);

  useEffect(() => {
    const getDayJsLocale = async () => {
      if (localeByBranch) {
        try {
          await import(`dayjs/locale/${localeByBranch}.js`);
          dayjs.locale(localeByBranch);
        } catch (error) {
          await import(`dayjs/locale/${DEFAULT_LOCALE}.js`);
          dayjs.locale(DEFAULT_LOCALE);
        }
      }
    };
    getDayJsLocale();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeByBranch]);
};

export default useDateLocalize;
