export const systemRequests = {
  request: "Request",
  complaint: "Complaint",
  compliment: "Compliment",
  urgent: "Urgent"
};

export const sbasEmail = {
  name: "SBAS",
  email: "SBAS@ironmountain.com"
};
