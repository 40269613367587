import { Box, Container } from "@mui/material";
import TabShortcuts from "components/dashboard/TabShortcuts";
import { useDateLocalize } from "hooks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getLocale, getTimeZoneOffset } from "store/slices";
import LAYOUT_STYLES from "./MainLayout.Styles";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import TopBar from "./top-bar/TopBar";

function Layout({ children }) {
  useDateLocalize();
  const { isLoaded: isLocaleLoaded } = useSelector(getLocale);
  const location = useLocation();
  const { isOffSetLoaded } = useSelector(getTimeZoneOffset);
  // Check if the current route is the home page ("/")
  const isHomePage = location.pathname === "/";
  const isInitialValuesLoaded = isOffSetLoaded && isLocaleLoaded;
  return (
    <Box sx={LAYOUT_STYLES.BOX_MAIN}>
      <Header />

      <Container
        maxWidth="xl"
        component="main"
        sx={LAYOUT_STYLES.CONTAINER_MAIN}
      >
        {isHomePage ? <TabShortcuts /> : <TopBar />}
        {isInitialValuesLoaded && children}
      </Container>
      <Footer />
    </Box>
  );
}

export default Layout;
