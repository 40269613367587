import {
  Autocomplete,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { ReactComponent as ArrowDown } from "assets/images/ArrowDown.svg";
import { ReactComponent as CloseIcon } from "assets/images/CloseIcon.svg";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthFormValues } from "store/slices/container-processing";
import {
  clearError,
  updateFormValues
} from "store/slices/container-processing/containerAuditDetails";
import {
  DEFAULT_LOCALE,
  dateFormatWithTime,
  numberConstants
} from "utils/constants";
import {
  checkAuditStatus,
  checkFilterOptions
} from "utils/constants/container-processing/ContainerAuditConstants";
import { ContainerAuditStyles } from "./ContainerAuditDetailStyles";
import LocationForm from "./LocationForm";
const ContainerAuditForm = ({
  values,
  requestedListBy,
  auditTypeList,
  customers,
  setLoading,
  currentBranch,
  setThrowError,
  setThrowErrorMessage,
  handleReSetAudit,
  auditDetailForm,
  isViewMode,
  viewData,
  localeByBranch,
  allAuditData,
  handleComment,
  handleGetAuditType,
  hasPermission,
  maxDate,
  containerAuditId,
  setShowAuthenticationSection,
  viewMode,
  setIsSaved,
  initialAuthValues
}) => {
  const today = dayjs();
  const auditStatus = viewData?.audit_status;

  const [formattedComments, setFormattedComments] = useState([]);
  const { formValues } = useSelector((state) => state.containerAuditDetails);
  const { isAuthenticated } = useSelector(
    (state) => state.containerAuditSearch
  );
  const dispatch = useDispatch();

  const clearAuthForm = () => {
    if (!isAuthenticated) {
      dispatch(updateAuthFormValues(initialAuthValues));
    }
  };
  const handleInputChange = (name, value) => {
    setIsSaved(false);
    const updates = {
      ...formValues,
      [name]: value
    };
    dispatch(updateFormValues(updates));
  };
  const handleReqChange = (newValue) => {
    setIsSaved(false);
    handleGetAuditType(newValue?.value);
    const updatedValues = {
      ...formValues,
      requestedBy: newValue,
      auditType: ""
    };
    dispatch(updateFormValues(updatedValues));
    if (newValue?.value === checkFilterOptions.Customer) {
      setShowAuthenticationSection(true);
    }
    clearAuthForm();
  };

  const handleAuditTypeChange = (newValue) => {
    setIsSaved(false);
    const updatedValues = {
      ...formValues,
      auditType: newValue
    };
    if (newValue.value === numberConstants.four) {
      updatedValues.customer = "";
    }
    dispatch(updateFormValues(updatedValues));
    if (formValues?.requestedBy?.value === checkFilterOptions.Customer) {
      setShowAuthenticationSection(true);
    }
    dispatch(clearError());
    clearAuthForm();
  };
  const onCustomerChange = (event, value) => {
    setIsSaved(false);
    const updatedValues = {
      auditId: "",
      customer: value
    };
    dispatch(updateFormValues(updatedValues));
    if (formValues?.requestedBy?.value === checkFilterOptions.Customer) {
      setShowAuthenticationSection(true);
    }
    clearAuthForm();
  };
  const isFieldDisabled = () => {
    if (!hasPermission) {
      return true;
    }
    if (!auditStatus) {
      return false;
    }
    return auditStatus !== checkAuditStatus?.scheduled;
  };
  const formatComments = async () => {
    if (allAuditData[1]?.length) {
      await import(`dayjs/locale/${localeByBranch}.js`);
      const comments = await Promise.all(
        allAuditData[1].map(async (item) => {
          const formattedDate = dayjs
            .utc(item?.comment_date_ltz)
            .locale(localeByBranch || DEFAULT_LOCALE)
            .format(dateFormatWithTime);

          return {
            date: formattedDate,
            employeeName: item?.employee_name,
            comment: item?.comment
          };
        })
      );

      setFormattedComments(comments);
    } else {
      setFormattedComments([]);
    }
  };
  useEffect(() => {
    formatComments();
    // eslint-disable-next-line
  }, [allAuditData, localeByBranch]);
  return (
    <Stack>
      <Grid container spacing={2}>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="requested-by"
            disablePortal
            name={t("containerAuditDetails.requestedBy")}
            disabled={containerAuditId || viewMode}
            options={requestedListBy}
            size="medium"
            value={formValues?.requestedBy}
            onChange={(event, newValue) => {
              handleReqChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("containerAuditDetails.requestedBy")}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <Autocomplete
            componentsProps={{
              clearIndicator: ArrowDown
            }}
            popupIcon={<ArrowDown />}
            clearIcon={<CloseIcon />}
            id="audit-type"
            name={t("containerAuditDetails.auditType")}
            disabled={!formValues?.requestedBy || containerAuditId || viewMode}
            options={auditTypeList}
            size="medium"
            value={formValues?.auditType}
            onChange={(event, newValue) => {
              handleAuditTypeChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("containerAuditDetails.auditType")}
                required
              />
            )}
          />
        </Grid>
        {formValues?.auditType && (
          <>
            <Grid item xs={4} sm={6} md={4} lg={3}>
              <FormControl fullWidth>
                <DatePickerField
                  id="due-date"
                  name="dueDate"
                  label={t("containerAuditSearch.dueDate") + " *"}
                  value={formValues.dueDate || null}
                  minDate={today}
                  maxDate={maxDate}
                  onChange={(value) => {
                    handleInputChange("dueDate", value);
                  }}
                  disabled={isFieldDisabled()}
                  required
                  error={false}
                />
              </FormControl>
            </Grid>
            {!(
              formValues?.auditType.value === numberConstants.four ||
              containerAuditId
            ) && (
              <Grid item xs={3}>
                <CustomersField
                  options={customers}
                  value={formValues.customer}
                  handleOnChange={onCustomerChange}
                  label={t("containerAuditSearch.customer")}
                  required
                  disabled={viewMode}
                />
              </Grid>
            )}

            <Grid item xs={6} sm={12} md={6} lg={5}>
              <FormControl fullWidth>
                <TextField
                  labelId="comments"
                  label={t("containerAuditDetails.comments")}
                  value={formValues?.comment}
                  onChange={(e) => handleInputChange("comment", e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  name="comments"
                  id="comments"
                  disabled={auditStatus === checkAuditStatus?.completed}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={12} md={6} lg={5}>
              <Typography style={ContainerAuditStyles.commentSectionText}>
                {t("containerAuditDetails.pastComments")}
              </Typography>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={ContainerAuditStyles.commentSection}
              >
                {formattedComments.length
                  ? formattedComments.map((comment, index) => (
                      <Typography key={index} marginBottom={2}>
                        {comment.date} {comment.employeeName} -{" "}
                        {comment.comment}
                      </Typography>
                    ))
                  : ""}
              </Grid>
            </Grid>
          </>
        )}
        {formValues?.auditType?.value === numberConstants.four && (
          <LocationForm
            customers={customers}
            setLoading={setLoading}
            currentBranch={currentBranch}
            setThrowError={setThrowError}
            setThrowErrorMessage={setThrowErrorMessage}
            auditDetailForm={auditDetailForm}
            isViewMode={isViewMode}
            viewData={viewData}
            localeByBranch={localeByBranch}
            allAuditData={allAuditData}
            handleComment={handleComment}
            viewMode={isFieldDisabled()}
            containerAuditId={containerAuditId}
            setIsSaved={setIsSaved}
            clearAuthForm={clearAuthForm}
          />
        )}
      </Grid>
    </Stack>
  );
};

export default ContainerAuditForm;
