import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { DatePickerField } from "components/core";
import { CustomersField } from "components/core/customer-field/CustomersField";
import MultiSelectCheckBox from "components/core/multi-select-checkbox/MultiSelectCheckBox";
import dayjs from "dayjs";
import { t } from "i18next";
import requestType from "mocks/v2/request_l1_sp_mockdata.json";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DISCREPANCY_MANAGEMENT,
  OPERATIONS_MODULE_BASE_PATH
} from "routing/Paths";
import {
  useGetAllCustomersQuery,
  useGetPersonnelAuthQuery
} from "services/api";
import { CF_URLS } from "services/api/endpoints";
import { getDistricts, getMessageFromCode } from "services/api/query";
import { selectAuth } from "store/slices";
import spacing from "styles/spacing";
import {
  ALL_SELECTION,
  capitalAll,
  container,
  dateFormatWithoutTime,
  dateFormatWithTime,
  dayMonthYearTimeFormat,
  DISTRICT_ID_GET_ALL_LOCATIONS,
  ERROR_TYPES,
  errorMsgs,
  globalAttributeTypeIdMaxRows,
  messageCode,
  N_CHECKER,
  numberConstants,
  openMedia,
  SYSTEM_ID_SecureBaseWeb,
  VALUE_ALL,
  VALUE_EMPTY_STRING,
  Y_CHECKER,
  yearMonthDayFormat
} from "utils/constants";
import { fileTypes } from "utils/constants/container-processing/Research";
import { fileSectionTypeIds } from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import {
  ccExchangeStatusId,
  ccExchangeStatusId_9,
  ccSystemFunctionId,
  ccSystemFunctionId_11,
  detectedInType,
  discrepancyDetectedMostFrequently,
  discrepancyMediaList,
  formDataValue,
  number_50,
  omExchangeStatusIdList,
  omExchangeStatusIdList_7080,
  omSystemFunctionId,
  reg,
  reportType,
  severityError,
  severityWarning,
  systemFunction,
  systemFunctionTitle
} from "utils/constants/reports-module/VaultStatusReport";
import {
  findErrorMessage,
  getGlobalAttributeValue,
  getOptions,
  getResponseData,
  removeDuplicates
} from "utils/helpers";
import ExceptionDetails from "./ExceptionDetails";
import { DetailsStyles } from "./Styles";
import VaultTables from "./VaultTables";

const today = dayjs().subtract(1, "year");
//max date state
const maxDate = dayjs();

const VaultStatusReportForm = ({
  setLoading,
  setThrowErrorMessage,
  handlePrint,
  setShowTitle,
  showTitle,
  setSeverity,
  setReportData,
  isLoading
}) => {
  const { isAuthenticated, discrepancyAuthFormValues } = useSelector(
    (state) => state.vaultStatusReport
  );
  const navigate = useNavigate();
  const { localeByBranch, currentBranch } = useSelector(selectAuth);
  const { data: allCustomers = [] } = useGetAllCustomersQuery({
    mainDistrictId: currentBranch?.value
  });
  const { data: personnelAuth = [] } = useGetPersonnelAuthQuery({
    personnelId: discrepancyAuthFormValues?.personnel?.value
  });
  const [customerList, setCustomerList] = useState([]);
  const [personnelAuthList, setPersonnelAuthList] = useState([]);
  const [omExceptionsDetails, setOmExceptionsDetails] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [isTemLoading, setIsTemLoading] = useState(false);
  const [formData, setFormData] = useState({
    ...formDataValue
  });
  const [disableSection, setDisableSection] = useState({
    media: false,
    detectedIn: false,
    discrepancyType: false,
    isCheck: false
  });
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonPrintDisabled, setButtonPrintDisabled] = useState(true);

  const [detectedTypesOptions, setDetectedTypesOptions] = useState([
    ALL_SELECTION
  ]);
  const [discrepancyTypeList, setDiscrepancyTypeList] = useState([
    ALL_SELECTION
  ]);
  const [accountSummeryList, setAccountSummeryList] = useState([]);
  const [oMDiscrepancySummaryList, setOMDiscrepancySummaryList] = useState([]);
  const [oMDiscrepancyOtherExList, setOMDiscrepancyOtherExList] = useState([]);
  const [containerDiscrepancySummaryList, setContainerDiscrepancySummaryList] =
    useState([]);
  const [customerfrequentlyList, setCustomerfrequentlyList] = useState([]);
  const [dropDownList, setDropDownList] = useState({
    discrepancy: { all: [], container: [], openMedia: [] },
    detectedIn: { all: [], container: [], openMedia: [] }
  });
  const [accordionShow, setAccordionShow] = useState({
    accountSummery: true,
    oMDiscrepancySummary: true,
    oMOtherException: true,
    contianer: true,
    customerfrequently: false
  });
  const [customerIdList, setCustomerIdList] = useState("");
  useEffect(() => {
    getDiscrepancyTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (allCustomers?.length) {
      let data = allCustomers.map((item) => {
        return {
          ...item,
          label: item?.label + " " + currentBranch.name
        };
      });
      setCustomerList([...data]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCustomers?.length]);

  useEffect(() => {
    if (isAuthenticated) {
      handleErrorMessage();
    } else {
      handleClear();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && isTemLoading) {
      setLoading(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const handleErrorMessage = async () => {
    setLoading(true);
    let branchList = await getDistricts();
    setFormData({
      ...formData,
      reportType: t("reports.vaultStatusReport.individualCustomerVSR"),
      customer: ALL_SELECTION?.value
    });
    setSeverity(severityWarning);
    if (isAuthenticated) {
      setThrowErrorMessage(t("reports.vaultStatusReport.errorMessage"));
      let list = [
        {
          label: ALL_SELECTION?.value,
          value: number_50,
          number: number_50
        }
      ];
      JSON.parse(personnelAuth[0]?.customer_list)?.forEach((item) => {
        let currentBranch = branchList.find(
          (a) => a?.district_id === `${item?.district_id}`
        );
        list.push({
          label: item?.name + " " + item?.number + " " + currentBranch?.name,
          value: `${item?.customer_Id}`,
          number: `${item?.number}`
        });
      });
      setPersonnelAuthList(list);
      handleFind(true, list);
    }
  };

  const handleClearCustomerErrors = () => {
    delete formData.errors;
    delete formData.touched;
    setThrowErrorMessage(null);
    setSeverity(severityError);
  };

  const handleCustomerChange = (event, newValue) => {
    // user can clear the field and it will return null
    if (newValue === null) {
      setFormData({ ...formData, customer: VALUE_EMPTY_STRING });
    } else {
      // setting the new value to the customerSelected state
      handleClearCustomerErrors();
      setFormData({ ...formData, customer: newValue });
    }
  };

  const getDiscrepancyTypes = async () => {
    try {
      setIsTemLoading(true);
      setLoading(true);
      const req = JSON.stringify({
        main_district_id: DISTRICT_ID_GET_ALL_LOCATIONS,
        operational_mode_id: numberConstants?.four
      });
      const res = await getResponseData(
        req,
        CF_URLS.discrepancyManagement.operationsservicereference,
        11
      );
      let data = res.data;
      if (data[0] && data[0][0]?.error) {
        setIsTemLoading(false);
        setLoading(false);
        return;
      } else {
        const uniqueData = [
          removeDuplicates(data[1], "formattedname"),
          removeDuplicates(data[2], "formattedname"),
          removeDuplicates(data[3], "formattedname"),
          removeDuplicates(data[4], "formattedname")
        ];
        const uniqueDataOptions = [
          getOptions(uniqueData[0], "uniqueid", "formattedname"),
          getOptions(uniqueData[1], "uniqueid", "formattedname"),
          getOptions(uniqueData[2], "uniqueid", "formattedname"),
          getOptions(uniqueData[3], "uniqueid", "formattedname")
        ];
        const detectedTypesOptionsAll = [
          ...uniqueDataOptions[0],
          ...uniqueDataOptions[1]
        ];
        setDetectedTypesOptions([ALL_SELECTION, ...detectedTypesOptionsAll]);
        let detectedValues = [ALL_SELECTION, ...detectedTypesOptionsAll].map(
          (option) => option.label
        );
        const joinedDiscrepancyData = [
          ALL_SELECTION,
          ...uniqueDataOptions[2],
          ...uniqueDataOptions[3]
        ];
        setFormData({
          ...formData,
          detectedIn: detectedValues,
          discrepancyType: ALL_SELECTION?.value
        });
        const discrepancyTypesOptions = removeDuplicates(
          joinedDiscrepancyData,
          "label"
        );
        const openMedia = removeDuplicates(uniqueDataOptions[3], "label");
        const container = removeDuplicates(uniqueDataOptions[2], "label");
        setDiscrepancyTypeList([...discrepancyTypesOptions]);
        setDropDownList({
          discrepancy: {
            all: discrepancyTypesOptions,
            container: container,
            openMedia: openMedia
          },
          detectedIn: {
            all: [ALL_SELECTION, ...detectedTypesOptionsAll],
            container: uniqueDataOptions[0],
            openMedia: uniqueDataOptions[1]
          }
        });
      }
      setIsTemLoading(false);
      setLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setSeverity(severityError);
      setIsTemLoading(false);
      setLoading(false);
    }
  };

  const renderValue = (selected) => {
    if (selected.includes(ALL_SELECTION?.value)) {
      return ALL_SELECTION?.value;
    }
    return selected.join(", ");
  };

  const handleDetectedChange = (event, optionValue) => {
    if (
      !event?.target?.value?.includes(ALL_SELECTION?.value) &&
      formData?.detectedIn?.includes(ALL_SELECTION?.value)
    ) {
      setFormData({ ...formData, detectedIn: [] });
    } else if (
      (event?.target?.value?.includes(ALL_SELECTION?.value) &&
        !formData?.detectedIn?.includes(ALL_SELECTION?.value)) ||
      detectedTypesOptions?.length === event?.target?.value?.length
    ) {
      let detectedValues = [ALL_SELECTION, ...detectedTypesOptions].map(
        (option) => option.label
      );
      setFormData({ ...formData, detectedIn: detectedValues });
    } else {
      let filter = event?.target?.value?.filter(
        (item) => item !== ALL_SELECTION?.value
      );
      setFormData({ ...formData, detectedIn: filter });
    }
  };

  const handleFind = async (vsr = false, list) => {
    const { isCheck, fromDate, toDate, media, customer, discrepancyType } =
      formData;
    setButtonDisabled(true);
    if (
      formData.customer !== undefined &&
      formData.customer === "" &&
      formData.reportType ===
        t("reports.vaultStatusReport.individualCustomerVSR") &&
      !vsr
    ) {
      const errorMsg = await getMessageFromCode(
        String(messageCode.requiredField)
      );

      if (errorMsg) {
        const custReqmessage = errorMsg[0]?.descr.replace(
          "|",
          t("common.customer")
        );
        setThrowErrorMessage(custReqmessage);
        setSeverity(severityError);
      }

      formData["errors"] = { customer: true };
      formData["touched"] = { customer: true };
      setFormData({ ...formData });
      return;
    }

    try {
      let result = null,
        fileSectionTypeIdList = [],
        systemFunctionIdList = [];
      setLoading(true);
      let req = JSON.stringify({
        main_district_id: currentBranch?.value,
        number_of_customers: String(formData?.number),
        start_date: dayjs(fromDate).format(yearMonthDayFormat),
        end_date: dayjs(toDate).format(yearMonthDayFormat)
      });
      if (discrepancyDetectedMostFrequently === formData?.reportType || vsr) {
        result = await getResponseData(
          req,
          CF_URLS.vaultStatusReport
            .queryvsrcustomerswithmostfrequentdiscrepancies,
          2
        );
      } else if (formData?.reportType === reportType[1]) {
        detectedTypesOptions?.forEach((item) => {
          let val = item?.value?.split("_");
          if (val[0] === systemFunction) {
            systemFunctionIdList.push(val[1]);
          } else {
            fileSectionTypeIdList.push(val[1]);
          }
        });
        result = await getResponseData(
          JSON.stringify({
            district_id: currentBranch?.value,
            number_of_customers: String(formData?.number),
            start_date: dayjs(fromDate).format(yearMonthDayFormat),
            end_date: dayjs(toDate).format(yearMonthDayFormat),
            target_media_type:
              media === ALL_SELECTION?.value ? "" : media?.toLowerCase(),
            file_section_type_id_list: fileSectionTypeIdList?.join(","),
            system_function_id_list: systemFunctionIdList?.join(","),
            discrepancy_type_code:
              discrepancyType === ALL_SELECTION?.value ? "" : discrepancyType
          }),
          CF_URLS.vaultStatusReport.queryvsrcustomerswithmostdiscrepancies,
          2
        );
      } else if (formData?.reportType === reportType[2]) {
        result = await getResponseData(
          JSON.stringify({
            district_id: currentBranch?.value,
            number_of_customers: String(formData?.number),
            start_date: dayjs(fromDate).format(yearMonthDayFormat),
            end_date: dayjs(toDate).format(yearMonthDayFormat)
          }),
          CF_URLS.vaultStatusReport
            .queryvsrcustomerswithmostduplicatediscrepanciesatdistribution,
          2
        );
      } else if (formData?.reportType === reportType[3]) {
        result = await getResponseData(
          JSON.stringify({
            district_id: currentBranch?.value,
            number_of_customers: String(formData?.number),
            start_date: dayjs(fromDate).format(yearMonthDayFormat),
            end_date: dayjs(toDate).format(yearMonthDayFormat),
            om_system_function_id: omSystemFunctionId,
            om_exchange_status_id_list: omExchangeStatusIdList,
            cc_system_function_id: ccSystemFunctionId,
            cc_exchange_status_id: ccExchangeStatusId
          }),
          CF_URLS.vaultStatusReport.queryvsrcustomerswithmosttransactions,
          2
        );
      } else if (formData?.reportType === reportType[4]) {
        result = await getResponseData(
          JSON.stringify({
            district_id: currentBranch?.value,
            number_of_customers: String(formData?.number),
            start_date: dayjs(fromDate).format(yearMonthDayFormat),
            end_date: dayjs(toDate).format(yearMonthDayFormat),
            om_system_function_id: "",
            om_exchange_status_id_list: omExchangeStatusIdList_7080,
            cc_system_function_id: ccSystemFunctionId_11,
            cc_exchange_status_id: ccExchangeStatusId_9
          }),
          CF_URLS.vaultStatusReport.queryvsrcustomerswithmosttransactions,
          2
        );
      }
      if (result?.data[0]) {
        let data = result?.data[0]?.map((item) => {
          const percentage = (item?.discrepancy_frequency * 100).toFixed(2);
          return {
            ...item,
            discrepancy_frequency: percentage + "%"
          };
        });
        setCustomerfrequentlyList(data);
      }
      let customerIdList = [];
      result?.data[0]?.forEach((item) => {
        customerIdList.push(item?.customer_id);
      });
      setCustomerIdList(customerIdList?.join(","));
      if (vsr || customer?.label === ALL_SELECTION?.value) {
        customerIdList = [];
        let dataList = list?.length ? list : personnelAuthList;
        dataList?.slice(1).forEach((item) => {
          customerIdList.push(item?.value);
        });
      } else if (isAuthenticated) {
        customerIdList = [];
        customerIdList.push(customer?.value);
      }
      let request = {
        customer_id_list: isAuthenticated
          ? customerIdList?.join(",")
          : customer?.value
            ? customer?.value
            : customerIdList?.join(","),
        start_date: dayjs(fromDate).format(yearMonthDayFormat),
        end_date: dayjs(toDate).format(yearMonthDayFormat),
        customer_facing_flag: isAuthenticated ? Y_CHECKER : N_CHECKER,
        target_media_type:
          media === ALL_SELECTION?.value
            ? ""
            : media?.toLowerCase()?.split(" ").join(""),
        file_section_type_id_list: fileSectionTypeIdList?.join(","),
        system_function_id_list: systemFunctionIdList.join(","),
        discrepancy_type_code:
          discrepancyType === ALL_SELECTION?.value ? "" : discrepancyType
      };
      let summary = await getResponseData(
        request,
        CF_URLS.vaultStatusReport.getvsraccountanddiscrepancysummary,
        3
      );
      if (summary?.data) {
        setButtonPrintDisabled(false);
        if (summary?.data[2]) {
          setAccountSummeryList(summary?.data[2]);
        }
        if (summary?.data[0]) {
          let data = summary?.data[0].map((item) => {
            let detected_in = fileSectionTypeIds[item?.detected_in_fst_id];

            return {
              ...item,
              detected_in
            };
          });
          setOMDiscrepancySummaryList(data);
        }
        if (summary?.data[1]) {
          let data = summary?.data[1].map((item) => {
            let detected_in = null;
            if (item?.detected_in_sf_id === numberConstants.one) {
              detected_in = t("discrepancyManagementDetail.picking");
            } else if (item?.detected_in_sf_id === numberConstants.two) {
              detected_in = t("discrepancyManagementDetail.distribution");
            } else if (item?.detected_in_sf_id === numberConstants.four) {
              detected_in = t("discrepancyManagementDetail.inventory");
            } else {
              detected_in =
                detectedInType[systemFunctionTitle + item?.detected_in_sf_id];
            }
            return {
              ...item,
              detected_in
            };
          });
          setContainerDiscrepancySummaryList(data);
        }
      }

      if (isCheck) {
        let omExceptions = await getResponseData(
          JSON.stringify({
            customer_id_list: isAuthenticated
              ? customerIdList?.join(",")
              : customer?.value
                ? customer?.value
                : customerIdList?.join(","),
            start_date: dayjs(fromDate).format(yearMonthDayFormat),
            end_date: dayjs(toDate).format(yearMonthDayFormat),
            customer_facing_flag: isAuthenticated ? Y_CHECKER : N_CHECKER
          }),
          CF_URLS.vaultStatusReport.getvsropenmediaotherexceptions,
          2
        );
        if (omExceptions?.data[0].length !== 0) {
          let data = omExceptions?.data[0]?.map((item) => {
            let detected_in = null;
            if (item?.detected_in_fst_id === numberConstants.one) {
              detected_in = t("discrepancyManagementDetail.picking");
            } else if (item?.detected_in_fst_id === numberConstants.two) {
              detected_in = t("discrepancyManagementDetail.distribution");
            } else if (item?.detected_in_fst_id === numberConstants.four) {
              detected_in = t("discrepancyManagementDetail.inventory");
            } else {
              detected_in =
                detectedInType[systemFunctionTitle + item?.detected_in_fst_id];
            }
            return {
              ...item,
              detected_in
            };
          });
          setOMDiscrepancyOtherExList(data);
          if (data?.length) {
            setButtonDisabled(false);
          } else {
            setButtonDisabled(true);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setSeverity(severityError);
      setLoading(false);
    }
  };

  const handleReportType = async (event) => {
    let obj = {
      ...accordionShow,
      accountSummery: true,
      oMDiscrepancySummary: true,
      oMOtherException: true,
      contianer: true,
      customerfrequently: false
    };
    if (
      event?.target?.value !==
      t("reports.vaultStatusReport.individualCustomerVSR")
    ) {
      handleClearCustomerErrors();
    }
    if (
      [
        discrepancyDetectedMostFrequently,
        reportType[3],
        reportType[4]
      ].includes(event?.target?.value)
    ) {
      setDisableSection({
        media: true,
        detectedIn: true,
        discrepancyType: true,
        isCheck: false
      });

      setFormData({ ...formDataValue, reportType: event?.target?.value });
    } else if (event?.target?.value === reportType[2]) {
      setDisableSection({
        media: true,
        detectedIn: true,
        discrepancyType: true,
        isCheck: true
      });
      setFormData({
        ...formDataValue,
        reportType: event?.target?.value,
        isCheck: true,
        media: t("discrepancyManagement.openMedia")
      });
      obj = {
        accountSummery: true,
        oMDiscrepancySummary: false,
        oMOtherException: true,
        contianer: false,
        customerfrequently: false
      };
    } else {
      setDisableSection({
        media: false,
        detectedIn: false,
        discrepancyType: false
      });
      setFormData({ ...formDataValue, reportType: event?.target?.value });
    }
    setButtonDisabled(true);
    setCustomerfrequentlyList([]);
    setContainerDiscrepancySummaryList([]);
    setOMDiscrepancySummaryList([]);
    setOMDiscrepancyOtherExList([]);
    setAccountSummeryList([]);
    setAccordionShow({
      ...obj,
      customerfrequently:
        event?.target?.value === discrepancyDetectedMostFrequently
    });
    setButtonPrintDisabled(true);
  };

  const handleClear = () => {
    setFormData({ ...formDataValue });
    setCustomerfrequentlyList([]);
    setContainerDiscrepancySummaryList([]);
    setOMDiscrepancySummaryList([]);
    setOMDiscrepancyOtherExList([]);
    setAccountSummeryList([]);
    setButtonDisabled(true);
    setDisableSection({
      media: false,
      detectedIn: false,
      discrepancyType: false,
      isCheck: false
    });
    setAccordionShow({
      accountSummery: true,
      oMDiscrepancySummary: true,
      oMOtherException: true,
      contianer: true,
      customerfrequently: false
    });
    setButtonPrintDisabled(true);
  };

  const handleOpenMedia = (value) => {
    let detectedValues = null;
    if (value === container) {
      setDiscrepancyTypeList([
        ALL_SELECTION,
        ...(dropDownList?.discrepancy?.container || [])
      ]);
      setDetectedTypesOptions([
        ALL_SELECTION,
        ...(dropDownList?.detectedIn?.container || [])
      ]);
      detectedValues = [
        ALL_SELECTION,
        ...(dropDownList?.detectedIn?.container || [])
      ].map((option) => option.label);
      setAccordionShow({
        accountSummery: true,
        oMDiscrepancySummary: false,
        oMOtherException: true,
        contianer: true,
        customerfrequently: false
      });
    } else if (value === openMedia) {
      setDiscrepancyTypeList([
        ALL_SELECTION,
        ...(dropDownList?.discrepancy?.openMedia || [])
      ]);
      setDetectedTypesOptions([
        ALL_SELECTION,
        ...(dropDownList?.detectedIn?.openMedia || [])
      ]);
      detectedValues = [
        ALL_SELECTION,
        ...(dropDownList?.detectedIn?.openMedia || [])
      ].map((option) => option.label);
      setAccordionShow({
        accountSummery: true,
        oMDiscrepancySummary: true,
        oMOtherException: true,
        contianer: false,
        customerfrequently: false
      });
    } else {
      setDiscrepancyTypeList([...(dropDownList?.discrepancy?.all || [])]);
      setDetectedTypesOptions([...(dropDownList?.detectedIn?.all || [])]);
      detectedValues = [
        ALL_SELECTION,
        ...(dropDownList?.detectedIn?.all || [])
      ].map((option) => option.label);
      setAccordionShow({
        accountSummery: true,
        oMDiscrepancySummary: true,
        oMOtherException: true,
        contianer: true,
        customerfrequently: false
      });
    }
    setFormData({
      ...formData,
      media: value,
      detectedIn: detectedValues,
      discrepancyType: ALL_SELECTION?.value
    });
  };

  const handleDownloadCSV = (data, fileName) => {
    let blob;
    let csv = Object.keys(data[0])?.join(",") + "\n";
    data.forEach((row) => {
      csv += Object.values(row)?.join(",");
      csv += "\n";
    });
    blob = new Blob([csv], { type: fileTypes.csv });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleSwitch = () => {
    setFormData({ ...formData, isCheck: !formData.isCheck });
    setAccordionShow({
      ...accordionShow,
      oMOtherException: !formData.isCheck
    });
    setCustomerfrequentlyList([]);
    setContainerDiscrepancySummaryList([]);
    setOMDiscrepancySummaryList([]);
    setOMDiscrepancyOtherExList([]);
    setAccountSummeryList([]);
    setButtonDisabled(true);
    setButtonPrintDisabled(true);
  };
  const exportExceptionDetail = async () => {
    const { customer, fromDate, toDate } = formData;
    const customerServiceResponse = await getGlobalAttributeValue(
      currentBranch?.value,
      SYSTEM_ID_SecureBaseWeb,
      globalAttributeTypeIdMaxRows
    );
    let exceptionType = [],
      obj = {};
    oMDiscrepancyOtherExList.forEach((item) => {
      exceptionType.push(item?.exception_type);
      obj[item?.detected_in_fst_id] = numberConstants.one;
    });
    try {
      setLoading(true);
      let res = await getResponseData(
        JSON.stringify({
          main_district_id: number_50,
          customer_id_list: customer?.value ? customer?.value : customerIdList,
          file_section_type_id_list: Object.keys(obj).join(","),
          from_date: dayjs(fromDate).format(yearMonthDayFormat),
          to_date: dayjs(toDate).format(yearMonthDayFormat),
          ga_max_num_search_results: customerServiceResponse,
          exception_type_list: exceptionType?.join(",")
        }),
        CF_URLS.vaultStatusReport.exceptiondetails,
        2
      );
      if (res?.data[0][0]?.error) {
        const Msg = await getMessageFromCode(res?.data[0][0]?.error);
        setThrowErrorMessage(
          Msg[0]?.descr?.replace("{0}", customerServiceResponse)
        );
        setSeverity(severityError);
      } else {
        let arr = [];
        res?.data[0]?.forEach((item) => {
          let detected_in = null;
          if (item?.file_section_type_id === numberConstants.one) {
            detected_in = t("discrepancyManagementDetail.picking");
          } else if (item?.file_section_type_id === numberConstants.two) {
            detected_in = t("discrepancyManagementDetail.distribution");
          } else if (item?.file_section_type_id === numberConstants.four) {
            detected_in = t("discrepancyManagementDetail.inventory");
          }
          arr.push({
            [t("reports.vaultStatusReport.customer")]: item?.customer_number,
            [t("reports.vaultStatusReport.detectedIn")]: detected_in,
            [t("reports.vaultStatusReport.exceptionType")]:
              item?.exception_type,
            [t("reports.vaultStatusReport.systemFunction")]: item?.mediaid
              ? "Open Media Distribution Upload"
              : "",
            [t("reports.vaultStatusReport.media")]: item?.mediaid,
            [t("reports.vaultStatusReport.detectedDatetime")]: dayjs
              .utc(item?.detected_datetime)
              .format(dayMonthYearTimeFormat),
            [t("reports.vaultStatusReport.task")]: item?.task_id,
            [t("reports.vaultStatusReport.logicalVaultC")]: item?.logical_vault,
            [t("reports.vaultStatusReport.requestType")]: item?.request_type_id,
            [t("reports.vaultStatusReport.serviceDate")]: dayjs
              .utc(item?.service_date)
              .format(dayMonthYearTimeFormat),
            [t("reports.vaultStatusReport.additionalInformation")]:
              item?.additionalinformation,
            [t("reports.vaultStatusReport.carID")]: item?.carid + ",,"
          });
        });
        handleDownloadCSV(
          arr,
          `OpenMediaExceptionDetail_${dayjs().format("YYYYMMDD")}.csv`
        );
      }

      setLoading(false);
    } catch (e) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setSeverity(severityError);

      setLoading(false);
    }
  };
  const handleOmExceptionDetails = async (obj) => {
    const { fromDate, toDate } = formData;
    const flattenedData = requestType.data?.flat();
    try {
      setLoading(true);
      let res = await getResponseData(
        JSON.stringify({
          main_district_id: currentBranch?.value,
          customer_id: obj?.customer_id,
          file_section_type_id: obj?.detected_in_fst_id,
          exception_type: obj?.exception_type,
          detected_date_from: dayjs(fromDate).format(yearMonthDayFormat),
          detected_date_to: dayjs(toDate).format(yearMonthDayFormat),
          operational_mode_id: numberConstants.four,
          logical_vault_code: obj?.logical_vault_code
        }),
        CF_URLS.vaultStatusReport.exceptiondetailsothervaultstatusreport,
        2
      );
      if (
        res?.data[0][0]?.error !== errorMsgs?.noerror ||
        res?.data[0]?.length
      ) {
        await import(`dayjs/locale/${localeByBranch}.js`);
        let dataFormat = [];
        res?.data[0]?.forEach((item) => {
          let tempObj = {
            ...item
          };
          tempObj["detected_datetime"] = dayjs
            .utc(item?.detected_datetime)
            .locale(localeByBranch)
            .format(dateFormatWithTime);
          tempObj["task_datetime"] =
            obj?.detected_in +
            " " +
            dayjs
              .utc(item?.task_datetime)
              .locale(localeByBranch)
              .format(dateFormatWithoutTime);
          tempObj["system_function"] =
            detectedInType[systemFunctionTitle + item?.system_function_id];
          tempObj["service_date"] = dayjs
            .utc(item?.service_date)
            .locale(localeByBranch)
            .format(dateFormatWithoutTime);

          let data = flattenedData.filter(
            (test) => `${test?.requestTypeId}` === item?.request_type_id
          );
          tempObj["request_type"] = data[0]?.requestType;
          dataFormat.push(tempObj);
        });
        setSelectedCustomer({ ...obj });
        setOmExceptionsDetails([...dataFormat]);
        setShowTitle(true);
      } else {
        let error = res?.data[0][0]?.error || res?.data[1][0]?.error;
        setSeverity(severityError);
        if (Number(error)) {
          const Msg = await getMessageFromCode(error);
          setThrowErrorMessage(Msg[0]?.descr);
        } else {
          setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        }
      }
      setLoading(false);
    } catch (e) {
      setSeverity(severityError);
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setLoading(false);
    }
  };

  const handlePayloadPrint = () => {
    let exceptionType = [],
      obj = {};
    let isMostDuplicate =
      formData?.reportType ===
      t(
        "reports.vaultStatusReport.customersWithMostDuplicateMediaNumberAtDistribution"
      );
    oMDiscrepancyOtherExList.forEach((item) => {
      exceptionType.push(item?.exception_type);
      obj[item?.detected_in_fst_id] = numberConstants.one;
    });
    const systemFunctionIdList = [];
    detectedTypesOptions?.forEach((item) => {
      let val = item?.value?.split("_");
      if (val[0] === systemFunction) {
        systemFunctionIdList?.push(val[1]);
      }
    });
    const {
      customer,
      fromDate,
      number,
      toDate,
      reportType,
      isCheck,
      detectedIn,
      discrepancyType,
      media
    } = formData;

    let payloadObj = {
      main_district_id: currentBranch?.value,
      customer_id: customer?.number,
      detected_in: detectedIn?.includes(VALUE_ALL) ? capitalAll : detectedIn,
      discrepancy_type:
        discrepancyType === VALUE_ALL ? capitalAll : discrepancyType,
      target_media_type:
        media === VALUE_ALL
          ? capitalAll
          : isMostDuplicate
            ? media?.split(" ")?.join("")
            : media,
      top: number,
      report_type: reportType,
      include_other_open_media_exception: isCheck ? Y_CHECKER : N_CHECKER,
      customer_id_list: customer?.value ? customer?.value : customerIdList,
      file_section_type_id_list: isMostDuplicate
        ? numberConstants.two
        : Object.keys(obj).join(","),
      from_date: dayjs(fromDate).format(yearMonthDayFormat),
      to_date: dayjs(toDate).format(yearMonthDayFormat),
      discrepancy_type_code:
        discrepancyType === ALL_SELECTION?.value || isMostDuplicate
          ? ""
          : discrepancyType,
      system_function_id_list: isMostDuplicate
        ? ""
        : systemFunctionIdList?.join(","),
      customer_facing_flag: isAuthenticated ? Y_CHECKER : N_CHECKER,
      region: process.env.REACT_APP_CF_REGION
    };
    setReportData(payloadObj);

    handlePrint();
  };

  const handleBack = () => {
    setSelectedCustomer({});
    setOmExceptionsDetails([]);
    setShowTitle(false);
  };
  const handleDiscrepancySearch = async (data) => {
    let vaultProps = {
      ...data,
      detected_date_from: formData?.fromDate,
      detected_date_to: formData?.toDate
    };
    navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`, {
      state: { vaultProps: vaultProps }
    });
  };
  return (
    <Grid container spacing={2} sx={DetailsStyles.fontColor}>
      {showTitle ? (
        <ExceptionDetails
          handleBack={handleBack}
          data={selectedCustomer}
          tableList={omExceptionsDetails}
        />
      ) : (
        <>
          <Grid item xs={8} sm={12} md={8} lg={6}>
            <FormControl fullWidth disabled={isAuthenticated}>
              <InputLabel id="reportType">
                {t("reports.vaultStatusReport.reportType")}
              </InputLabel>
              <Select
                labelId="reportType"
                value={formData?.reportType}
                label={t("reports.vaultStatusReport.reportType")}
                onChange={(event) => {
                  handleReportType(event);
                }}
              >
                {[...reportType]?.map((item) => (
                  <MenuItem value={item} key={item}>
                    {" "}
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {[
            discrepancyDetectedMostFrequently,
            reportType[1],
            reportType[2],
            reportType[3],
            reportType[4]
          ].includes(formData?.reportType) ? (
            <Grid item xs={4} sm={12} md={4} lg={3}>
              <FormControl fullWidth>
                <TextField
                  labelId="top"
                  label={t("reports.vaultStatusReport.top")}
                  value={formData?.number}
                  onChange={(event) => {
                    setFormData({ ...formData, number: event?.target?.value });
                  }}
                  onKeyPress={(event) => {
                    if (!reg.test(event.key)) {
                      event.preventDefault(); // Prevent non-numeric key presses
                    }
                  }}
                  fullWidth
                  name="review-day"
                  id="review-day"
                  inputProps={{
                    maxLength: numberConstants.three
                  }}
                />
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <CustomersField
                options={isAuthenticated ? personnelAuthList : customerList}
                value={formData?.customer}
                handleOnChange={handleCustomerChange}
                label={t("common.customer")}
                required={true}
                form={formData}
              />
            </Grid>
          )}

          <Grid item xs={4} sm={6} md={4} lg={3}></Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <DatePickerField
                label={t("reports.vaultStatusReport.reportDateFrom")}
                value={formData?.fromDate}
                minDate={today}
                maxDate={maxDate}
                defaultValue={maxDate}
                locale={localeByBranch}
                onChange={(value) => {
                  setFormData({ ...formData, fromDate: value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <DatePickerField
                label={t("reports.vaultStatusReport.reportDateTo")}
                value={formData?.toDate}
                minDate={today}
                maxDate={maxDate}
                defaultValue={maxDate}
                locale={localeByBranch}
                onChange={(value) => {
                  setFormData({ ...formData, toDate: value });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth disabled={disableSection?.media}>
              <InputLabel id="media">
                {t("reports.vaultStatusReport.media")}
              </InputLabel>
              <Select
                labelId="media"
                value={formData?.media}
                label={t("reports.vaultStatusReport.media")}
                onChange={(event) => {
                  handleOpenMedia(event?.target?.value);
                }}
              >
                {[...discrepancyMediaList]?.map((item) => (
                  <MenuItem value={item?.value} key={item?.value}>
                    {" "}
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Typography style={DetailsStyles.subHeading}>
              {t("reports.vaultStatusReport.discrepancySummary")}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <MultiSelectCheckBox
              multiple
              fullWidth
              id="detectedIn"
              labelId="discrepancyManagement-label"
              label={t("reports.vaultStatusReport.detectedIn")}
              name="detectedIn"
              options={detectedTypesOptions}
              disablePortal
              renderValue={renderValue}
              value={formData.detectedIn}
              onChange={(event, newValue) => {
                handleDetectedChange(event, newValue);
              }}
              error={null}
              helperText={null}
              MenuProps={{
                PaperProps: {
                  sx: DetailsStyles.menuStyles
                }
              }}
              disabled={disableSection?.detectedIn}
            />
          </Grid>
          <Grid item xs={4} sm={6} md={4} lg={3}>
            <FormControl fullWidth disabled={disableSection?.discrepancyType}>
              <InputLabel id="discrepancyType">
                {t("reports.vaultStatusReport.discrepancyType")}
              </InputLabel>
              <Select
                labelId="discrepancyType"
                value={formData.discrepancyType}
                label={t("reports.vaultStatusReport.discrepancyType")}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    discrepancyType: event?.target?.value
                  });
                }}
              >
                {[...discrepancyTypeList]?.map((item) => (
                  <MenuItem value={item?.label} key={item?.label}>
                    {" "}
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData?.isCheck}
                  onChange={() => {
                    handleSwitch();
                  }}
                  disabled={disableSection?.isCheck}
                />
              }
              label={t("reports.vaultStatusReport.text")}
              disabled={disableSection?.isCheck}
            />
            <br />
            <Typography marginLeft={5}>
              {t("reports.vaultStatusReport.text1")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={spacing.gap} marginTop={2}>
              <Grid id="clear-button-grid" item xs="auto">
                <Button
                  id="clear-button"
                  variant="outlined"
                  type="button"
                  onClick={handleClear}
                >
                  {t("common.clear")}
                </Button>
              </Grid>
              <Grid id="find-button-grid" item xs="auto">
                <Button
                  id="find-button"
                  variant="contained"
                  type="button"
                  onClick={() => {
                    handleFind();
                  }}
                >
                  {t("common.find")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <VaultTables
            accountSummeryList={accountSummeryList}
            oMDiscrepancySummaryList={oMDiscrepancySummaryList}
            oMDiscrepancyOtherExList={oMDiscrepancyOtherExList}
            containerDiscrepancySummaryList={containerDiscrepancySummaryList}
            customerfrequentlyList={customerfrequentlyList}
            accordionShow={accordionShow}
            handleOmExceptionDetails={handleOmExceptionDetails}
            handleDiscrepancySearch={handleDiscrepancySearch}
          />
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={spacing.gap}>
              <Grid id="clear-button-grid" item xs="auto">
                <Button
                  id="clear-button"
                  variant="outlined"
                  type="button"
                  onClick={() => {
                    exportExceptionDetail();
                  }}
                  disabled={isButtonDisabled}
                >
                  {t("reports.vaultStatusReport.exportExceptionDetail")}
                </Button>
              </Grid>
              <Grid id="find-button-grid" item xs="auto">
                <Button
                  id="find-button"
                  variant="contained"
                  type="button"
                  onClick={() => {
                    handlePayloadPrint();
                  }}
                  disabled={isButtonPrintDisabled}
                >
                  {t("common.print")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default VaultStatusReportForm;
