import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField
} from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CircularLoader,
  CircularLoaderFullPage,
  DatePickerField,
  SelectField
} from "components/core";
import ModalPopup from "components/core/modal-components/ModalPopup";
import { NonEditableField } from "components/shared";
import CommonModal from "components/shared/common-modal/CommonModal";
import dayjs from "dayjs";
import { CF_URLS } from "services/api/endpoints";
import { getExchangeStatusById, getMessageFromCode } from "services/api/query";
import spacing from "styles/spacing";
import {
  ERROR_TYPES,
  EXCHANGE_LOCATION_TYPE_SL,
  MEDIA_PROGRAM_TYPES,
  N_CHECKER,
  VALUE_EMPTY_STRING,
  VALUE_N,
  Y_CHECKER,
  errorMsgs,
  isoDateTimeZ
} from "utils/constants";
import {
  exchangeStatus,
  maximumReturnDate,
  runRulesSystemFunctionId
} from "utils/constants/container-processing/Research";
import {
  findErrorMessage,
  getAuthenticatedUserBranch,
  getDateFromUTCFormat,
  getResponseData
} from "utils/helpers";
import { HighlightScheduledDates, dateFormat } from "./HighlightScheduledDates";
import { containerResearchStyles } from "./ResearchStyle";

// edit container form inital values
const editContainerFormInitialValues = {
  customer: "",
  logicalVault: "",
  mediaType: "",
  customerMediaIdentifier: "",
  indefRetentionFlag: false,
  returnDate: null,
  newStatus: "",
  requestId: 0,
  runId: 0
};

// based on the current date's month start day
const handleFromDate = (value) => {
  return dayjs(value).startOf("month").format(isoDateTimeZ);
};
// based on the current date's month end day
const handleToDate = (value) => {
  return dayjs(value).endOf("month").format(isoDateTimeZ);
};

const getDateObjectFromUTCFormat = (date) => {
  return getDateFromUTCFormat(date, { asObject: true });
};

const EditContainerResearchModal = (props) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "warning"
  });
  const [message10152, setMessage10152] = useState(""); // message 10152 value
  const [message20229, setMessage20229] = useState(""); // message 20229 value
  const [message80117, setMessage80117] = useState(""); // message 80117 value
  const [message20271, setMessage20271] = useState(""); // message 20271 value
  const [message20353, setMessage20353] = useState(""); // message 20353 value
  const [message20375Showed, setMessage20375Showed] = useState(false); // message 20375 already showed state
  const [disableMediaTypeField, setDisableMediaTypeField] = useState(false); // disable media type field
  const [
    disableCustomerMediaIdentifierField,
    setDisableCustomerMediaIdentifierField
  ] = useState(false); // disable CMI field
  const [disableIndefRetentionCheckbox, setDisableIndefRetentionCheckbox] =
    useState(false); // disable Indefinite Retention field
  const [disableReturnDateField, setDisableReturnDateField] = useState(false); // disable return date field
  const [openDatePicker, setOpenDatePicker] = useState(false); // open/close date picker
  const [disableNewStatusField, setDisableNewStatusField] = useState(false); // disable new status field
  const [showMediaTypeChangeConfirmModal, setShowMediaTypeChangeConfirmModal] =
    useState(false); // show/hide media type changes confirmation modal
  const [showDeleteContainerModal, setShowDeleteContainerModal] =
    useState(false); // show/hide delete container modal
  const [containerResearch, setContainerResearch] = useState(null); // editing container's details
  const [scheduledServices, setScheduledServices] = useState([]); // scheduled services list
  const [scheduledServicesLoading, setScheduledServicesLoading] =
    useState(false); // scheduled services data loading
  const [serviceAuthComments, setServiceAuthComments] = useState(null); // customer's service auth comments
  const [containerRunRule, setContainerRunRule] = useState(false); // container's run rule state
  const [mediaTypes, setMediaTypes] = useState([]); // media type list
  const [newExchangeStatuses, setNewExchangeStatuses] = useState([]); // container status list
  const [pageLoading, setPageLoading] = useState(false); // page loading state
  const [returnDateUpdated, setReturnDateUpdated] = useState(false); // help re-render datepicker

  const scheduledServiceDates = useMemo(
    () =>
      scheduledServices?.map((item) =>
        dateFormat(dayjs.utc(item.service_date))
      ),
    [scheduledServices]
  ); // scheduled service dates

  const returnDate = getDateObjectFromUTCFormat(containerResearch?.return_date);
  // check run rule
  const checkRunRule = async (container) => {
    if (container?.run_id !== VALUE_EMPTY_STRING) {
      try {
        const reqBody = JSON.stringify({
          main_district_id: getAuthenticatedUserBranch(),
          system_function_id: runRulesSystemFunctionId,
          current_exchange_status_id: container?.exchange_status_id,
          called_by_sp: VALUE_N
        });
        //check to see if the moContainer is tied to a run - if so, check the run rules
        const response = await getResponseData(
          reqBody,
          CF_URLS.containerProcessing.runPrep.runrule,
          2
        );

        const results = response?.data;
        if (results && results[0]?.length > 0) {
          setContainerRunRule((prevState) => true);
        } else {
          setContainerRunRule((prevState) => false);
        }
      } catch (error) {
        setAlert((prevState) => ({
          show: true,
          message: findErrorMessage(ERROR_TYPES.ISSUE),
          severity: "error"
        }));
      }
    }
  };

  /**
   * @description get exchange status values by id
   *
   * @return ({ label: status_code, value: status_id })
   *
   **/
  const getExchangeStatusValue = async (statusId) => {
    const response = await getExchangeStatusById(statusId);
    if (response && response[0]) {
      const item = response[0];
      return { label: item?.type, value: Number(item?.exchange_status_id) };
    }
    return null;
  };

  /**
   * @name constructExchangeStatus
   * @description This will populate the drop down with all valid new status descriptions.
   * @param {object} container selected container values
   */
  const constructExchangeStatus = async (container) => {
    if (props.enableUpdateNewStatusField) {
      const newStatus = [];

      // these items are always added to the drop down list if the Container is not deleted
      if (Number(container?.exchange_status_id) !== exchangeStatus.deleted) {
        // If container status is Pending Distribution
        if (
          Number(container?.exchange_status_id) ===
          exchangeStatus.pendingDistribution
        ) {
          // Add Delivered
          const delivered = await getExchangeStatusValue(
            exchangeStatus.delivered
          );
          if (delivered) {
            newStatus.push(delivered);
          }

          // If not (transport AND hasMedia)
          if (
            !(
              Number(container?.media_program_type_id) ===
                MEDIA_PROGRAM_TYPES.TRANSPORT.ID &&
              container?.open_media_in_transport === Y_CHECKER
            )
          ) {
            // Add At Iron Mountain
            const atIronMountain = await getExchangeStatusValue(
              exchangeStatus.atImosdp
            );
            if (atIronMountain) {
              newStatus.push(atIronMountain);
            }
          }
        } else {
          // If container status NOT At Iron Mountain
          if (
            Number(container?.exchange_status_id) !== exchangeStatus.atImosdp
          ) {
            // If not (transport AND hasMedia) then
            if (
              !(
                Number(container?.media_program_type_id) ===
                  MEDIA_PROGRAM_TYPES.TRANSPORT.ID &&
                container?.open_media_in_transport === Y_CHECKER
              )
            ) {
              // Add At Iron Mountain
              const atIronMountain = await getExchangeStatusValue(
                exchangeStatus.atImosdp
              );
              if (atIronMountain) {
                newStatus.push(atIronMountain);
              }
            }
          } else if (props.slottingContainerFlag) {
            // pending dist status allowed only if slotting
            // If not (transport AND hasMedia)
            if (
              !(
                Number(container?.media_program_type_id) ===
                  MEDIA_PROGRAM_TYPES.TRANSPORT.ID &&
                container?.open_media_in_transport === Y_CHECKER
              )
            ) {
              // Add Pending Distribution
              const pendingDistribution = await getExchangeStatusValue(
                exchangeStatus.pendingDistribution
              );
              if (pendingDistribution) {
                newStatus.push(pendingDistribution);
              }
            }
          }

          // If container status NOT Delivered
          if (
            Number(container?.exchange_status_id) !== exchangeStatus.delivered
          ) {
            // Add Delivered
            const delivered = await getExchangeStatusValue(
              exchangeStatus.delivered
            );
            if (delivered) {
              newStatus.push(delivered);
            }
          }
          // check to see if the user has permission to delete containers
          // CONTAINER_RESEARCH_DELETE (279)
          if (props.addDeletedStatusOption) {
            // check to see if we can delete the Container
            // If current status is Delivered OR At Iron Mountain
            if (
              Number(container?.exchange_status_id) ===
                exchangeStatus.delivered ||
              Number(container?.exchange_status_id) === exchangeStatus.atImosdp
            ) {
              // cannot delete Transports with Open Media
              // If not (transport AND hasMedia)
              if (
                !(
                  Number(container?.media_program_type_id) ===
                    MEDIA_PROGRAM_TYPES.TRANSPORT.ID &&
                  container?.open_media_in_transport === Y_CHECKER
                )
              ) {
                // Add Deleted
                const deleted = await getExchangeStatusValue(
                  exchangeStatus.deleted
                );
                if (deleted) {
                  newStatus.push(deleted);
                }
              }
            }
          }
        }
      } else {
        // IF container Customer_Owned_Container_Flag = Yes
        if (container?.customer_owned_container_flag === Y_CHECKER) {
          // Add Delivered
          const delivered = await getExchangeStatusValue(
            exchangeStatus.delivered
          );
          if (delivered) {
            newStatus.push(delivered);
          }
        } else if (
          !(
            Number(container?.media_program_type_id) ===
              MEDIA_PROGRAM_TYPES.TRANSPORT.ID &&
            container?.open_media_in_transport === Y_CHECKER
          )
        ) {
          // If container Not (transport AND has media)
          // Add At Iron Mountain
          const atIronMountain = await getExchangeStatusValue(
            exchangeStatus.atImosdp
          );
          if (atIronMountain) {
            newStatus.push(atIronMountain);
          }
        }
      }

      if (container?.max_exch_status_code !== VALUE_EMPTY_STRING) {
        // pending distribution check
        if (
          Number(container?.max_exch_status_id) !== exchangeStatus.atImosdp &&
          Number(container?.max_exch_status_id) !==
            exchangeStatus.pendingDistribution &&
          Number(container?.max_exch_status_id) !== exchangeStatus.delivered &&
          Number(container?.max_exch_status_id) !== exchangeStatus.deleted &&
          container?.max_exch_status_id !== VALUE_EMPTY_STRING &&
          Number(container?.max_exch_status_id) !==
            Number(container?.exchange_status_id)
        ) {
          const exists = newStatus.findIndex(
            (st) => Number(st.value) === Number(container?.max_exch_status_id)
          );
          if (exists < 0) {
            // If it is not in the list, add to new status column item
            newStatus.push({
              label: container?.max_exch_status_code,
              value: container?.max_exch_status_id
            });
          }
        }
      }

      setNewExchangeStatuses((prevState) => {
        if (
          props.flag2020D &&
          Number(container?.exchange_status_id) === exchangeStatus.pickedUp &&
          Number(container?.run_exchange_status_id) <
            exchangeStatus.arrivedAtImosdp
        ) {
          return [];
        }

        return newStatus;
      });
    }
  };

  // edit container research form
  const editContainerForm = useFormik({
    initialValues: editContainerFormInitialValues
  });

  /**
   * @name indefiniteRetentionFieldEditable
   * @description If user clicks on INDEF RET column check to see if user can edit the field
   * @param {bool} onIndefRetFlag triggered by indefinite_retention_flag field
   * @returns
   */
  const indefiniteRetentionFieldEditable = async (onIndefRetFlag = false) => {
    let result = false;

    if (!props.enableUpdate) {
      setDisableIndefRetentionCheckbox((prevState) => true);
      result = false;
    } else {
      result = true;
      setDisableIndefRetentionCheckbox((prevState) => false);
      // if Container is in discrepancy, delivered, issued or At Customer and
      // not a transport - we cannot change information
      if (
        ((containerResearch?.discrep_resolved_flag !== N_CHECKER &&
          Number(containerResearch?.exchange_status_id) !==
            exchangeStatus.delivered &&
          Number(containerResearch?.exchange_status_id) !==
            exchangeStatus.tobeIssued &&
          editContainerForm.values?.newStatus !==
            exchangeStatus.deleted.toString() &&
          editContainerForm.values?.newStatus !==
            exchangeStatus.atImosdp.toString()) ||
          (Number(containerResearch?.exchange_status_id) ===
            exchangeStatus.delivered &&
            editContainerForm.values?.newStatus ===
              exchangeStatus.atImosdp.toString()) ||
          (Number(containerResearch?.exchange_status_id) ===
            exchangeStatus.tobeIssued &&
            editContainerForm.values?.newStatus ===
              exchangeStatus.atImosdp.toString())) &&
        Number(containerResearch?.media_program_type_id) !==
          MEDIA_PROGRAM_TYPES.TRANSPORT.ID
      ) {
        if (
          Number(containerResearch?.run_id) > 0 &&
          containerResearch?.discrep_resolved_flag !== N_CHECKER &&
          Number(editContainerForm.values?.newStatus) !==
            exchangeStatus.atImosdp
        ) {
          if (!containerRunRule) {
            setDisableIndefRetentionCheckbox((prevState) => true);
            result = false;
          }
        }

        // Don't allow users to change the indefinite Retention flag when the status is deleted and the new status is not at IM.
        if (
          Number(containerResearch?.exchange_status_id) ===
            exchangeStatus.deleted &&
          Number(editContainerForm.values?.newStatus) !==
            exchangeStatus.atImosdp
        ) {
          setDisableIndefRetentionCheckbox((prevState) => true);
          result = false;
        }
      } else if (
        Number(containerResearch?.run_id) > 0 &&
        containerResearch?.discrep_resolved_flag !== N_CHECKER &&
        Number(containerResearch?.media_program_type_id) !==
          MEDIA_PROGRAM_TYPES.TRANSPORT.ID
      ) {
        if (!containerRunRule) {
          setDisableIndefRetentionCheckbox((prevState) => true);
          result = false;
        }
      } else {
        // If sDiscrepancy_Resolved_Flag == NO and currently on INDEF_RETENTION_COL
        if (
          containerResearch?.discrep_resolved_flag === N_CHECKER &&
          onIndefRetFlag
        ) {
          if (!message20375Showed) {
            const errDesc = await getMessageFromCode(errorMsgs.errorCode20375);

            setAlert((prevState) => ({
              show: true,
              message: errDesc[0]?.descr,
              severity: "warning"
            }));
            setMessage20375Showed((prevState) => true);
          }
          setDisableIndefRetentionCheckbox((prevState) => true);
          result = false;
        }

        if (
          props.flag2020D &&
          Number(containerResearch?.exchange_status_id) ===
            exchangeStatus.pickedUp
        ) {
          setDisableIndefRetentionCheckbox((prevState) => true);
          result = false;
        }

        if (
          Number(containerResearch?.exchange_status_id) ===
            exchangeStatus.pickedUp &&
          Number(editContainerForm.values?.newStatus) !==
            exchangeStatus.atImosdp
        ) {
          setDisableIndefRetentionCheckbox((prevState) => true);
          result = false;
        }
      }
    }
    return result;
  };

  // fetch media types for the container
  const fetchMediaTypes = async (logicalVaultId) => {
    try {
      const reqBody = JSON.stringify({
        main_district_id: getAuthenticatedUserBranch(),
        logical_vault_id: logicalVaultId
      });
      const dataSets = await getResponseData(
        reqBody,
        CF_URLS.containerProcessing.getLVMediaTypes,
        2
      );

      const results = dataSets?.data;
      const mappedMediaTypes = results[0]?.map((mt) => ({
        label: mt?.descr,
        value: mt?.media_type_id
      }));
      const sortedMediaTypes = mappedMediaTypes?.sort((a, b) =>
        a?.label.localeCompare(b?.label)
      );
      setMediaTypes((prevState) => sortedMediaTypes);
    } catch (error) {
      setAlert((prevState) => ({
        show: true,
        message: findErrorMessage(ERROR_TYPES.ISSUE),
        severity: "error"
      }));
    }
  };

  // initialize data and options required for editing a container research item
  const initializeData = async () => {
    if (props.containerValues) {
      setPageLoading((prevState) => true);

      let container = props.containerValues;
      // check run rule
      await checkRunRule(container);

      if (
        container?.new_exch_status_id === undefined ||
        container?.new_exch_status_id === VALUE_EMPTY_STRING
      ) {
        container = {
          ...container,
          new_exch_status_id: VALUE_EMPTY_STRING,
          new_exch_status_code: VALUE_EMPTY_STRING
        };
      }
      setContainerResearch((prevState) => container);
      let newFormValues = {
        customer: container?.customer_number,
        logicalVault: container?.invoice_descr,
        mediaType: container?.media_type_id,
        customerMediaIdentifier: container?.customer_media_descr
          ? container?.customer_media_descr
          : "",
        indefRetentionFlag: container?.indefinite_retention_flag === Y_CHECKER,
        returnDate: container?.return_date
          ? getDateObjectFromUTCFormat(container?.return_date)
          : null,
        newStatus: container?.new_exch_status_id
          ? container.new_exch_status_id
          : "",
        requestId: container?.request_id,
        runId: container?.run_id
      };

      if (
        !props.enableUpdateNewStatusField &&
        Number(container?.exchange_status_id) !== exchangeStatus.atImosdp
      ) {
        // User does not have permission to update New Status
        // AND container exchange_status_id is NOT At Iron Mountain
        newFormValues.returnDate = null;
      }

      // determine whether the media_type field can be editable
      if (
        Number(container?.exchange_status_id) !==
          exchangeStatus.pendingDistribution &&
        Number(container?.exchange_status_id) !== exchangeStatus.staged &&
        Number(container?.exchange_status_id) !== exchangeStatus.runPrep &&
        Number(container?.exchange_status_id) !==
          exchangeStatus.generatedRunList
      ) {
        setDisableMediaTypeField((prevState) => true);
      }

      // determine whether the customer_media_identifier field can be editable
      setDisableCustomerMediaIdentifierField(
        (prevState) =>
          !(
            container?.discrep_resolved_flag !== N_CHECKER &&
            props.enableUpdateCustomerMediaIdField
          )
      );

      // determine whether the indefinite_retention_flag field can be editable
      setDisableIndefRetentionCheckbox((prevState) => !props.enableUpdate);

      // determine whether the return_date field can be editable
      setDisableReturnDateField((prevState) => !props.enableUpdate);

      // determine whether the new_status field can be editable
      setDisableNewStatusField(
        (prevState) => !props.enableUpdateNewStatusField
      );

      if (container?.new_exch_status_id) {
        // If NEW_STATUS_COL has a value
        if (
          Number(container?.new_exch_status_id) === exchangeStatus.atImosdp ||
          Number(container?.new_exch_status_id) ===
            exchangeStatus.pendingDistribution
        ) {
          // If value entered is “At Iron Mountain” OR “Pending Distribution”
          // lock column RETURN_DATE_COL based on mNoReturnDateUpdate
          setDisableReturnDateField((prevState) => !props.enableUpdate);
          // Set mbIndefRetLocked also to mNoReturnDateUpdate
          setDisableIndefRetentionCheckbox((prevState) => !props.enableUpdate);
        } else if (
          Number(container?.exchange_status_id) !== exchangeStatus.delivered &&
          Number(container?.exchange_status_id) !== exchangeStatus.deleted
        ) {
          // Else if current container status is not “Delivered” AND not “Deleted”
          // lock column RETURN_DATE_COL based on mNoReturnDateUpdate
          setDisableReturnDateField((prevState) => !props.enableUpdate);
          // Set mbIndefRetLocked also to mNoReturnDateUpdate
          setDisableIndefRetentionCheckbox((prevState) => !props.enableUpdate);
        }
      }

      await Promise.allSettled([
        // fill new_exchange_status dropdown
        constructExchangeStatus(container),
        // fill the form with the values
        editContainerForm.setValues(newFormValues),
        // fetch and store customer's service auth comments
        fetchAuthComments(container?.customer_id),
        // fetch and store schedules services and set to date picker
        fetchScheduledServiceDates(
          container.customer_id,
          handleFromDate(dayjs()),
          handleToDate(dayjs())
        ),
        // fetch and store media types of the container
        fetchMediaTypes(container?.logical_vault_id)
      ]);
      setReturnDateUpdated((prevState) => true);

      setPageLoading((prevState) => false);
    }
  };

  // fetch customer's service auth comments
  const fetchAuthComments = async (customerId) => {
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      exchange_location_type_code: EXCHANGE_LOCATION_TYPE_SL,
      service_frequency_flag: Y_CHECKER
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.requestDetail.getServiceAuthComments}`,
      2
    );

    const results = response.data[0];
    setServiceAuthComments((prevState) => results[0]);
  };

  // fetch scheduled service dates based on the inputs
  const fetchScheduledServiceDates = async (customerId, fromDate, toDate) => {
    setScheduledServicesLoading((prevState) => true);
    const requestBody = JSON.stringify({
      main_district_id: getAuthenticatedUserBranch(),
      customer_id: customerId,
      from_date: fromDate,
      to_date: toDate,
      reschedule_flag: N_CHECKER,
      addon_cutoff_flag: N_CHECKER
    });
    const response = await getResponseData(
      requestBody,
      `${CF_URLS.requestDetail.getScheduledServiceDates}`,
      2
    );

    const results = response.data[0];
    setScheduledServices((prevState) => results);
    setScheduledServicesLoading((prevState) => false);
  };

  // Media Type onChange event handler
  const handleMediaTypeOnChange = (event) => {
    editContainerForm.handleChange(event);
  };

  // Customer Media Identifier onChange event handler
  const handleCustomerMediaIdOnChange = (event) =>
    editContainerForm.handleChange(event);

  // check whether the supplied CMI already exists for the customer
  const checkDuplicateCMI = (newCMI) => {
    if (newCMI !== VALUE_EMPTY_STRING) {
      const existing = props.existingMediaIds?.find(
        (item) => item?.customer_media_descr === newCMI
      );
      if (existing) {
        // existing CMI
        return existing;
      }
    }
    return null;
  };

  /**
   * @name checkIndefRet
   * @description Depending on the value entered for Indef Ret set properties accordingly
   * @param {bool} checked field checked or not
   */
  const checkIndefRet = async (checked) => {
    if (checked) {
      // if checked
      await editContainerForm.setFieldValue("returnDate", null);
      setReturnDateUpdated((prevState) => !prevState);
      await editContainerForm.setFieldValue("requestId", 0);
      await editContainerForm.setFieldValue("runId", 0);
    } else {
      setDisableReturnDateField((prevState) => !props.enableUpdate);
    }
  };

  // Indefinite retention checkbox onChange event handler
  const handleIndefiniteRetOnChange = async (event, checked) => {
    if (!disableIndefRetentionCheckbox) {
      await editContainerForm.setFieldValue("indefRetentionFlag", checked);
      await checkIndefRet(checked);
    }

    const editable = await indefiniteRetentionFieldEditable(true);
    // reset the grid from the object if not allowed to update
    if (!editable) {
      await editContainerForm.setFieldValue(
        "indefRetentionFlag",
        containerResearch?.indefinite_retention_flag === Y_CHECKER
      );
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date !== VALUE_EMPTY_STRING
          ? returnDate
          : null,
        false
      );
      setReturnDateUpdated((prevState) => !prevState);
      await editContainerForm.setFieldValue(
        "requestId",
        containerResearch?.request_id
      );
      await editContainerForm.setFieldValue("runId", containerResearch?.run_id);
      setDisableIndefRetentionCheckbox((prevState) => true);
    }
  };

  /**
   * @name checkReturnDateField
   * @description Depending on the value entered for Return Date set properties accordingly
   * @param {dayjs} date return date
   * @returns true if the onChange event needs to be cancelled; false otherwise
   */
  const checkReturnDateField = async (date) => {
    let result = false;

    const currentDate = editContainerForm.values?.returnDate;
    // if the date is the same then don't edit
    if (currentDate !== null && date.isSame(currentDate, "day")) {
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date ? returnDate : null
      );
      return result;
    }

    // Transport return dates cannot be edited
    // Don't allow change if column is locked
    if (disableReturnDateField) {
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date ? returnDate : null
      );
      return result;
    }

    // clear indefinite_retention_flag
    if (
      editContainerForm.values.indefRetentionFlag &&
      !date.isSame(dayjs(returnDate), "day")
    ) {
      // indefinite_retention_flag is checked AND current container return_date is not same as new value
      await editContainerForm.setFieldValue("indefRetentionFlag", false);
    }

    // check date < current date
    if (date.isBefore(dayjs(), "day")) {
      // reset column value to container return date
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date ? returnDate : null
      );
      setAlert((prevState) => ({
        show: true,
        message: message20271,
        severity: "warning"
      }));
      return result;
    }

    // check date > max date
    if (date.isAfter(maximumReturnDate, "day")) {
      // reset column value to container return date
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date ? returnDate : null
      );
      result = true;
      setAlert((prevState) => ({
        show: true,
        message: message10152,
        severity: "warning"
      }));
      return result;
    }

    if (serviceAuthComments?.generated_date_end !== VALUE_EMPTY_STRING) {
      if (
        scheduledServices.length > 0 ||
        date?.isAfter(dayjs(serviceAuthComments?.generated_date_end), "day")
      ) {
        const parentRequest = scheduledServiceDates?.find((service_date) =>
          dayjs(service_date).isSame(date, "day")
        );

        if (parentRequest) {
          // schedule service available
          await editContainerForm.setFieldValue(
            "requestId",
            parentRequest?.request_id || 0
          );
          await editContainerForm.setFieldValue(
            "runId",
            parentRequest?.run_id || 0
          );

          // check run rules if the user is assigning to a new run
          if (parentRequest?.run_id) {
            // run available, check run rules
            if (!containerRunRule) {
              const errDesc = await getMessageFromCode(
                errorMsgs.errorCode20401
              );

              setAlert((prevState) => ({
                show: true,
                message: errDesc[0]?.descr,
                severity: "warning"
              }));
            }

            // get new run information
            // needs to implement
            // findRunStatus(parentRequest?.run_id);
          }
        } else {
          await editContainerForm.setFieldValue("requestId", 0);
          await editContainerForm.setFieldValue("runId", 0);
          await editContainerForm.setFieldValue(
            "returnDate",
            containerResearch?.return_date ? returnDate : null
          );
          setAlert((prevState) => ({
            show: true,
            message: message20229,
            severity: "warning"
          }));
        }
      } else {
        await editContainerForm.setFieldValue(
          "returnDate",
          containerResearch?.return_date ? returnDate : null
        );
        setAlert((prevState) => ({
          show: true,
          message: message20229,
          severity: "warning"
        }));
        return result;
      }
    } else {
      await editContainerForm.setFieldValue(
        "returnDate",
        containerResearch?.return_date ? returnDate : null
      );
      setAlert((prevState) => ({
        show: true,
        message: message20229,
        severity: "warning"
      }));
      return result;
    }

    if (
      Number(containerResearch.exchange_status_id) !==
        exchangeStatus.atImosdp ||
      Number(containerResearch.exchange_status_id) !==
        exchangeStatus.pendingDistribution
    ) {
      // exchange status is not At Iron Mountain or Pending Distribution
      await editContainerForm.setFieldValue(
        "newStatus",
        exchangeStatus.atImosdp
      );
    }
  };

  // Return Date onChange event handler
  const handleReturnDateOnChange = async (date) => {
    setPageLoading((prevState) => true);

    // clear alert
    setAlert((prevState) => ({
      show: false,
      message: "",
      severity: "warning"
    }));

    // set the return date selected
    await editContainerForm.setFieldValue("returnDate", date);
    setReturnDateUpdated((prevState) => !prevState);

    if (
      !props.enableUpdateNewStatusField &&
      Number(containerResearch?.exchange_status_id) !== exchangeStatus.atImosdp
    ) {
      await editContainerForm.setFieldValue("returnDate", null);
      setReturnDateUpdated((prevState) => !prevState);
    } else {
      await checkReturnDateField(date);
    }

    setPageLoading((prevState) => false);
  };

  /**
   * @name hanldeReturnDateOnOpen
   * @description Check to see if return date can be edited, if so load frmDate.
   *  When the user returns check run rules, edit run info if necessary,
   *  and update return date cell to the date selected from frmDate.
   */
  const hanldeReturnDateOnOpen = async () => {
    setPageLoading((prevState) => true);

    // if Container is in discrepancy, display message
    if (containerResearch?.discrep_resolved_flag === N_CHECKER) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20375);
      setAlert((prevState) => ({
        show: true,
        message: errDesc[0]?.descr,
        severity: "warning"
      }));
      setOpenDatePicker((prevState) => false);
      setPageLoading((prevState) => false);
      return;
    }

    // check to see if the return date cannot be changed because the
    // indefinite return date flag is set
    if (disableReturnDateField) {
      setOpenDatePicker((prevState) => false);
      setPageLoading((prevState) => false);
      return;
    }

    // check to see if we should be able to modify the return date
    if (
      Number(editContainerForm.values?.runId) > 0 &&
      (Number(editContainerForm.values?.newStatus) !==
        exchangeStatus.atImosdp ||
        Number(editContainerForm.values?.newStatus) !==
          exchangeStatus.pendingDistribution)
    ) {
      // check to see if the Container is tied to a run - if so, check the run rules
      if (!containerRunRule) {
        const errDesc = await getMessageFromCode(errorMsgs.errorCode20376);
        setAlert((prevState) => ({
          show: true,
          message: errDesc[0]?.descr,
          severity: "warning"
        }));
        setOpenDatePicker((prevState) => false);
        setPageLoading((prevState) => false);
        return;
      }
    }

    setOpenDatePicker((prevState) => true);
    setPageLoading((prevState) => false);

    const isFutureMonth = dayjs().isBefore(
      editContainerForm.values?.returnDate,
      "month"
    );
    let scheduleDate = dayjs();
    if (isFutureMonth) {
      scheduleDate = editContainerForm.values?.returnDate;
    }

    await fetchScheduledServiceDates(
      containerResearch?.customer_id,
      handleFromDate(scheduleDate),
      handleToDate(scheduleDate)
    );
  };

  // New Status onChange event handler
  const handleNewStatusOnChange = async (event) => {
    const currentStatus = editContainerForm.values?.newStatus;
    const newStatus = event.target.value;

    if (
      Number(newStatus) === exchangeStatus.deleted &&
      Number(currentStatus) !== exchangeStatus.deleted
    ) {
      // If new status is Delete (and previous was not delete)
      setShowDeleteContainerModal((prevState) => true);
    }

    if (
      Number(newStatus) === exchangeStatus.delivered ||
      Number(newStatus) === exchangeStatus.deleted
    ) {
      // if new status is delivered or deleted then clear fields
      await editContainerForm.setFieldValue("returnDate", null);
      setReturnDateUpdated((prevState) => !prevState);
      setDisableReturnDateField((prevState) => true);
      await editContainerForm.setFieldValue("indefRetentionFlag", false);
    }

    if (Number(newStatus) === exchangeStatus.atImosdp) {
      // Else if new status At Iron Mountain
      if (
        Number(containerResearch?.media_program_type_id) ===
        MEDIA_PROGRAM_TYPES.TRANSPORT.ID
      ) {
        // If container is a Transport
        await editContainerForm.setFieldValue("returnDate", null);
        setReturnDateUpdated((prevState) => !prevState);
        await editContainerForm.setFieldValue("indefRetentionFlag", true);
      } else {
        // lock RETURN_DATE_COL based on security
        setDisableReturnDateField((prevState) => !props.enableUpdate);
      }
    } else {
      // lock RETURN_DATE_COL based on security
      setDisableReturnDateField((prevState) => !props.enableUpdate);
    }

    editContainerForm.handleChange(event);

    // if newStatus = At Iron Mountain AND Indefinite_Retention_Flag = NO AND Return_Date == ""
    if (
      newStatus === exchangeStatus.atImosdp.toString() &&
      !editContainerForm.values?.indefRetentionFlag &&
      editContainerForm.values?.returnDate === null
    ) {
      const newStatusObj = await getExchangeStatusValue(newStatus);
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20394);
      setAlert((prevState) => ({
        show: true,
        message: errDesc[0]?.descr
          ?.replace("|", newStatusObj.label)
          ?.replace("|", containerResearch.container_number),
        severity: "warning"
      }));
    }
  };

  // modal Close button onClick event handler
  const handleOnClose = () => props.onClose();

  // modal Update button onClick event handler
  const handleOnUpdate = async (mediaTypeChangeConfirmed = false) => {
    setPageLoading((prevState) => true);

    // validate CMI
    const duplicateCMI = checkDuplicateCMI(
      editContainerForm.values?.customerMediaIdentifier
    );
    if (duplicateCMI) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode63860);

      setAlert((prevState) => ({
        show: true,
        message: errDesc[0]?.descr?.replace(
          "|",
          containerResearch.container_number
        ),
        severity: "warning"
      }));

      // reset CMI to previous value
      await editContainerForm.setFieldValue(
        "customerMediaIdentifier",
        containerResearch?.customer_media_descr
      );
      setPageLoading((prevState) => false);
      return;
    }

    // IF Indefinite_Retention_Flag = NO AND Return_Date == "" AND newStatus <> Delivered AND newStatus <> Deleted AND Exchange_Status_Id <> Deleted
    if (
      !editContainerForm.values?.indefRetentionFlag &&
      editContainerForm.values?.returnDate === null &&
      String(editContainerForm.values?.newStatus) !==
        String(exchangeStatus.delivered) &&
      String(editContainerForm.values?.newStatus) !==
        String(exchangeStatus.deleted) &&
      Number(containerResearch?.exchange_status_id) !== exchangeStatus.deleted
    ) {
      const errDesc = await getMessageFromCode(errorMsgs.errorCode20392);
      setAlert((prevState) => ({
        show: true,
        message: errDesc[0]?.descr?.replace(
          "|",
          containerResearch.container_number
        ),
        severity: "warning"
      }));
      setPageLoading((prevState) => false);
      return;
    }

    let updatedContainer = {
      ...containerResearch,
      // reset request_id to existing value if new value is 0
      request_id:
        Number(editContainerForm.values?.requestId) > 0
          ? editContainerForm.values?.requestId
          : containerResearch?.request_id,
      // reset run_id to existing value if new value is 0
      run_id:
        Number(editContainerForm.values?.runId) > 0
          ? editContainerForm.values?.runId
          : containerResearch?.run_id,
      customer_media_descr: editContainerForm.values?.customerMediaIdentifier,
      indefinite_retention_flag: editContainerForm.values?.indefRetentionFlag
        ? Y_CHECKER
        : N_CHECKER,
      return_date:
        editContainerForm.values?.returnDate === null
          ? VALUE_EMPTY_STRING
          : editContainerForm.values?.returnDate
    };

    // if only the media type updated
    if (
      containerResearch?.customer_media_descr ===
        editContainerForm.values?.customerMediaIdentifier &&
      (containerResearch?.indefinite_retention_flag === Y_CHECKER) ===
        editContainerForm.values?.indefRetentionFlag &&
      (containerResearch?.return_date ===
        (editContainerForm.values?.returnDate ?? VALUE_EMPTY_STRING) ||
        (returnDate &&
          returnDate.isSame(editContainerForm.values?.returnDate, "day"))) &&
      containerResearch?.new_exch_status_id ===
        editContainerForm.values?.newStatus &&
      containerResearch?.media_type_id !== editContainerForm.values?.mediaType
    ) {
      // media type change not confirmed
      if (!mediaTypeChangeConfirmed) {
        // init save when media type changed
        setPageLoading((prevState) => false);
        setShowMediaTypeChangeConfirmModal((prevState) => true);
        return;
      } else {
        // get the selected media type values
        const mediaType = mediaTypes.find(
          (mt) => mt?.value === editContainerForm.values?.mediaType
        );
        updatedContainer = {
          ...updatedContainer,
          media_type_id: mediaType?.value,
          descr: mediaType?.label
        };
      }
    }

    // new status updated
    if (
      editContainerForm.values?.newStatus !== VALUE_EMPTY_STRING &&
      containerResearch?.new_exch_status_id !==
        editContainerForm.values?.newStatus
    ) {
      // get the selected status values
      const newStatus = await getExchangeStatusValue(
        editContainerForm.values?.newStatus
      );
      updatedContainer = {
        ...updatedContainer,
        new_exch_status_id: newStatus?.value,
        new_exch_status_code: newStatus?.label
      };
    }

    props.onSubmit(updatedContainer);

    setPageLoading((prevState) => false);
  };

  // delete container No button onClick event handler
  const handleDeleteContainerNoOnClick = async () => {
    await editContainerForm.setFieldValue("newStatus", "");
    setShowDeleteContainerModal((prevState) => false);
  };

  // media type change confirm modal Yes button onClick event handler
  const handleChangeMediaTypeYesClick = async () => {
    setShowMediaTypeChangeConfirmModal((prevState) => false);
    await handleOnUpdate(true);
  };

  // media type change confirm modal No button onClick event handler
  const handleChangeMediaTypeNoClick = async () => {
    await editContainerForm.setFieldValue(
      "mediaType",
      containerResearch?.media_type_id
    );
    setShowMediaTypeChangeConfirmModal((prevState) => false);
  };

  // init page
  useEffect(() => {
    getMessageFromCode(errorMsgs.errorCode10152).then((resp) =>
      setMessage10152((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode20229).then((resp) =>
      setMessage20229((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode80117).then((resp) =>
      setMessage80117((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode20271).then((resp) =>
      setMessage20271((prevState) => resp[0]?.descr)
    );
    getMessageFromCode(errorMsgs.errorCode20353).then((resp) =>
      setMessage20353((prevState) => resp[0]?.descr)
    );

    initializeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Full page loader */}
      <CircularLoaderFullPage
        id="container-research-full-page-loader"
        loading={pageLoading}
      />

      <CommonModal
        open={props.open}
        title={t("containerResearch.editContainerResearch")}
        body={
          <Box sx={containerResearchStyles.modalStyles.containerWidth}>
            {/* Alert area */}
            {alert.show && (
              <Alert
                id="edit-container-research-modal-alert"
                severity={alert.severity}
                sx={{ marginBottom: spacing.verticalMargin16 }}
                onClose={() =>
                  setAlert((prevState) => ({
                    show: false,
                    message: ""
                  }))
                }
              >
                {alert.message}
              </Alert>
            )}

            <Grid
              id="edit-container-research-modal-grid1"
              container
              spacing={spacing.verticalMargin16}
            >
              <Grid
                id="edit-container-research-modal-grid2"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Customer */}
                <Grid id="edit-container-research-modal-grid2" item md={6}>
                  <NonEditableField
                    id="edit-container-research-modal-customer"
                    label={t("containerResearch.customer")}
                    value={
                      props.showCustomerNumber
                        ? editContainerForm.values?.customer
                        : VALUE_EMPTY_STRING
                    }
                  />
                </Grid>

                {/* Logical vault */}
                <Grid id="edit-container-research-modal-grid3" item md={6}>
                  <NonEditableField
                    id="edit-container-research-modal-logical-vault"
                    label={t("containerResearch.logicalVault")}
                    value={editContainerForm.values?.logicalVault}
                  />
                </Grid>
              </Grid>

              <Grid
                id="edit-container-research-modal-grid4"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Media Type */}
                <Grid id="edit-container-research-modal-grid10" item md={6}>
                  <SelectField
                    id="edit-container-research-modal-media-type"
                    disabled={disableMediaTypeField}
                    name="mediaType"
                    label={t("containerResearch.mediaType")}
                    options={mediaTypes}
                    value={editContainerForm.values?.mediaType}
                    onChange={handleMediaTypeOnChange}
                  />
                </Grid>

                {/* Customer Media Identifiers */}
                {props.enableUpdateCustomerMediaIdField && (
                  <Grid id="edit-container-research-modal-grid5" item md={6}>
                    <TextField
                      id="edit-container-research-modal-customer-media-identifiers"
                      fullWidth
                      name="customerMediaIdentifier"
                      label={t("containerResearch.customerMediaIdentifier")}
                      disabled={disableCustomerMediaIdentifierField}
                      value={editContainerForm.values?.customerMediaIdentifier}
                      onChange={handleCustomerMediaIdOnChange}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                id="edit-container-research-modal-grid7"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* Indefinite Retention */}
                <Grid id="edit-container-research-modal-grid6" item md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="edit-container-research-modal-idefinite-retention"
                        disabled={disableIndefRetentionCheckbox}
                        color="primary"
                        name="indefRetentionFlag"
                        checked={editContainerForm.values?.indefRetentionFlag}
                        onChange={handleIndefiniteRetOnChange}
                      />
                    }
                    label={t("containerResearch.indefRetention")}
                  />
                </Grid>

                {/* Return Date */}
                <Grid id="edit-container-research-modal-grid8" item md={6}>
                  <DatePickerField
                    id="edit-container-research-modal-return-date"
                    key={returnDateUpdated}
                    disabled={disableReturnDateField}
                    name="returnDate"
                    label={t("containerResearch.returnDate")}
                    locale={props.branchLocale}
                    value={dayjs(
                      dayjs(editContainerForm.values?.returnDate, {
                        strict: true
                      }).toDate()
                    )}
                    maxDate={maximumReturnDate}
                    minDate={dayjs()}
                    open={openDatePicker}
                    closeOnSelect={true}
                    onAccept={handleReturnDateOnChange}
                    error={false}
                    loading={scheduledServicesLoading}
                    renderLoading={() => <CircularLoader loading={true} />}
                    onOpen={hanldeReturnDateOnOpen}
                    onClose={() => setOpenDatePicker((prevState) => false)}
                    onMonthChange={(value) =>
                      fetchScheduledServiceDates(
                        containerResearch?.customer_id,
                        handleFromDate(value),
                        handleToDate(value)
                      )
                    }
                    onYearChange={(value) =>
                      fetchScheduledServiceDates(
                        containerResearch?.customer_id,
                        handleFromDate(value),
                        handleToDate(value)
                      )
                    }
                    highlightScheduleDays={(props) => (
                      <HighlightScheduledDates
                        {...props}
                        selectedDate={editContainerForm.values?.returnDate}
                        hightlightedDates={scheduledServiceDates}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid
                id="edit-container-research-modal-grid7"
                item
                container
                md={12}
                columnSpacing={spacing.horizontalMargin16}
              >
                {/* New Status */}
                <Grid id="edit-container-research-modal-grid9" item md={6}>
                  <SelectField
                    id="edit-container-research-modal-new-status"
                    disabled={disableNewStatusField}
                    name="newStatus"
                    label={t("containerResearch.newStatus")}
                    options={newExchangeStatuses}
                    value={editContainerForm.values?.newStatus}
                    onChange={handleNewStatusOnChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        }
        buttons={
          <>
            <Button
              id="edit-container-research-modal-cancel-btn"
              variant="outlined"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="reset"
              onClick={handleOnClose}
            >
              {t("common.cancel")}
            </Button>
            <Button
              id="edit-container-research-modal-ok-btn"
              variant="contained"
              sx={containerResearchStyles.modalStyles.buttonStyle}
              type="submit"
              onClick={(e) => handleOnUpdate()}
            >
              {t("common.update")}
            </Button>
          </>
        }
      />

      {/* Confirm media type change confirmation modal */}
      {showMediaTypeChangeConfirmModal && (
        <ModalPopup
          modalPopupOpen={showMediaTypeChangeConfirmModal}
          title={t("common.confirm")}
          alertMessage={message80117}
          modalButton={
            <>
              <Box>
                <Button
                  id="edit-container-mediat-type-confirm-modal-cancel-btn"
                  variant="outlined"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="reset"
                  onClick={handleChangeMediaTypeNoClick}
                >
                  {t("common.cancel")}
                </Button>
              </Box>
              <Box>
                <Button
                  id="edit-container-mediat-type-confirm-modal-ok-btn"
                  variant="contained"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="submit"
                  onClick={handleChangeMediaTypeYesClick}
                >
                  {t("common.ok")}
                </Button>
              </Box>
            </>
          }
        />
      )}

      {/* Delete container confirmation modal */}
      {showDeleteContainerModal && (
        <ModalPopup
          modalPopupOpen={showDeleteContainerModal}
          title={t("common.confirm")}
          alertMessage={message20353.replace(
            "|",
            containerResearch?.container_number
          )}
          modalButton={
            <>
              <Box>
                <Button
                  id="edit-container-delete-confirm-modal-cancel-btn"
                  variant="outlined"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="reset"
                  onClick={handleDeleteContainerNoOnClick}
                >
                  {t("common.no")}
                </Button>
              </Box>
              <Box>
                <Button
                  id="edit-container-delete-confirm-modal-ok-btn"
                  variant="contained"
                  sx={containerResearchStyles.modalStyles.buttonStyle}
                  type="submit"
                  onClick={() =>
                    setShowDeleteContainerModal((prevState) => false)
                  }
                >
                  {t("common.yes")}
                </Button>
              </Box>
            </>
          }
        />
      )}
    </>
  );
};

EditContainerResearchModal.propTypes = {
  open: PropTypes.bool.isRequired, // show/hide modal state
  flag2020D: PropTypes.bool.isRequired, // 2020D enabled flag
  slottingContainerFlag: PropTypes.bool.isRequired, // Slotting container flag
  showCustomerNumber: PropTypes.bool.isRequired, // customer number visible
  enableUpdate: PropTypes.bool.isRequired, // can edit container_research item values
  enableUpdateNewStatusField: PropTypes.bool.isRequired, // can edit container_research item new_status field
  enableUpdateCustomerMediaIdField: PropTypes.bool.isRequired, // can edit container_research item customer_media_id field
  addDeletedStatusOption: PropTypes.bool.isRequired, // add deleted option to container_research item new_status dropdown
  containerValues: PropTypes.array.isRequired, // existing container numbers
  branchLocale: PropTypes.string.isRequired, // locale of the selected branch
  onClose: PropTypes.func, // handle modal close event
  onSubmit: PropTypes.func, // handle modal submit event
  existingMediaIds: PropTypes.array // media ids for selected customer
};

export default EditContainerResearchModal;
