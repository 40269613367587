import CloseIcon from "@mui/icons-material/Close";

import { Alert, Box, IconButton, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RemoveRedEyeFilled from "assets/images/RemoveRedEyeFilled.svg";
import CreateOutlinedIcon from "assets/images/pencil.svg";
import ErrorIcon from "assets/images/warning-2.svg";
import { FullCircularLoader } from "components/core";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useGetGlobalAttributeValueByTypeIdQuery,
  useGetValidateOpenMediaListQuery
} from "services/api";
import { ERROR_TYPES, GLOBAL_ATTRIBUTE_VALUES } from "utils/constants";
import { CheckIsValidOMNumber } from "utils/helpers";
import {
  COLUMN_IDS_OPEN_MEDIA,
  CSV,
  DEFAULT_TABLE_SORT,
  INVALID_LABEL,
  MEDIA_LIMIT,
  STATUS_CHAR_LIMIT,
  TABLE_ROWS_PER_PAGE,
  TABLE_ROWS_PER_PAGE_DEFAULT,
  TXT
} from "../Constants";
import { MDRVaultItemizedStyles } from "../MDRVaultItemizedStyles.Styles";

const useFetchValidateOpenMediaList = (
  mainDistrictId,
  customerSelected,
  volserList,
  startValidation
) => {
  return useGetValidateOpenMediaListQuery({
    mainDistrictId: mainDistrictId,
    customerId: customerSelected.value,
    volserList: volserList,
    startValidation
  });
};

const OpenMediaTable = ({
  customerSelected,
  onClickHandler,
  selectedRowsMedia,
  selectedMedia,
  isReadOnly,
  setTableMedia,
  mdrTableData,
  resetCalled,
  setInvalidMedias,
  setShowInvalidMediasSnapBar,
  isNewFormClick,
  mainDistrictId
}) => {
  const { t } = useTranslation();
  const [volserList, setVolserList] = useState([]);
  const [startValidation, setStartValidation] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [removeClicked, setRemoveClicked] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_ROWS_PER_PAGE_DEFAULT);

  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [mediaNumber, setMediaNumber] = useState("");

  const [alreadyExists, setAlreadyExists] = useState(false);

  const [sorting, setSorting] = useState(DEFAULT_TABLE_SORT);
  const [shouldSort, setShouldSort] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [tableValidatedData, setTableValidatedData] = useState([]);
  const [isImporting, setIsImporting] = useState(false);

  const validateOpenMediaList = useFetchValidateOpenMediaList(
    mainDistrictId,
    customerSelected,
    volserList,
    startValidation
  );

  const {
    data: globalAttributeValue = "",
    isLoading: isLoadingGlobalAttribute,
    isError: isGlobalAttributeError
  } = useGetGlobalAttributeValueByTypeIdQuery({
    id: GLOBAL_ATTRIBUTE_VALUES.VALUE_94
  });

  const MEDIA_LIMIT_VALIDATION =
    globalAttributeValue !== "" ||
    !isGlobalAttributeError ||
    !isLoadingGlobalAttribute
      ? Number(globalAttributeValue)
      : 0;

  function removeSpaces(inputString) {
    // to remove spaces
    return inputString.replace(/\s/g, "");
  }

  useEffect(() => {
    if (errorMessage === "") {
      setIsError(false);
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    if (isNewFormClick) {
      setErrorMessage("");
      setIsError(false);
    }
  }, [isNewFormClick]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await validateOpenMediaList;

        if (response?.isSuccess) {
          if (
            response &&
            removeClicked &&
            volserList?.length === 1 &&
            volserList[0] === "" &&
            !response?.data
          ) {
            setButtonClicked(false);
            setRemoveClicked(false);
            setStartValidation(false);
            setTableValidatedData([]);
          }

          if (response && response?.data) {
            setButtonClicked(false);

            setStartValidation(false);
            const newData = response?.data.map((item) => ({
              ...item,
              volser: removeSpaces(item.volser)
            }));
            setShouldSort(true);
            setTableValidatedData(newData);

            handleSort("status");
          }

          if (response?.data && response?.data[0]?.error) {
            setButtonClicked(false);

            setErrorMessage(findErrorMessage(response?.data[0]?.error));
            setIsError(true);
          } else {
            setErrorMessage("");
            setIsError(false);
          }
        } else if (!response?.isSuccess && response?.data) {
          setButtonClicked(false);

          setErrorMessage(findErrorMessage(response?.data[0]?.error));
          setIsError(true);
        }
      } catch (e) {
        setButtonClicked(false);

        setErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
        setIsError(true);

        setStartValidation(false);
      }
    };

    if (buttonClicked) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [volserList, validateOpenMediaList, buttonClicked]);

  const findErrorMessage = (error) => {
    let relatedErrorMessage = "";

    switch (error) {
      case ERROR_TYPES.FAILED:
        relatedErrorMessage = t("vaultItemized.failedError");
        break;
      case ERROR_TYPES.EXCEPTION:
        relatedErrorMessage = t("vaultItemized.errorOccurred");
        break;
      case ERROR_TYPES.ISSUE:
        relatedErrorMessage = t("vaultItemized.somethingWrong");
        break;
      default:
        relatedErrorMessage = error;
        break;
    }

    return relatedErrorMessage;
  };

  const columns = [
    { id: COLUMN_IDS_OPEN_MEDIA.i, label: "", visible: !isReadOnly },
    {
      id: COLUMN_IDS_OPEN_MEDIA.media,
      label: t("vaultItemized.mediaLabelTable"),
      visible: true
    },
    {
      id: COLUMN_IDS_OPEN_MEDIA.status,
      label: t("vaultItemized.statusLabelTable"),
      visible: true
    },
    {
      id: COLUMN_IDS_OPEN_MEDIA.followUp,
      label: t("vaultItemized.followUpLabelTable"),
      visible: !isReadOnly
    }
  ];

  const reset = () => {
    setAlreadyExists(false);
    setMediaNumber("");
    setEditingRowIndex(null);
    setIsEditing(false);
    setTableValidatedData([]);
    setVolserList([]);
  };

  useEffect(() => {
    reset();
  }, [resetCalled]);

  const handleSort = (column) => {
    setShouldSort(true);
    setSorting((prevSorting) => ({
      column,
      direction:
        prevSorting.column === column && prevSorting.direction === "asc"
          ? "desc"
          : "asc"
    }));
  };

  useEffect(() => {
    if (isReadOnly) {
      if (mdrTableData && mdrTableData.length > 0) {
        // desc sorting by status column for existing MDRs
        const sortedData = [...mdrTableData].sort((a, b) => {
          if (a.destruct_item_status_descr < b.destruct_item_status_descr) {
            return 1;
          }
          if (a.destruct_item_status_descr > b.destruct_item_status_descr) {
            return -1;
          }
          return 0;
        });
        setTableValidatedData(sortedData);
      }
    }
  }, [mdrTableData, isReadOnly]);

  useEffect(() => {
    // sorting logic using sorting.column and sorting.direction
    if (!isEditing && tableValidatedData?.length > 0 && sorting && shouldSort) {
      setShouldSort(false);

      const sortedTableData = [...tableValidatedData]?.sort((a, b) => {
        const isAsc = sorting.direction === "asc";
        const columnId = sorting.column;

        // Adjust the comparison based on the column id
        if (columnId === COLUMN_IDS_OPEN_MEDIA.media) {
          return isAsc
            ? a.volser?.localeCompare(b.volser)
            : b.volser?.localeCompare(a.volser);
        } else if (columnId === COLUMN_IDS_OPEN_MEDIA.status) {
          return isAsc
            ? a.item_status_descr?.localeCompare(b.item_status_descr)
            : b.item_status_descr?.localeCompare(a.item_status_descr);
        } else if (columnId === COLUMN_IDS_OPEN_MEDIA.followUp) {
          return isAsc
            ? a.follow_up?.localeCompare(b.follow_up)
            : b.follow_up?.localeCompare(a.follow_up);
        }

        return 0;
      });

      !isReadOnly && setTableValidatedData(sortedTableData);
    }
    // eslint-disable-next-line
  }, [sorting, tableValidatedData, isEditing]);

  const handleEditRow = (index) => {
    setIsEditing(true);
    const row = tableValidatedData[index];
    setMediaNumber(row.volser.toUpperCase());

    setEditingRowIndex(index);
  };

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (selectedRowsMedia?.length > 0) {
      setButtonClicked(true);

      const volserList = selectedRowsMedia?.map((item) => item?.volser);
      if (!isReadOnly) {
        const volserArray = tableValidatedData?.map((item) => item.volser);
        //remove equal value to selectedMedia.volser from volserArray
        const filteredVolserArray = volserArray?.filter(
          (volser) => volser !== selectedMedia?.volser
        );

        setVolserList([...filteredVolserArray, ...volserList]);
        setStartValidation(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsMedia]);

  useEffect(() => {
    setTableMedia(tableValidatedData);
  }, [tableValidatedData, setTableMedia]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFileImport = async (event) => {
    setIsImporting(true);
    const file = event.target.files[0];

    if (file) {
      // Check if the selected file has a .txt or .csv extension
      if (
        !(
          file.name.toLowerCase().endsWith(TXT) ||
          file.name.toLowerCase().endsWith(CSV)
        )
      ) {
        setErrorMessage(t("vaultItemized.selectTXTCSVWarning"));
        setIsError(true);
        setIsImporting(false);
        // Clear the file input
        fileInputRef.current.value = null;
        return;
      } else {
        setErrorMessage("");
        setIsError(false);
      }

      setButtonClicked(true);

      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;

          const lines = content.split("\n");
          const filteredLines = Array.from(
            new Set(
              lines
                .map((line) => line.trim())
                .filter(
                  (line) =>
                    line.length > 0 && line.length <= MEDIA_LIMIT_VALIDATION
                )
            )
          );

          if (
            tableValidatedData?.length + filteredLines?.length <=
            MEDIA_LIMIT
          ) {
            processFileContent(content);
          } else {
            setErrorMessage(t("vaultItemized.mediaListExceeds"));
            setIsError(true);
            setIsImporting(false);
          }
          // Reset the input value to trigger the onChange event next time
          fileInputRef.current.value = null;
        };
        reader.readAsText(file);
      } catch (error) {
        setErrorMessage(t("vaultItemized.fileReadingError"));
        setIsError(true);
        setIsImporting(false);
      }
    }
  };

  const processFileContent = async (content) => {
    const lines = content.split("\n");
    const newData = [];
    const invalidList = [];
    const batchSize = 100;

    const processBatch = (batch) => {
      batch.forEach((line) => {
        const trimmedLine = line?.trim();

        if (trimmedLine?.length > MEDIA_LIMIT_VALIDATION) {
          const obj = {
            volser: trimmedLine,
            error: INVALID_LABEL
          };

          invalidList.push(obj);
        }

        if (
          trimmedLine &&
          tableValidatedData?.length < MEDIA_LIMIT &&
          trimmedLine?.length <= MEDIA_LIMIT_VALIDATION
        ) {
          const volser = trimmedLine;

          // Check if volser is not already in tableValidatedData
          const isMediaNumberInTable = tableValidatedData?.some(
            (item) =>
              String(item.volser).toUpperCase() === String(volser).toUpperCase()
          );

          // Check if volser is not already in the newData
          const isNewDataContainsMediaNumber = newData?.some(
            (item) => item.toUpperCase() === String(volser).toUpperCase()
          );

          if (!isMediaNumberInTable && !isNewDataContainsMediaNumber) {
            newData.push(volser);
          }
        }
      });
    };

    for (let i = 0; i < lines.length; i += batchSize) {
      const batch = lines.slice(i, i + batchSize);
      processBatch(batch);
      await new Promise((resolve) => setTimeout(resolve, 0)); // Allow UI updates
    }

    if (invalidList.length > 0) {
      setInvalidMedias(invalidList);
      setShowInvalidMediasSnapBar(true);
    }

    const rowsToAdd = newData?.slice(
      0,
      Math.max(0, MEDIA_LIMIT - tableValidatedData.length)
    );

    if (!isReadOnly) {
      const volserArray = tableValidatedData.map((item) => item.volser);
      setVolserList([...volserArray, ...rowsToAdd]);
      setStartValidation(true);
    }

    setIsImporting(false);
  };

  const handleRemoveRow = (index) => {
    const updatedTableData = [...tableValidatedData];
    updatedTableData.splice(index, 1);

    if (!isReadOnly) {
      const volserArray = updatedTableData.map((item) => item.volser);
      setVolserList([...volserArray, mediaNumber]);

      setShouldSort(true);
      setTableValidatedData(updatedTableData);
    }
  };

  const handleAddMediaNumber = () => {
    setIsEditing(false);
    if (mediaNumber !== "") {
      // Check if the media number is already in tableValidatedData
      const isMediaNumberInTable = tableValidatedData?.some(
        (item, idx) =>
          String(item.volser).toUpperCase() ===
            String(mediaNumber).toUpperCase() && idx !== editingRowIndex
      );

      if (!isMediaNumberInTable) {
        setAlreadyExists(false);
        setButtonClicked(true);

        if (editingRowIndex !== null) {
          //remove values of editingRowIndex in tableValidatedData
          const updatedTableData = [...tableValidatedData];
          updatedTableData.splice(editingRowIndex, 1);

          if (!isReadOnly) {
            const volserArray = updatedTableData.map((item) => item.volser);
            setVolserList([...volserArray, mediaNumber]);
            setStartValidation(true);
          }
        } else {
          if (!isReadOnly) {
            const volserArray = tableValidatedData.map((item) => item.volser);
            setVolserList([...volserArray, mediaNumber]);
            setStartValidation(true);
          }
        }
        setEditingRowIndex(null);

        setMediaNumber(""); // Clear the input field
      } else {
        setAlreadyExists(true);
      }
    }
  };

  const handleCancelEdit = () => {
    setAlreadyExists(false);

    setEditingRowIndex(null);
    setMediaNumber("");
    setIsEditing(false);
  };

  const handleMediaNumber = (event) => {
    setIsEditing(false);
    setAlreadyExists(false);

    const mediaNumber = event.target.value;
    const isValidInput = CheckIsValidOMNumber(mediaNumber);

    // Check if the new comment exceeds the character limit
    if (isValidInput && mediaNumber.length <= MEDIA_LIMIT_VALIDATION) {
      setMediaNumber(mediaNumber);
    }
  };

  const shrinkString = (inputString) => {
    try {
      return inputString?.length <= STATUS_CHAR_LIMIT
        ? inputString
        : inputString?.substring(0, STATUS_CHAR_LIMIT);
    } catch (error) {
      return inputString;
    }
  };

  return (
    <Stack id="mainTable">
      {isImporting && <FullCircularLoader />}

      {isError && (
        <Box sx={MDRVaultItemizedStyles.OpenMediaTable_Alert}>
          <Alert
            severity="error"
            icon={<img src={ErrorIcon} alt="error" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                  setIsError(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      <Box sx={MDRVaultItemizedStyles.OpenMediaTable_Box1}>
        <Stack direction="row" gap="20px">
          <Box>
            <TextField
              disabled={isReadOnly || customerSelected === ""}
              label={t("vaultItemized.mediaNumberLabel")}
              id="mediaNumber"
              size="small"
              sx={MDRVaultItemizedStyles.OpenMediaTable_MediaNumber}
              value={mediaNumber}
              onChange={(event) => handleMediaNumber(event)}
              InputLabelProps={
                MDRVaultItemizedStyles.OpenMediaTable_InputLabelProps
              }
              error={alreadyExists}
              helperText={alreadyExists && t("vaultItemized.alreadyExists")}
            />
          </Box>

          {editingRowIndex !== null && (
            <Box>
              <Button
                id="cancelEditBtn"
                variant="outlined"
                style={MDRVaultItemizedStyles.OpenMediaTable_Button}
                onClick={handleCancelEdit}
              >
                {t("vaultItemized.cancelEdit")}
              </Button>
            </Box>
          )}

          <Box>
            <Button
              variant="outlined"
              style={MDRVaultItemizedStyles.OpenMediaTable_Button}
              onClick={handleAddMediaNumber}
              id="validateAndAddBtn"
              disabled={
                isReadOnly ||
                customerSelected === "" ||
                tableValidatedData?.length === MEDIA_LIMIT
              }
            >
              {t("vaultItemized.validateAndAdd")}
            </Button>
          </Box>
          <Box>
            <Button
              component="label"
              variant="outlined"
              style={MDRVaultItemizedStyles.OpenMediaTable_Button}
              id="importBtn"
              disabled={
                isReadOnly ||
                customerSelected === "" ||
                tableValidatedData?.length === MEDIA_LIMIT
              }
            >
              {t("vaultItemized.import")}{" "}
              <input
                ref={fileInputRef}
                type="file"
                accept={TXT}
                style={MDRVaultItemizedStyles.OpenMediaTable_Input}
                onChange={handleFileImport}
              />
            </Button>
          </Box>
        </Stack>

        <Typography
          variant="subtitle1"
          sx={MDRVaultItemizedStyles.OpenMediaTable_Label}
        >
          {tableValidatedData?.length} {t("vaultItemized.requestedOf30000")}
        </Typography>
      </Box>

      <Paper style={MDRVaultItemizedStyles.OpenMediaTable_Paper}>
        <div style={MDRVaultItemizedStyles.OpenMediaTable_div}>
          <TableContainer
            style={MDRVaultItemizedStyles.OpenMediaTable_TableContainer}
          >
            <Table>
              <TableHead
                style={MDRVaultItemizedStyles.OpenMediaTable_TableHead}
              >
                <TableRow>
                  {columns.map(
                    (column, index) =>
                      column.visible && (
                        <TableCell
                          key={column.id}
                          align="left"
                          sx={{
                            width:
                              index === 0
                                ? "10%"
                                : index === 1
                                  ? "30%"
                                  : index === 2
                                    ? "30%"
                                    : index === 3
                                      ? "30%"
                                      : undefined,
                            ...MDRVaultItemizedStyles.TableHeader_Typo
                          }}
                          size="small"
                        >
                          <Box
                            id={`mainTableHead${index}`}
                            sx={MDRVaultItemizedStyles.OpenMediaTable_Box_TH}
                            onClick={() => handleSort(column.id)}
                          >
                            {column.label}
                          </Box>
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? tableValidatedData.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : tableValidatedData
                ).map((row, index) => (
                  <TableRow
                    key={index}
                    selected={editingRowIndex === index}
                    sx={MDRVaultItemizedStyles.openMediaTableRowMuiCustom}
                  >
                    {!isReadOnly && (
                      <TableCell size="small">
                        {!isReadOnly ? (
                          <Box
                            sx={
                              MDRVaultItemizedStyles.OpenMediaTable_TableCell_First
                            }
                          >
                            <Box
                              id={`mainTableEditBtn${index}`}
                              sx={{
                                cursor:
                                  editingRowIndex === null
                                    ? "pointer"
                                    : "default"
                              }}
                              onClick={() =>
                                editingRowIndex === null && handleEditRow(index)
                              }
                            >
                              <img
                                src={CreateOutlinedIcon}
                                alt={t("vaultItemized.createOutlinedIconAlt")}
                              />
                            </Box>

                            <Box
                              id={`mainTableRemoveRowBtn${index}`}
                              sx={{
                                cursor:
                                  editingRowIndex === null
                                    ? "pointer"
                                    : "default"
                              }}
                              onClick={() =>
                                editingRowIndex === null &&
                                handleRemoveRow(index)
                              }
                            >
                              <CloseIcon color="primary" />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            sx={
                              MDRVaultItemizedStyles.OpenMediaTable_TableCell_HeightBox
                            }
                          ></Box>
                        )}
                      </TableCell>
                    )}
                    <TableCell size="small">
                      {String(row.volser).toUpperCase()}
                    </TableCell>
                    <TableCell size="small">
                      {shrinkString(
                        row.item_status_descr || row.destruct_item_status_descr
                      )}
                    </TableCell>
                    {!isReadOnly && (
                      <TableCell size="small">
                        <Box
                          sx={
                            MDRVaultItemizedStyles.OpenMediaTable_TableCell_FollowUp
                          }
                        >
                          <Box>{row.follow_up}</Box>
                          {row?.follow_up?.includes(
                            t("vaultItemized.possibleMatches")
                          ) ||
                          row?.follow_up?.includes(
                            t("vaultItemized.duplicates")
                          ) ? (
                            <Box
                              id={`mainTableFindPossibleMatchesBtn${index}`}
                              onClick={() =>
                                editingRowIndex === null && onClickHandler(row)
                              }
                              sx={{
                                cursor:
                                  editingRowIndex === null
                                    ? "pointer"
                                    : "default",
                                ...MDRVaultItemizedStyles.OpenMediaTable_TableCell_FollowUp_Btn
                              }}
                            >
                              {!isReadOnly && (
                                <img
                                  src={RemoveRedEyeFilled}
                                  alt={t("vaultItemized.eyeIconAlt")}
                                />
                              )}
                            </Box>
                          ) : (
                            <Box
                              sx={MDRVaultItemizedStyles.OpenMediaTable_div}
                            ></Box>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <TablePagination
          style={MDRVaultItemizedStyles.OpenMediaTable_TablePagination}
          rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          component="div"
          count={tableValidatedData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Stack>
  );
};

export default OpenMediaTable;
