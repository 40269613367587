import { getDocs, query, where } from "firebase/firestore";
import {
  authorization_code_collection,
  destruct_Request_Handling_Type_collection,
  destruct_request_box_type_collection,
  destruct_request_type_collection,
  message_by_code_collection,
  personnel_customer_authorization_collection,
  program_security_collection,
  service_location_collection,
  unit_of_measure_collection
} from "../../firebase";

import { callToCloudFunction } from "services/api/apiRequests";
import { api_results_collection_resultset0 } from "services/firebase";
import {
  DOUBLE_EQUALS,
  ERROR_TYPES,
  FIREBASE_FIELD_NAMES,
  Y_CHECKER
} from "utils/constants";
/**
 * @module mediaDestructionRequestDeatilPickup request media pickup feature specific requests module
 */

export const mediaDestruction = {
  /**
   * Get all tracking requirments
   *
   * @param {object} props
   * @returns
   */
  getTrackingRequirements: async () => {
    try {
      let result = [];
      const q = query(destruct_request_type_collection);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({
          id: doc?.destruct_request_type_id,
          ...doc?.data()
        });
      });
      return result;
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get all desposition service
   *
   * @param {object} props
   * @returns
   */
  getDispositionServices: async () => {
    try {
      let result = [];
      const q = query(destruct_Request_Handling_Type_collection);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({
          id: doc?.destruct_request_handling_type_id,
          ...doc?.data()
        });
      });
      return result;
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get unit of measures
   *
   * @param {object} props
   * @returns
   */
  getUnitOfMeasures: async () => {
    try {
      let result = [];
      const q = query(unit_of_measure_collection);
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({
          id: doc?.unit_of_measure_id,
          ...doc?.data()
        });
      });
      return result;
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get all box types
   *
   * @param {object} props
   * @returns
   */
  getBoxTypes: async () => {
    try {
      let result = [];
      const q = query(
        destruct_request_box_type_collection,
        where(FIREBASE_FIELD_NAMES.ACTIVE_FLAG, DOUBLE_EQUALS, Y_CHECKER)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        result.push({
          id: doc?.destruct_request_box_type_id,
          ...doc?.data()
        });
      });
      return result;
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get customer service location
   *
   * @param {object} props
   * @returns
   */
  getCustomerServiceLocation: async ({ customerId }) => {
    try {
      if (customerId) {
        let result = [];
        const q = query(
          service_location_collection,
          where("customer_id", "==", customerId)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          result.push({ id: doc?.id, ...doc?.data() });
        });

        return result;
      }
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get customer details
   *
   * @param {object} props
   * @returns
   */
  getCustomerInfo: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getcustomerdatabyid`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));

        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get destruct request details
   *
   * @param {object} props
   * @returns
   */
  getDestructRequestDetails: async ({
    customerId,
    destructRequestId,
    systemId,
    mainDistrictId,
    language_locale_id
  }) => {
    var requestBody = JSON.stringify({
      main_district_id: mainDistrictId,
      customer_id: customerId,
      destruct_request_id: destructRequestId,
      system_id: systemId,
      language_locale_id: language_locale_id
    });
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/getdestructrequestdetail`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get special handling
   *
   * @param {object} props
   * @returns
   */
  getSpecialHandling: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/returnsdestructrequestspecialhandlinginfolist`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get request comment text
   *
   * @param {object} props
   * @returns
   */
  getRequestComments: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/returndestructrequestcomments`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * Get box types count
   *
   * @param {object} props
   * @returns
   */
  getBoxTypesCount: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestboxtypecountreturn`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * save request for sitad
   *
   * @param {object} props
   * @returns
   */
  saveSitadRequest: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/createnewmediadestructionrequest`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * delete request box types count
   *
   * @param {object} props
   * @returns
   */
  deleteRequestBoxTypeCount: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestboxtypecountdelete`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults || cfResponse.isSuccess) {
        const docId = cfResponse.docId;
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * insert request box types count
   *
   * @param {object} props
   * @returns
   */
  insertRequestBoxTypeCount: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestboxtypecountinsert`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults || cfResponse.isSuccess) {
        const docId = cfResponse.docId;
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * insert request special handling
   *
   * @param {object} props
   * @returns
   */
  insertRequestSpecialHandling: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestspecialhandlinginsert`,
      ).then((resp) => resp.data);
      if (cfResponse.hasResults || cfResponse.isSuccess) {
        const docId = cfResponse.docId;
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * delete request special handling
   *
   * @param {object} props
   * @returns
   */
  deleteRequestComment: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestcommentdelete`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults || cfResponse.isSuccess) {
        const docId = cfResponse.docId;
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * insert request comment Text
   *
   * @param {object} props
   * @returns
   */
  insertRequestComment: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/destructrequestcommentinsert`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults || cfResponse.isSuccess) {
        const docId = cfResponse.docId;
        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * save schedule request
   *
   * @param {object} props
   * @returns
   */
  saveScheduleRequest: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/updatemediadestruction`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * cancel request
   *
   * @param {object} props
   * @returns
   */
  cancelRequest: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/canceldestructrequest`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * get the other request status Id
   *
   * @param {object} props
   * @returns
   */
  getRunRequestStatus: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/request/getrequestandrunexchangestatus`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * verify personnel permission for customer & personnel
   *
   * @param {object} props
   * @param {string} props.customer_id
   * @param {string} props.personnel_id
   * @param {string} props.program_id

   * @returns
   */
  verifyPersonnelPermission: async ({
    customer_id,
    personnel_id,
    program_id
  }) => {
    try {
      let personnelCustomerAuth = [];
      let authorizationCodes = [];
      let allAuthprogramId = [];
      //getting the personnelCustomerAuth by using customer_id and personnel_id
      const q1 = query(
        personnel_customer_authorization_collection,
        where("customer_id", "==", customer_id),
        where("personnel_id", "==", personnel_id)
      );
      const querySnapshot1 = await getDocs(q1);
      querySnapshot1.forEach((doc) => {
        personnelCustomerAuth.push({ id: doc?.id, ...doc?.data() });
      });
      //getting the authorizationCodes
      await Promise.all(
        personnelCustomerAuth.map(async (customerPersonnel) => {
          const q2 = query(
            authorization_code_collection,
            where(
              "authorization_code_id",
              "==",
              customerPersonnel?.authorization_code_id
            )
          );
          const querySnapshot2 = await getDocs(q2);
          querySnapshot2.forEach((doc) => {
            authorizationCodes.push({ id: doc?.id, ...doc?.data() });
          });
        })
      );
      // get the program id for the customer
      await Promise.all(
        authorizationCodes.map(async (customerPersonnel) => {
          const q5 = query(
            program_security_collection,
            where(
              "security_group_id",
              "==",
              customerPersonnel?.security_group_id
            )
          );
          const querySnapshot5 = await getDocs(q5);
          querySnapshot5.forEach((doc) => {
            allAuthprogramId.push({ id: doc?.id, ...doc?.data() });
          });
        })
      );
      // checking the program id
      const authStatus = allAuthprogramId.filter((program) => {
        return program.program_id === program_id;
      });

      return authStatus.length > 0;
    } catch (error) {
      // Handle the error
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  },
  /**
   * get the other request status Id
   *
   * @param {object} props
   * @returns
   */
  uploadFile: async (payload) => {
    var requestBody = JSON.stringify(payload);
    try {
      let response = [];
      // send the parameters to the CF and wait for the response
      const cfResponse = await callToCloudFunction(
        requestBody,
        `${process.env.REACT_APP_CF_URL_MODULE_REQUEST}/destruction/createinteractivesitescanlist`
      ).then((resp) => resp.data);
      if (cfResponse.hasResults && cfResponse.isSuccess) {
        const docId = cfResponse.docId;

        // fetch the document's resultset which CF returns
        const collectionSnap = await getDocs(
          api_results_collection_resultset0(docId)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => response.push(doc.data()));
        if (response && response[0]?.error) {
          let result = [];
          const filteredQuery = query(
            message_by_code_collection,
            where(FIREBASE_FIELD_NAMES.MESSAGE_ID, "==", response[0]?.error)
          );

          const querySnapshot = await getDocs(filteredQuery);
          querySnapshot?.forEach((doc) => {
            result.push({ id: doc?.id, ...doc?.data() });
          });

          response = [
            {
              error: result[0]?.descr
            }
          ];
        }
        // return the results
        return response;
      } else if (!cfResponse.isSuccess) {
        response = [
          {
            error: ERROR_TYPES.FAILED
          }
        ];
        return response;
      }
    } catch (err) {
      // throw an error if an error caught
      const response = [
        {
          error: ERROR_TYPES.EXCEPTION
        }
      ];
      return response;
    }
  }
};
