import { t } from "i18next";
import { numberConstants } from "..";

export const auditTypesBranch = [
  {
    label: t("openMediaAuditSearch.logicalVault"),
    value: numberConstants.five
  },
  {
    label: t("openMediaAuditSearch.location"),
    value: numberConstants.six
  },
  {
    label: t("openMediaAuditSearch.distribution"),
    value: numberConstants.seven
  },
  {
    label: t("openMediaAuditSearch.customerDistribution"),
    value: numberConstants.eight
  }
];

export const auditTypesCustomer = [
  {
    label: t("openMediaAuditSearch.logicalVault"),
    value: numberConstants.five
  }
];
export const notCompletedSelection = {
  label: t("openMediaAuditSearch.notCompleted"),
  value: numberConstants.minusOne
};

export const B = "B";
export const C = "C";
export const programSecurityCode308 = "308";
export const programSecurityCode309 = "309";
export const programSecurityCode310 = "310";
export const programSecurityCode311 = "311";
export const systemId = "69";
export const globalAttributeTypeIdMaxRowLimit = "134";
export const globalAttributeTypeIdCalFuture = "141";
export const globalAttributeTypeIdCalPast = "142";
