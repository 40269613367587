import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DISCREPANCY_MANAGEMENT,
  DISCREPANCY_MANAGEMENT_UPDATE,
  OPERATIONS_MODULE_BASE_PATH
} from "routing/Paths";
import { CF_URLS } from "services/api/endpoints";
import {
  getExchangeStatusById,
  getMessageFromCode,
  getSuggestionType
} from "services/api/query";
import { selectAuth } from "store/slices";
import {
  setError,
  setHasInsufficientRights,
  setType,
  setUpdateSelectedList
} from "store/slices/discrepancy-search";
import {
  clearError,
  setAuthTypeChange,
  setShowAuthSection,
  updateDiscrepancyAuthFormValues,
  updatePreferencesAuthentication
} from "store/slices/discrepancy-update";
import spacing from "styles/spacing";
import {
  authSection,
  dayMonthYearFormat,
  dayMonthYearTimeFormat,
  ERROR_TYPES,
  errorMsgs,
  N_CHECKER,
  numberConstants,
  VALUE_EMPTY_STRING,
  Y_CHECKER
} from "utils/constants";
import {
  containerDescripancyTypes,
  containerDiscrepancyTitle,
  descrepancyTypeTitle,
  detectedInType,
  mediaTypes,
  mediaTypesTitle,
  openMeidaDiscrepancyTypes,
  systemFunctionTitle,
  TRtext
} from "utils/constants/discrepancy-management/DiscrepancySearchConstants";
import { findErrorMessage, getResponseData } from "utils/helpers";
import { DetailsStyles } from "./DetailsStyles";
import { DiscrepancyDetailsTable } from "./DiscrepancyDetailsTable";

const DiscrepancyDetails = ({
  setIsLoading,
  setThrowErrorMessage,
  setShowPrintModal,
  isLoading,
  isAuthenticated
}) => {
  const { currentBranch, localeByBranch } = useSelector(selectAuth);
  const { openMediaData, containerData, selectedValues, searchFormValues } =
    useSelector(({ discrepancySearch }) => discrepancySearch);
  const { discrepancyAuthFormValues } = useSelector(
    (state) => state.discrepancyUpdate
  );
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [details, setDetail] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [index, setIndex] = useState(0);
  const [currentList, setCurrentList] = useState([]);
  const [type, setDiscrepancyType] = useState();
  const [tempLoad, setTempLoad] = useState(false);
  const [runStatus, setRunStatus] = useState(null);

  let location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let type = location?.state?.type;
    let id =
      location?.state?.value?.openMediaDiscrepancyId ||
      location?.state?.value?.discrepancyId;
    if (!id && selectedValues?.length) {
      id =
        selectedValues[0]?.openMediaDiscrepancyId ||
        selectedValues[0]?.discrepancyId;
      type = selectedValues[0]?.openMediaDiscrepancyId
        ? t("discrepancyManagement.openMedia")
        : "";
    }
    if (id) {
      getDiscrepancyDetail(id, type);
      setDiscrepancyType(type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.openMediaDiscrepancyId]);

  useEffect(() => {
    if (!isLoading && tempLoad) {
      setIsLoading(tempLoad);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (data?.customer_number && isAuthenticated) {
      if (
        discrepancyAuthFormValues?.customer?.number?.trim() !==
        data?.customer_number?.trim()
      ) {
        handleError();
      }
    }
    //eslint-disable-next-line
  }, [isAuthenticated, data?.customer_number]);

  const handleError = async () => {
    setIsLoading(true);
    const errDesc = await getMessageFromCode(errorMsgs.errorCode64097);
    if (errDesc[0]?.descr) {
      dispatch(setError(errDesc[0]?.descr));
      dispatch(setHasInsufficientRights(true));
      navigate(`${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`, {
        state: {
          from: location?.pathname,
          detectedIn: searchFormValues.detectedIn
        }
      });
    }

    setIsLoading(false);
  };

  const getDiscrepancyDetail = async (id, type) => {
    try {
      setTempLoad(true);
      setIsLoading(true);
      let url =
        type === t("discrepancyManagement.openMedia")
          ? CF_URLS.discrepancyManagement.getOpenMediaDiscrepanciessbwebandss
          : CF_URLS.discrepancyManagement
              .getOpenMediaLoadCantainerDiscrepancydetails;
      let req = {};
      if (type === t("discrepancyManagement.openMedia")) {
        req = {
          main_district_id: currentBranch?.value,
          om_discrepancy_id: id
        };
      } else {
        req = {
          main_district_id: currentBranch?.value,
          discrepancy_id: id
        };
      }
      let res = await getResponseData(
        JSON.stringify({
          ...req
        }),
        `${url}`,
        4
      );
      if (res?.data[0][0]) {
        let detected_in = "",
          resolution = "",
          commentText = res?.data[0][0]?.auth_bypass_reason,
          discrepancyTypeModification = "";
        if (
          res?.data[0][0]?.discrepancy_type_code &&
          type === t("discrepancyManagement.openMedia")
        ) {
          discrepancyTypeModification =
            openMeidaDiscrepancyTypes[
              descrepancyTypeTitle +
                res?.data[0][0]?.discrepancy_type_code?.replace(/\s+/g, "") +
                res?.data[0][0]?.file_section_type_id
            ];
        } else {
          discrepancyTypeModification =
            containerDescripancyTypes[
              containerDiscrepancyTitle +
                res?.data[0][0]?.discrepancy_type_code
                  ?.replace(/\s+/g, "")
                  .toLowerCase() +
                res?.data[0][0]?.system_function_id
            ];
        }

        if (res?.data[1]?.length) {
          let data = res?.data[1].map((item) => {
            let temp =
              openMeidaDiscrepancyTypes[
                descrepancyTypeTitle +
                  item?.discrepancy_type_code?.replace(/\s+/g, "") +
                  item?.file_section_type_id
              ];
            return {
              ...item,
              discrepancy_type_code: temp
            };
          });
          setDetail(data);
        }
        setCommentList(res?.data[3]);
        if (res?.data[0][0]?.suggestion_type_id) {
          let res1 = await getSuggestionType(
            res?.data[0][0]?.suggestion_type_id
          );
          resolution = res1[0]?.descr;
        }
        let status = await getExchangeStatusById(
          res?.data[0][0]?.run_exchange_status_id
        );
        if (res?.data[0][0]?.auth_bypass_reason?.trim()) {
          commentText =
            res?.data[0][0]?.auth_bypass_employee_name +
            " " +
            dayjs
              .utc(res?.data[0][0]?.auth_bypass_bypass_date)
              .format(dayMonthYearFormat) +
            " " +
            res?.data[0][0]?.auth_bypass_reason;
        }
        setRunStatus(status);
        let detectedIn = res.data[0]?.find((item) => {
          let id =
            location?.state?.value?.containerAuditId ||
            location?.state?.value?.omAuditId;
          if (!id) {
            id =
              selectedValues[0]?.omAuditId ||
              selectedValues[0]?.containerAuditId;
          }
          return item?.audit_id === id;
        });
        if (!detectedIn) {
          detectedIn = res.data[0][0];
        }
        if (type === t("discrepancyManagement.openMedia")) {
          if (detectedIn?.file_section_type_id === numberConstants.one) {
            detected_in = t("discrepancyManagementDetail.picking");
          } else if (detectedIn?.file_section_type_id === numberConstants.two) {
            detected_in = t("discrepancyManagementDetail.distribution");
          } else if (
            detectedIn?.file_section_type_id === numberConstants.four
          ) {
            detected_in = t("discrepancyManagementDetail.inventory");
          }
          detected_in +=
            " " +
            dayjs.utc(detectedIn?.detected_datetime).format(dayMonthYearFormat);
          if (detectedIn?.file_section_type_id === numberConstants.sixteen) {
            detected_in =
              t("discrepancyManagementDetail.audit") + detectedIn?.audit_id;
          }
          if (detectedIn?.file_section_type_id === numberConstants.fourTeen) {
            detected_in = t("discrepancyManagement.pickingDestruction");
          }
        } else if (
          [
            detectedIn?.file_section_type_id,
            detectedIn?.system_function_id
          ].includes(numberConstants.seventeethree)
        ) {
          detected_in =
            t("discrepancyManagementDetail.inventoryAudit") +
            detectedIn?.audit_id;
        } else {
          detected_in =
            detectedInType[
              systemFunctionTitle + detectedIn?.system_function_id
            ];
        }
        setData({
          ...res?.data[0][0],
          detected_in,
          resolution,
          commentText,
          discrepancyTypeModification
        });
      }
      if (type === t("discrepancyManagement.openMedia")) {
        if (currentList?.length) {
          let idx = currentList?.findIndex(
            (item) => item?.open_media_discrepancy_id === id
          );
          setIndex(idx);
        } else {
          let idx = openMediaData?.findIndex(
            (item) => item?.open_media_discrepancy_id === id
          );
          setCurrentList([...openMediaData]);
          setIndex(idx);
        }
      } else {
        if (currentList?.length) {
          let idx = currentList?.findIndex(
            (item) => item?.discrepancy_id === id
          );
          setIndex(idx);
        } else {
          let idx = containerData?.findIndex(
            (item) => item?.discrepancy_id === id
          );
          setCurrentList([...containerData]);
          setIndex(idx);
        }
      }
      setTempLoad(false);
      setIsLoading(false);
    } catch (err) {
      setThrowErrorMessage(findErrorMessage(ERROR_TYPES.ISSUE));
      setTempLoad(false);
      setIsLoading(false);
    }
  };
  const handleClickNextPrev = (index) => {
    let id =
      currentList[index]?.open_media_discrepancy_id ||
      currentList[index]?.discrepancy_id;
    getDiscrepancyDetail(id, type, index);
  };
  const handlePersonnelAuthChange = () => {
    const updatedValues = {
      ...discrepancyAuthFormValues,
      personnel: VALUE_EMPTY_STRING,
      authNumber: VALUE_EMPTY_STRING,
      challengeQuestion: VALUE_EMPTY_STRING,
      challengeResponse: VALUE_EMPTY_STRING,
      personnelAuthValue: authSection?.personnelAuth
    };
    dispatch(setAuthTypeChange(authSection?.personnelAuth));
    dispatch(updateDiscrepancyAuthFormValues(updatedValues));
    dispatch(updatePreferencesAuthentication(false));
    dispatch(clearError());
    dispatch(setShowAuthSection(false));
  };
  const handleUpdate = () => {
    const {
      resolved_by_name,
      car_customer_display_flag,
      volser_number,
      customer_file_section_id,
      discrepancy_type_context,
      detected_in_context,
      detected_in_datetime,
      customer_number,
      om_audit_id,
      customer_id,
      customer_name,
      media_type_id,
      request_id,
      last_mod_date,
      file_section_type_id,
      logical_vault_id,
      detected_datetime,
      open_media_discrepancy_id,
      car_id,
      discrepancy_type_code,
      container_number,
      system_function_id,
      discrepancy_id,
      om_in_transport_flag,
      original_system_function_id
    } = currentList[index];
    let obj = {
      resolvedByName: resolved_by_name,
      carCustomerDisplayFlag: car_customer_display_flag,
      carId: car_id,
      customerFileSectionId: customer_file_section_id,
      customerId: customer_id,
      customerName: customer_name,
      customerNumber: customer_number,
      detectedDatetime: detected_datetime,
      detectedInContext: detected_in_context,
      detectedInDateTime: detected_in_datetime,
      detectedValue: data?.detected_in,
      requestId: request_id,
      discrepancyTypeCode: discrepancy_type_code,
      discrepancyType: data?.discrepancyTypeModification,
      mediaTypeId: media_type_id,
      logicalVaultId: logical_vault_id,
      discrepancyTypeContext: discrepancy_type_context,
      lastModDate: last_mod_date,
      omAuditId: om_audit_id,
      originalSystemFunctionId: original_system_function_id,
      detectedInFull: data?.detected_in,
      serviceDate: data?.service_date,
      run: data?.run_name,
      pendingReturnDate: data?.pending_return_date,
      customerMediaDescr: currentList[index]?.customer_media_descr,
      runId: data?.run_id ?? ""
    };
    if (type === t("discrepancyManagement.openMedia")) {
      obj["fileSectionTypeId"] = file_section_type_id;
      obj["openMediaDiscrepancyId"] = open_media_discrepancy_id;
      obj["volserNumber"] = volser_number;
      obj["omAuditId"] =
        location?.state?.value?.omAuditId || selectedValues[0]?.omAuditId;
      obj["isTransprot"] = om_in_transport_flag;
    } else {
      obj["systemFunctionId"] = system_function_id;
      obj["containerNumber"] = container_number;
      obj["discrepancyId"] = discrepancy_id;
      obj["containerAuditId"] =
        location?.state?.value?.containerAuditId ||
        selectedValues[0]?.containerAuditId;
      obj["hasMedia"] = om_in_transport_flag;
      obj["isTransprot"] = container_number?.includes(TRtext)
        ? Y_CHECKER
        : N_CHECKER;
    }
    handlePersonnelAuthChange();
    dispatch(setUpdateSelectedList([{ ...obj }]));
    dispatch(setType(type));
    navigate(
      `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT_UPDATE}`,
      {
        state: {
          from: location?.pathname,
          detectedIn: searchFormValues.detectedIn
        }
      }
    );
  };
  const handlePrintData = () => {
    let id =
      currentList[index]?.open_media_discrepancy_id ||
      currentList[index]?.discrepancy_id;
    let obj = {
      district_id: currentList[index]?.district_id,
      detected_in: data?.detected_in?.replace(/#/g, "<hash>"),
      om_discrepancy_id: id,
      media_type: data.media_type_id
        ? mediaTypes[mediaTypesTitle + data.media_type_id] ?? data.media_type_id
        : "",
      show_additional_system_comments: numberConstants.one,
      show_bypass_reason: numberConstants.one,
      resolution: data?.resolution,
      location: data?.location,
      run: data?.run_name,
      run_status: runStatus ? runStatus[0]?.type : "",
      pending_return_date: data?.pending_return_date,
      service_date: data?.service_date,
      cstomer_media_identifier: currentList[index]?.customer_media_descr
    };
    setShowPrintModal(obj);
  };
  return (
    <Grid container spacing={2} sx={DetailsStyles.fontColor}>
      <Grid item md={6} lg={3} xs={12}>
        {t("common.Customer")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.customer_number}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.customerMediaIdentifier")}
        <Typography style={DetailsStyles.fontColor2}>
          {currentList[index]?.customer_media_descr}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.mediaNumber")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.volser_number || data?.container_number}{" "}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.mediaType")}
        <Typography style={DetailsStyles.fontColor2}>
          {data.media_type_id
            ? mediaTypes[mediaTypesTitle + data.media_type_id]
            : ""}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.carId")}
        <Typography style={DetailsStyles.fontColor2}>{data?.car_id}</Typography>
      </Grid>

      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.discrepancyType")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.discrepancyTypeModification}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.detectedDate")}
        <Typography style={DetailsStyles.fontColor2}>
          {dayjs.utc(data?.detected_datetime).format(dayMonthYearTimeFormat)}{" "}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.detectedIn")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.detected_in}{" "}
        </Typography>
      </Grid>
      {type !== t("discrepancyManagement.openMedia") ? (
        <>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.serviceDate")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.service_date
                ? dayjs.utc(data?.service_date).format(dayMonthYearFormat)
                : ""}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.pendingReturnDate")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.pending_return_date
                ? dayjs(data?.pending_return_date)
                    .locale(localeByBranch)
                    .format(dayMonthYearFormat)
                : ""}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.run")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.run_name}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.runStatus")}
            <Typography style={DetailsStyles.fontColor2}>
              {runStatus ? runStatus[0]?.type : ""}
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.logicalVault")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.logical_vault_code}{" "}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.requestId")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.request_id}{" "}
            </Typography>
          </Grid>
        </>
      )}

      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.displayToCustomerDate")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.display_to_customer_datetime &&
            dayjs
              .utc(data.display_to_customer_datetime)
              .format(dayMonthYearTimeFormat)}
        </Typography>
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.resolvedDate")}
        {data.resolved_datetime && (
          <Typography style={DetailsStyles.fontColor2}>
            {dayjs.utc(data.resolved_datetime).format(dayMonthYearTimeFormat)}
          </Typography>
        )}
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.resolvedBy")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.resolved_by_name}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.authorizedBy")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.authorized_by_name}{" "}
        </Typography>
      </Grid>
      <Grid item md={6} lg={3} xs={12}>
        {t("discrepancyManagementDetail.resolution")}
        <Typography style={DetailsStyles.fontColor2}>
          {data?.resolution}{" "}
        </Typography>
      </Grid>
      {type === t("discrepancyManagement.openMedia") && (
        <>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.lastKnownLocation")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.last_known_location}
            </Typography>
          </Grid>
          <Grid item md={6} lg={3} xs={12}>
            {t("discrepancyManagementDetail.location")}
            <Typography style={DetailsStyles.fontColor2}>
              {data?.location}
            </Typography>
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <Divider />
          </Grid>
          <Grid item md={12} lg={12} xs={12}>
            <DiscrepancyDetailsTable details={details} />
          </Grid>
        </>
      )}
      <Grid item md={12} lg={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Typography style={DetailsStyles.subHeading}>
          {t("discrepancyManagementDetail.additionalSystemInformation")}
        </Typography>
        <Grid item style={DetailsStyles.sectionStyle}>
          {commentList?.length ? (
            commentList?.map((item) => {
              return (
                <Grid item key={item.comment}>
                  {item.comment}{" "}
                </Grid>
              );
            })
          ) : (
            <>{t("common.noRecordsFound")} </>
          )}
        </Grid>
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Box>
          <TextField
            name="byPassReason"
            id="byPassReason"
            label={t("discrepancyManagementDetail.byPassReason")}
            value={data?.commentText}
            fullWidth
            multiline
            rows={4}
            inputProps={{ maxLength: 225 }}
            disabled={true}
            variant="outlined"
            InputLabelProps={{ shrink: !!data?.commentText }}
          />
        </Box>
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Divider />
      </Grid>
      <Grid item md={12} lg={12} xs={12}>
        <Grid
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
          gap={spacing.verticalMargin20}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handlePersonnelAuthChange();
              navigate(
                `${OPERATIONS_MODULE_BASE_PATH}/${DISCREPANCY_MANAGEMENT}`,
                {
                  state: {
                    from: location?.pathname,
                    detectedIn: searchFormValues.detectedIn
                  }
                }
              );
            }}
            disabled={isAuthenticated}
          >
            {t("common.back")}
          </Button>
          <Button
            variant="outlined"
            disabled={index === 0}
            onClick={() => {
              handleClickNextPrev(index - 1);
            }}
          >
            {t("discrepancyManagementDetail.previous")}
          </Button>
          <Button
            variant="outlined"
            disabled={index === currentList?.length - 1}
            onClick={() => {
              handleClickNextPrev(index + 1);
            }}
          >
            {t("discrepancyManagementDetail.next")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              handlePrintData();
            }}
          >
            {t("discrepancyManagementDetail.print")}
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              handleUpdate();
            }}
            disabled={
              [numberConstants.two, numberConstants.one].includes(
                data?.audit_status_id
              ) || data?.resolved_datetime
            }
          >
            {t("discrepancyManagementDetail.updateDiscrepancy")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DiscrepancyDetails;
