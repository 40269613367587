import axios from "axios";
import { getApp, getApps, initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { api_results_collection_resultset1 } from "services/firebase";
import { APPLICATION_JSON, TXT_BEARER, WORKER_ID } from "utils/constants";
import {
  getAuthenticatedUserBranch,
  getAuthenticatedUserToken,
  sanitizeApiData
} from "utils/helpers";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  appId: process.env.REACT_APP_FB_APP_ID
};

const dbId = process.env.REACT_APP_FB_DB_ID;

let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApp();
}

const db = getFirestore(firebaseApp, dbId);

//common function for call to cloud function
export const callToCloudFunction = async (reqBody, url) => {
  const accessToken = await getAuthenticatedUserToken();

  let sanitizePayload = {};
  if (typeof reqBody === 'object') {
    sanitizePayload = sanitizeApiData(reqBody);
  } else {
    sanitizePayload = sanitizeApiData(JSON.parse(reqBody));
  }

  const response = await axios.post(url, JSON.stringify(sanitizePayload), {
    headers: {
      "Content-Type": APPLICATION_JSON,
      authorization: `${TXT_BEARER} ${accessToken}`,
      region: process.env.REACT_APP_CF_REGION,
      environment: process.env.REACT_APP_CF_ENV,
      branch: getAuthenticatedUserBranch(),
      workerid: WORKER_ID
    }
  });
  return response;
};

export const callToAPI = async (reqBody, url) => {
  try {
    const accessToken = await getAuthenticatedUserToken();
    const response = await axios.post(url, reqBody, {
      headers: {
        "Content-Type": APPLICATION_JSON,
        authorization: `${TXT_BEARER} ${accessToken}`,
        region: process.env.REACT_APP_CF_REGION,
        environment: process.env.REACT_APP_CF_ENV,
        branch: getAuthenticatedUserBranch(),
        workerid: WORKER_ID
      }
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getDataFromFirestore = async (resObj, resultSetCount, docId) => {
  let response = { data: {}, error: null };
  if (resObj.data.isSuccess) {
    for (var i = 0; i < resultSetCount; i++) {
      let resData = [];
      if (resObj.data.hasResults) {
        let collectionSnap = await getDocs(
          collection(db, "api_results", docId, "resultSet_" + i)
        );
        //loop through the snapshot and add it to a variable
        collectionSnap.forEach((doc) => resData?.push(doc.data()));
        response.data[i] = resData;
      } else {
        response.data[i] = resData;
      }
    }
    // return the results
    return response;
  } else {
    // throw an error containing the error message
    if (!resObj.data.hasResults) {
      response?.error?.push(resObj?.error || resObj?.data?.error);
      return response;
    }
  }
};

export const getResultSet1ErrorStatus = async (reqBody, url) => {
  try {
    const cfResponse = await callToCloudFunction(reqBody, url);

    let response = [];

    if (cfResponse?.data?.isSuccess && cfResponse?.data?.hasResults) {
      const docId = cfResponse?.data?.docId;

      // fetch the document's resultset which CF returns
      const collectionSnap = await getDocs(
        api_results_collection_resultset1(docId)
      );
      //loop through the snapshot and add it to a variable
      collectionSnap?.forEach((doc) => {
        const formattedData = {
          ...doc.data()
        };
        response.push(formattedData);
      });
    }

    return response;
  } catch (error) {
    return null;
  }
};
