import { getDocs, query } from "firebase/firestore";
import {
  api_results_collection_resultset0,
  billing_classification_collection
} from "services/firebase";
import { TAG_TYPES } from "utils/constants";
import { callToCloudFunction } from "../apiRequests";
import { baseApi } from "../baseApi";

/**
 * @module requestSearchApi request search RTK Query endpoints
 */
export const billingInterfaceRulesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllClassifications: builder.query({
      queryFn: async () => {
        let result = [];
        const q = query(billing_classification_collection);
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          result.push({ id: doc?.id, ...doc?.data() });
        });

        // Transform the data for the API response
        const responseData = result.map(
          ({ descr, billing_classification_code }) => ({
            label: descr,
            value: billing_classification_code
          })
        );

        return { data: responseData };
      },
      providesTags: [TAG_TYPES.reqestType]
    }),
    getAllCatalogItems: builder.mutation({
      queryFn: async (arg) => {
        try {
          const { billingClassificationCode, mainDistrictId } = arg;
          var requestBody = JSON.stringify({
            main_district_id: mainDistrictId,
            function: "catalog_item_l2_sp",
            billing_classification_code: billingClassificationCode
          });
          let response = [];
          // send the parameters to the CF and wait for the response
          const cfResponse = await callToCloudFunction(
            requestBody,
            `${process.env.REACT_APP_CF_URL_MODULE_BILLING}/interfacerules/getcatalogiteml2`
          ).then((resp) => resp.data);

          const docId = cfResponse.docId;

          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );

          // loop through the snapshot and add it to a variable
          collectionSnap.forEach((doc) => response.push(doc.data()));

          // map the response to match the expected format
          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestStatus]
    }),
    getAllIdentifierInfo: builder.mutation({
      queryFn: async (arg) => {
        try {
          const { billingClassificationCode, mainDistrictId } = arg;
          var requestBody = JSON.stringify({
            main_district_id: mainDistrictId,
            function: "billing_identifier_l1_sp",
            billing_classification_code: billingClassificationCode
          });
          let response = [];
          // send the parameters to the CF and wait for the response
          const cfResponse = await callToCloudFunction(
            requestBody,
            `${process.env.REACT_APP_CF_URL_MODULE_BILLING}/interfacerules/getbillingidentifierinfo`
          ).then((resp) => resp.data);

          const docId = cfResponse.docId;
          // fetch the document's resultset which CF returns
          const collectionSnap = await getDocs(
            api_results_collection_resultset0(docId)
          );

          // loop through the snapshot and add it to a variable
          collectionSnap.forEach((doc) => response.push(doc.data()));

          // map the response to match the expected format

          return { data: response };
        } catch (err) {
          return { error: err };
        }
      },
      providesTags: [TAG_TYPES.requestStatus]
    })
  })
});
